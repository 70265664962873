import React from 'react';
import { useEffectOnce } from 'react-use';
import PropTypes from 'prop-types';

import { useAnalyticsContext, View } from 'analytics';
import zoneDealsBackground from 'assets/ZoneDealsBackground.svg';
import GTInfoModal from 'components/GTInfoModal/GTInfoModal';
import { FullEvent, Listing } from 'models';

import styles from './styles.module.scss';

export const ZoneDealsInfoModal = ({ listing, onHide, show, fullEvent }) => {
  const analytics = useAnalyticsContext();

  useEffectOnce(() => {
    analytics.track(
      new View(
        View.PAGE_TYPES.LISTING({
          listing,
          fullEvent,
          interaction: 'modal',
          payload: {
            title: 'zone_deal_info',
          },
        })
      )
    );
  });

  return (
    <GTInfoModal
      className={styles['zone-modal']}
      show={show}
      onHide={onHide}
      buttonText="Got It"
      style={{
        content: {
          backgroundImage: `url(${zoneDealsBackground})`,
        },
      }}
      buttonClassName={styles['zone-close']}
    >
      <h2 className={styles['zone-header']}>Get in the zone!</h2>
      <p className={styles['zone-content']}>
        Zone Deals give fans special discounts in specific areas of the stadium,
        but the exact seats are hidden until after delivery. These secret deals
        get you even better prices for your favorite events.
      </p>
    </GTInfoModal>
  );
};

ZoneDealsInfoModal.propTypes = {
  fullEvent: PropTypes.instanceOf(FullEvent).isRequired,
  listing: PropTypes.instanceOf(Listing),
  show: PropTypes.bool,
  onHide: PropTypes.func,
};
