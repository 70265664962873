import React from 'react';

import { PerformerClickTracker } from 'analytics';
import MiniCard from 'components/Search/SearchResults/MiniCard/MiniCard';
import { Performer } from 'models';
import { getCategoryName } from 'store/modules/categories/category.helpers';

import styles from './PerformerList.module.scss';

type PerformerListProps = { performers: Performer[] };

const PerformerList = ({ performers }: PerformerListProps) => {
  return (
    <div className={styles.list}>
      {performers.map((performer) => (
        <div key={performer.slug} className={styles['card-container']}>
          <MiniCard
            imageProps={performer.getImageOptions()}
            title={performer.name}
            subtitle={getCategoryName(performer.category)}
            to={performer.getPath()}
            clickTracker={new PerformerClickTracker(performer)}
            primaryColor={performer.primaryColor}
            emoji={performer.isSportsCategoryGroup && performer.emoji}
            isSmall={false}
            isSearchBar={false}
          />
        </div>
      ))}
    </div>
  );
};

export default PerformerList;
