import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tag from 'ui/Tag/Tag';

import { Click, ListingClickTracker, mapListingTrackingData } from 'analytics';
import DealTag from 'components/DealTag';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import MLBBadge from 'components/MLBBadge/MLBBadge';
import Disclosures from 'components/Modals/DisclosuresModal/Disclosures';
import TicketPrice from 'components/TicketPrice/TicketPrice';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import {
  selectIsPercentageVsDollarsExperiment,
  selectIsWebExclusivesV3Experiment,
} from 'experiments';
import { selectShowTicketSourceEnabled } from 'featureFlags';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { DiscountIcon } from 'icons';
import { FullEvent } from 'models';
import Listing, { ZONE_TICKET_DISCLOSURE } from 'models/Listing';
import { DEAL_VALUE_PERCENTILE } from 'pages/Listing/constants';
import { selectIsAllInPricing } from 'store/modules/listings/selectors';
import { selectAllDeals } from 'store/modules/resources/resource.selectors';
import { isMLBEvent } from 'utils/mlb';
import { addQuery } from 'utils/url';

import PrimaryPrice from './PrimaryPrice';

import styles from './ListingCard.module.scss';

const propTypes = {
  listing: PropTypes.instanceOf(Listing),
  fullEvent: PropTypes.instanceOf(FullEvent),
  onHover: PropTypes.func,
  listingIndex: PropTypes.number,
  allDisclosures: PropTypes.object,
  zoomLevel: PropTypes.number,
  showTicketSource: PropTypes.bool,
  isAllInPriceActive: PropTypes.bool,
  isCarouselItem: PropTypes.bool,
  className: PropTypes.string,
  isFocusedCard: PropTypes.bool,
  isGalleryView: PropTypes.bool,
  showDiscountInDollars: PropTypes.bool.isRequired,
  isWebExclusivesV3Experiment: PropTypes.bool.isRequired,
  isCarouselFloating: PropTypes.bool,
  allDeals: PropTypes.object,
};

function isTopValueDeal(dealType) {
  const topValueDealTypes = ['super', 'flash', 'best'];
  return topValueDealTypes.includes(dealType);
}

/**
 * @deprecated This component is difficult to work with and should be replaced
 * by ListingFocusedCard wherever possible for any new usage.
 */
const ListingCard = ({
  listing,
  fullEvent,
  onHover,
  listingIndex,
  allDisclosures,
  zoomLevel,
  showTicketSource,
  isAllInPriceActive = false,
  isCarouselItem = false,
  className,
  isFocusedCard = false,
  isGalleryView,
  showDiscountInDollars,
  isWebExclusivesV3Experiment,
  isCarouselFloating = false,
  allDeals,
}) => {
  const location = useLocation();
  const dealType = listing.dealType;
  const isMobile = useMediaQuery(device.down.sm);

  const urlWithParams = addQuery(listing.getPath(fullEvent), location.search, {
    zoom: zoomLevel,
  });

  const isHighPrice = () => {
    const maxNumberDigits = 3;
    if (isAllInPriceActive) {
      return listing.getTotalPrice().toString().length > maxNumberDigits;
    }
    return listing.getPrice().toString().length > maxNumberDigits;
  };

  const showMLBBadge = isMLBEvent(fullEvent);
  const isSectionBadge = isMobile || isCarouselItem;
  const isWidePriceLabel =
    isHighPrice() && listing.isDiscounted() && isCarouselItem;

  const deal = allDeals?.[dealType];

  const showDealTag = !!dealType;
  // TODO: if we clean up Exclusives V3 we will only have a left deal badge
  const showDealTagLeft =
    showDealTag &&
    (isCarouselItem ||
      isGalleryView ||
      isMobile ||
      isWebExclusivesV3Experiment);
  const showDealTagRight = showDealTag && !showDealTagLeft;

  function getValueTag() {
    if (listing.isDiscounted()) {
      const savingsAmount = listing.getSavingsAmount();

      // only show savings in dollars if the savings amount is greater than or equal
      // to this value in whole dollars, otherwise continue showing percentage
      const SHOW_DISCOUNT_IN_DOLLARS_MINIMUM = 10;

      const priceMarkdown =
        showDiscountInDollars &&
        savingsAmount >= SHOW_DISCOUNT_IN_DOLLARS_MINIMUM ? (
          <span>
            <span className={styles['dollar-sign']}>$</span>
            {savingsAmount} OFF
          </span>
        ) : (
          <>{listing.getDiscountPercent()}% OFF</>
        );

      const iconSize = isWebExclusivesV3Experiment ? 12 : 16;

      return (
        <Tag
          title={priceMarkdown}
          icon={<DiscountIcon width={iconSize} height={iconSize} />}
          size={isWebExclusivesV3Experiment ? 'small' : 'default'}
          bordered
        />
      );
    }

    if (isWebExclusivesV3Experiment && isTopValueDeal(listing.dealType)) {
      return (
        <Tag
          title={`Top ${DEAL_VALUE_PERCENTILE[listing.dealType]}% Value`}
          size="small"
          bordered
        />
      );
    }

    return null;
  }

  const coloredBorderStyles = deal?.colorGradient
    ? {
        background: `linear-gradient(${deal.colorGradient.join(', ')}) border-box`,
        border: `1.5px solid transparent`,
      }
    : {};

  return (
    <div
      key={listing.id}
      className={classNames(styles['listing-card-container'], className, {
        [styles['carousel-listing-card']]: isCarouselItem,
        [styles['floating-carousel']]:
          isCarouselFloating && !deal?.colorGradient,
      })}
      style={coloredBorderStyles}
      data-testid={`listing-card-${listingIndex}`}
    >
      <Link
        to={urlWithParams}
        className={styles['listing-card']}
        onMouseEnter={() => onHover?.(listing)}
        onFocus={() => onHover?.(listing)}
        clickTracker={new ListingClickTracker(listing)
          .interaction(
            isFocusedCard
              ? Click.INTERACTIONS.FOCUSED_CARD()
              : Click.INTERACTIONS.TILE(isCarouselItem)
          )
          .payload({
            item_index: listingIndex,
            ...mapListingTrackingData(listing.listingTrackingData),
          })}
        rel="nofollow"
      >
        <div
          className={classNames(styles['image-container'], {
            [styles['is-exclusives-v3']]: isWebExclusivesV3Experiment,
          })}
        >
          <Image
            className={styles.image}
            {...{ ...listing.getImageOptions(), lazyLoad: false }}
          />
          {showMLBBadge && !isSectionBadge && (
            <div className={styles['image-badge-container']}>
              <MLBBadge />
            </div>
          )}
          {showDealTagLeft && deal && (
            <div className={styles['deal-tag-container']}>
              <DealTag deal={deal} />
            </div>
          )}
          {listing.disclosures.includes(ZONE_TICKET_DISCLOSURE) && (
            <div
              className={
                styles[
                  isSectionBadge
                    ? 'zone-tag-container-section-badge'
                    : 'zone-tag-container'
                ]
              }
            >
              <ZoneTag />
            </div>
          )}
        </div>
        <div className={styles['bottom-fade']} />
        <div className={styles.details}>
          <div className={styles['seat-info-col1']}>
            {showMLBBadge && isSectionBadge && (
              <div className={styles['section-badge-container']}>
                <MLBBadge />
              </div>
            )}

            <div className={styles['section-name']}>{listing.sectionGroup}</div>
            <div
              className={classNames(styles['seat-details'], {
                [styles['wide-label']]: isWidePriceLabel,
              })}
            >
              <span
                className={classNames(styles['seat-details-row'], {
                  [styles['long-row']]: isFocusedCard,
                })}
              >
                {listing.section}
                {', Row '}
                {listing.row}
              </span>
              <Disclosures
                disclosures={listing.disclosures}
                allDisclosures={allDisclosures}
                onlyIcon
              />
            </div>
            {showTicketSource && (
              <div
                className={classNames(styles['listing-source'], {
                  [styles['wide-label']]: isWidePriceLabel,
                })}
              >
                {`Ticket source: ${listing.source}`}
              </div>
            )}
          </div>
          <div className={styles['seat-info-col2']}>
            {showDealTagRight && deal ? (
              <div className={styles['tag-info']}>
                <DealTag deal={deal} />
              </div>
            ) : (
              getValueTag()
            )}
            {isAllInPriceActive && (
              <span className={styles['all-in']}>All-In</span>
            )}
            <div
              className={styles['price-info']}
              key={`${listing.id}-${listing.getPrice()}`}
            >
              <PrimaryPrice
                listing={listing}
                isAllInPriceActive={isAllInPriceActive}
              />

              <TicketPrice
                className={classNames({
                  [styles['super-deal-ticket-price']]: dealType === 'super',
                })}
                price={
                  isAllInPriceActive
                    ? listing.getTotalPrice()
                    : listing.getPrice()
                }
                showEachSuffix={!isCarouselItem}
                eachSuffixStyle={{ color: 'inherit' }}
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

ListingCard.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    showTicketSource: selectShowTicketSourceEnabled(state),
    isAllInPriceActive: selectIsAllInPricing(state),
    showDiscountInDollars: selectIsPercentageVsDollarsExperiment(state),
    isWebExclusivesV3Experiment: selectIsWebExclusivesV3Experiment(state),
    allDeals: selectAllDeals(state),
  };
};

export default connect(mapStateToProps)(ListingCard);
