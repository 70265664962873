import React from 'react';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import Link from 'components/Link/Link';
import ReviewCard from 'components/ReviewCard/ReviewCard';
import TopArrowsCarousel from 'components/TopArrowsCarousel/TopArrowsCarousel';
import config from 'config/config';
import { StarsRatingIcon } from 'icons';
import IOSAppStoreBadge from 'icons/IOSAppStoreBadge';
import { Performer } from 'models';
import { Review } from 'types';

import styles from './ReviewsCarousel.module.scss';

interface ReviewsCarouselProps {
  reviews: Review[];
  performer: Performer;
}

const APP_STORE_REVIEWS_LINK = `${config.APP_STORE_URL}?see-all=reviews`;

const payload = {
  collection: 'app_store_reviews',
};

const ReviewsCarousel = ({ reviews, performer }: ReviewsCarouselProps) => {
  const analytics = useAnalyticsContext();

  const renderHeader = () => {
    const clickTracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.BUTTON())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.PERFORMER())
      .targetPageType(Click.TARGET_PAGE_TYPES.APP_STORE())
      .payload(payload);

    return (
      <div className={styles['reviews-carousel-top']}>
        <h2 className={styles['reviews-carousel-header']}>
          See What Fans Like You Are Saying
        </h2>
        <div className={styles['reviews-appstore']}>
          <Link
            clickTracker={clickTracker}
            href={config.APP_STORE_URL}
            target="_blank"
            className={styles['reviews-link']}
          >
            <IOSAppStoreBadge />
          </Link>
          <Link
            className={styles['reviews-link']}
            href={APP_STORE_REVIEWS_LINK}
            target="_blank"
          >
            <span className={styles['reviews-average']}>4.7</span>
            <div className={styles['reviews-rating']}>
              <div className={styles['reviews-rating-stars']}>
                <StarsRatingIcon fill="none" />
                <StarsRatingIcon fill="none" />
                <StarsRatingIcon fill="none" />
                <StarsRatingIcon fill="none" />
                <StarsRatingIcon fill="none" />
              </div>
              <span>195k Ratings</span>
            </div>
          </Link>
        </div>
      </div>
    );
  };

  const renderReview = (review: Review, index: number) => {
    const readMoreClickTracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.SEE_MORE())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.PERFORMER())
      .targetPageType(Click.TARGET_PAGE_TYPES.REVIEW_MODAL())
      .payload({
        ...payload,
        item_index: index,
        performer_id: performer.id,
        reviewer_name: review.reviewer_name,
      });

    return (
      <ReviewCard
        key={review.id}
        review={review}
        clickTracker={readMoreClickTracker}
      />
    );
  };

  const onTopForwardClick = () => {
    const topForwardTracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.FORWARD_ARROW())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.PERFORMER())
      .payload(payload);

    analytics.track(new Click(topForwardTracker.json()));
  };

  const onTopBackClick = () => {
    const topBackTracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.BACK_ARROW())
      .sourcePageType(Click.SOURCE_PAGE_TYPES.PERFORMER())
      .payload(payload);

    analytics.track(new Click(topBackTracker.json()));
  };

  return (
    <div className={styles['reviews-carousel']}>
      <TopArrowsCarousel
        header={renderHeader()}
        renderItem={renderReview}
        onTopForwardClick={onTopForwardClick}
        onTopBackClick={onTopBackClick}
        items={reviews}
        name="reviews-carousel"
      />
    </div>
  );
};

export default ReviewsCarousel;
