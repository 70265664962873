import { createAction } from '@reduxjs/toolkit';
import _defaults from 'lodash/defaults';
import type { AsyncThunk, RootState } from 'store';

import {
  getListings,
  GetListingsParams,
  ZListingsParams,
} from 'services/listings/listings.service';
import {
  getPreferenceSortOrder,
  userPreferenceSeatCountSelector,
  userPreferenceShowAllInPriceSelector,
} from 'store/modules/userPreference/user.preference.selectors';
import { GetListingsV2Response } from 'types';

import { zListingsSelector } from '../user/user.selectors';

import { selectListingsParams } from './selectors';
import { DEFAULT_LISTINGS_QUANTITY, getSuggestedQuantity } from './utils';

// this is a workaround to get types for these selectors
function selectUserPreferenceSortOrder(state: RootState) {
  return getPreferenceSortOrder(state) as GetListingsParams['sort_order'];
}
function selectUserPreferenceAllInPricing(state: RootState) {
  return userPreferenceShowAllInPriceSelector(
    state
  ) as GetListingsParams['all_in_pricing'];
}
function selectUserPreferenceListingQuantity(state: RootState) {
  return userPreferenceSeatCountSelector(state) || DEFAULT_LISTINGS_QUANTITY;
}

export const fetchListingsRequest = createAction<GetListingsParams>(
  'Listings/FetchListingsRequest'
);
export const fetchListingsSuccess = createAction<{
  eventId: string;
  response: GetListingsV2Response;
}>('Listings/FetchListingsSuccess');
export const fetchListingsFailure = createAction<unknown>(
  'Listings/FetchListingsFailure'
);
export const updateListingsParams = createAction<GetListingsParams>(
  'Listings/UpdateListingsParams'
);

/**
 * Update the listings quantity without re-fetching listings. This action is
 * used on Listing Details where we want to change the quantity without changing
 * curation or pricing.
 */
export const updateListingsQuantity = createAction<number>(
  'Listings/UpdateListingsQuantity'
);

/**
 * Thunk action to fetch Listings from the server and load them into the store
 */
export function fetchListings(incomingParams: GetListingsParams): AsyncThunk {
  return async (dispatch, getState, { mobileApi }) => {
    const state = getState();

    // if we are fetching listings for the same event, apply stored params from
    // the previous request as default values; otherwise use incoming params
    // with saved user preferences as defaults.
    const storedParams = selectListingsParams(state);
    const params =
      incomingParams.eventId === storedParams.eventId
        ? _defaults({}, incomingParams, storedParams)
        : _defaults({}, incomingParams, {
            quantity: selectUserPreferenceListingQuantity(state),
            sort_order: selectUserPreferenceSortOrder(state),
            all_in_pricing: selectUserPreferenceAllInPricing(state),
          });

    dispatch(fetchListingsRequest(params));

    try {
      const zListings = zListingsSelector(state) as ZListingsParams;

      const getListingsResponse = await getListings(
        mobileApi,
        params,
        zListings
      ).then((response) => {
        // if we get a non-null response that does not have the requested
        // quantity in `available_lots`, we will re-request with one of the
        // available quantities. This is to ensure that pricing and exclusive
        // deal curation reflects the quantity requested.
        //
        // Listings V3 will handle this more elegantly by simply returning
        // the quantity used for curation which we will use for quantity in
        // the UI
        const shouldRetryWithAvailableQuantity =
          response &&
          params.quantity &&
          !response.available_lots.includes(params.quantity);

        if (shouldRetryWithAvailableQuantity) {
          const retryParams = {
            ...params,
            quantity: getSuggestedQuantity(response.available_lots),
          };

          dispatch(updateListingsParams(retryParams));

          return getListings(mobileApi, retryParams, zListings);
        }

        return response;
      });

      if (!getListingsResponse) {
        // TODO: how should we handle successful but null response?
        throw new Error('null response from GET Listings request');
      }

      dispatch(
        fetchListingsSuccess({
          eventId: params.eventId,
          response: getListingsResponse,
        })
      );
    } catch (error) {
      dispatch(fetchListingsFailure(error));
    }
  };
}
