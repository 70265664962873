/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface PreloadedImageProps {
  src: string;
}

const PreloadedImage = ({ src }: PreloadedImageProps) => {
  return (
    <Helmet>
      {/* @ts-expect-error fetchpriority will become fetchPriority in React 19 */}
      <link fetchpriority="high" rel="preload" as="image" href={src} />
    </Helmet>
  );
};

export default PreloadedImage;
