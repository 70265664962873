import React from 'react';

const AppleStoreSvg = () => (
  <svg
    width="96"
    height="32"
    viewBox="0 0 96 32"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75594 31.3C6.5122 31.3 6.27434 31.2968 6.03251 31.2914C5.53154 31.2849 5.03173 31.2413 4.5372 31.1609C4.07607 31.0815 3.62936 30.9338 3.21181 30.7226C2.79808 30.5132 2.42073 30.2386 2.09421 29.9093C1.76296 29.5839 1.48718 29.2065 1.2778 28.7921C1.06611 28.375 0.919609 27.9279 0.843398 27.4664C0.761098 26.9704 0.71657 26.469 0.710198 25.9664C0.705126 25.7976 0.698486 25.2359 0.698486 25.2359V6.75542C0.698486 6.75542 0.705558 6.2023 0.710238 6.03979C0.716339 5.53794 0.760608 5.03727 0.842662 4.54214C0.919013 4.07935 1.06563 3.63095 1.27743 3.21246C1.48604 2.79831 1.76029 2.42064 2.08954 2.0941C2.41842 1.76445 2.79698 1.48843 3.21141 1.27612C3.62801 1.06563 4.07387 0.918937 4.53407 0.840967C5.03023 0.75982 5.53177 0.715948 6.03448 0.709719L6.75633 0.699951H88.9712L89.7017 0.710111C90.1998 0.716031 90.6968 0.759511 91.1884 0.840183C91.6532 0.919131 92.1037 1.06684 92.5251 1.27846C93.3554 1.70634 94.0309 2.38328 94.4571 3.21441C94.6655 3.63001 94.8099 4.07476 94.8853 4.53354C94.9683 5.03274 95.0148 5.53734 95.0243 6.04331C95.0266 6.26987 95.0266 6.51323 95.0266 6.75542C95.0329 7.05542 95.0329 7.34097 95.0329 7.62886V24.3718C95.0329 24.6624 95.0329 24.946 95.0266 25.232C95.0266 25.4921 95.0266 25.7304 95.0235 25.9757C95.0142 26.4727 94.9685 26.9682 94.8868 27.4585C94.8121 27.9234 94.6664 28.374 94.4548 28.7945C94.2439 29.2044 93.9697 29.5786 93.6423 29.9031C93.3155 30.2341 92.9375 30.5103 92.5228 30.7211C92.1026 30.9339 91.6527 31.0821 91.1884 31.1609C90.6939 31.2417 90.194 31.2853 89.693 31.2914C89.4587 31.2968 89.2133 31.3 88.9751 31.3L88.1079 31.3015L6.75594 31.3Z"
      fill="black"
    />
  </svg>
);

export default AppleStoreSvg;
