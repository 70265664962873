import { createSelector } from 'reselect';
import type { RootState } from 'store';

import { Listing } from 'models';

type Props = {
  listing: Listing;
};

const selectListingQuantity = (_: RootState, props: Props) =>
  props.listing.currentSeatCount;
const prefeeSeatPriceSelector = (_: RootState, props: Props) =>
  props.listing.getPrice();
const seatPriceSelector = (_: RootState, props: Props) =>
  props.listing.getTotalPrice();
const seatFeeSelector = (_: RootState, props: Props) =>
  props.listing.getTotalPrice() -
  props.listing.getPrice() -
  props.listing.getSalesTax();
const discountSelector = (_: RootState, props: Props) => props.listing.discount;
const salesTaxSelector = (_: RootState, props: Props) =>
  props.listing.getSalesTax();

export const extendedPurchaseSelector = createSelector(
  [
    selectListingQuantity,
    prefeeSeatPriceSelector,
    seatPriceSelector,
    seatFeeSelector,
    discountSelector,
    salesTaxSelector,
  ],
  (quantity, prefeeSeatPrice, seatPrice, seatFee, discount, salesTax) => ({
    totalPrefeePrice: quantity * prefeeSeatPrice,
    fees: quantity * seatFee,
    totalPrice: quantity * seatPrice,
    prefeeSeatPrice,
    seatFee,
    discount,
    salesTax,
  })
);
