import { Action, createAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { PerformerInContext as PerformerInContextModel } from 'models';
import { REDUCERS } from 'store/modules/reducer.constants';
import { PerformerInContext, PerformersInContext } from 'types';

type GenrePerformersState = {
  performers: PerformerInContext[];
};

type StoreState = {
  [REDUCERS.GENRE_PERFORMERS]: GenrePerformersState;
};

export const updateGenrePerformers = createAction<PerformersInContext>(
  'GENRE_PERFORMERS/UPDATE'
);

export const selectGenrePerformers = createSelector(
  [(state: StoreState) => state[REDUCERS.GENRE_PERFORMERS].performers],
  (performers) =>
    performers.map((performer) => new PerformerInContextModel(performer))
);

export const initialState: GenrePerformersState = {
  performers: [],
};

export default function reducer(state = initialState, action: Action) {
  if (updateGenrePerformers.match(action)) {
    return {
      performers: action.payload.performers,
    };
  }

  return state;
}
