import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import DealsSlider from 'components/Deals/DealsSlider/DealsSlider';
import { FullEvent, Listing } from 'models';
import { selectIsListingsLoading } from 'store/modules/listings/selectors';

const CarouselListing = ({
  fullEvent,
  listings = [],
  dealType,
  zoomLevel,
  allDisclosures,
  onListingHover,
  allDeals,
  isWebExclusivesV3Experiment,
  isCarouselFloating,
}) => {
  if (listings.length === 0) {
    return null;
  }

  if (dealType === 'zone') {
    return (
      <DealsSlider
        header="Zone Deals"
        listings={listings}
        fullEvent={fullEvent}
        dealType={dealType}
        onListingHover={onListingHover}
        zoomLevel={zoomLevel}
        allDisclosures={allDisclosures}
        allDeals={allDeals}
      />
    );
  }

  return (
    <DealsSlider
      header={
        isWebExclusivesV3Experiment ? 'Top Deals on Gametime' : 'Top Deals'
      }
      listings={listings}
      fullEvent={fullEvent}
      onListingHover={onListingHover}
      zoomLevel={zoomLevel}
      allDisclosures={allDisclosures}
      allDeals={allDeals}
      isCarouselFloating={isCarouselFloating}
    />
  );
};

CarouselListing.propTypes = {
  fullEvent: PropTypes.instanceOf(FullEvent),
  listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)),
  dealType: PropTypes.string,
  allDisclosures: PropTypes.object,
  allDeals: PropTypes.object,
  zoomLevel: PropTypes.number,
  onListingHover: PropTypes.func,
  isWebExclusivesV3Experiment: PropTypes.bool,
  isCarouselFloating: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isListingsLoading: selectIsListingsLoading(state),
  };
};

export default connect(mapStateToProps)(CarouselListing);
