import { createAction } from '@reduxjs/toolkit';
import type { AsyncThunk } from 'store';

import {
  getPerformers,
  GetPerformersParams,
} from 'services/performer/performer.service';
import { GetPerformersResponse } from 'types';

export const fetchPerformersRequest = createAction<GetPerformersParams>(
  'Performers/FetchPerformersDataRequest'
);
export const fetchPerformersSuccess = createAction<{
  response: GetPerformersResponse;
}>('Performers/FetchPerformersDataSuccess');

export const fetchPerformersFailure = createAction<unknown>(
  'Performers/FetchPerformersDataFailure'
);

export function fetchPerformersData(params: GetPerformersParams): AsyncThunk {
  return async (dispatch, _getState, { mobileApi }) => {
    dispatch(fetchPerformersRequest(params));

    try {
      const response = await getPerformers(mobileApi, params);

      if (!response) {
        throw new Error('null response from GET Performers request');
      }

      dispatch(fetchPerformersSuccess({ response }));
    } catch (error) {
      dispatch(fetchPerformersFailure(error));
    }
  };
}
