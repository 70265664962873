import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  cleanupReCaptchaScript,
  injectReCaptchaScript,
} from 'helpers/Google/GoogleReCaptcha';

const ReCaptchaContext = createContext({
  reCaptchaReady: false,
  executeReCaptcha: undefined,
});

export function ReCaptchaProvider({ children, reCaptchaKey }) {
  const [reCaptchaInstance, setReCaptchaInstance] = useState(null);

  useEffect(() => {
    injectReCaptchaScript(reCaptchaKey)
      .then((reCaptchaInstance) => {
        setReCaptchaInstance(reCaptchaInstance);
      })
      .catch(console.error);

    return () => {
      cleanupReCaptchaScript();
    };
  }, [reCaptchaKey]);

  const executeReCaptcha = useCallback(
    (action) =>
      new Promise((resolve, reject) => {
        if (!reCaptchaInstance?.ready) {
          reject(new Error('reCaptchaInstance is not configured'));
        }

        // reCaptchaInstance.ready only works with a listener callback
        // that executes after reCAPTCHA is ready — no other options for
        // us to await or directly return a value from the inner function
        reCaptchaInstance.ready(() => {
          reCaptchaInstance
            .execute(reCaptchaKey, { action })
            .then(resolve)
            .catch(reject);
        });
      }),
    [reCaptchaInstance, reCaptchaKey]
  );

  const contextValue = useMemo(
    () => ({
      reCaptchaReady: !!reCaptchaInstance,
      executeReCaptcha: reCaptchaInstance ? executeReCaptcha : undefined,
    }),
    [reCaptchaInstance, executeReCaptcha]
  );

  return (
    <ReCaptchaContext.Provider value={contextValue}>
      {children}
    </ReCaptchaContext.Provider>
  );
}
ReCaptchaProvider.propTypes = {
  children: PropTypes.node.isRequired,
  reCaptchaKey: PropTypes.string.isRequired,
};

/**
 * Hook returning ReCaptchaContext value. `executeReCaptcha` will only be
 * available after the reCaptchaInstance is loaded and ready so consumers
 * may need to wait before using.
 */
export function useReCaptcha() {
  const context = useContext(ReCaptchaContext);
  if (!context) {
    throw new Error('useReCaptcha may only be used within ReCaptchaProvider');
  }
  return context;
}

/**
 * HOC to inject ReCaptchaContext values into wrapped class components. Prefer
 * useReCaptcha for function components. `executeReCaptcha` will only be
 * available after the reCaptchaInstance is loaded and ready so consumers
 * may need to wait before using.
 */
export function withReCaptcha(Component) {
  const WithReCaptchaComponent = (props) => (
    <ReCaptchaContext.Consumer>
      {(contextValue) => <Component {...props} {...contextValue} />}
    </ReCaptchaContext.Consumer>
  );

  const displayName = Component.displayName || Component.name || 'Component';
  WithReCaptchaComponent.displayName = `withReCaptcha(${displayName})`;

  return WithReCaptchaComponent;
}
