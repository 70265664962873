import React from 'react';
import _merge from 'lodash/merge';
import AppLink from 'ui/AppLink/AppLink';

import {
  Click,
  DeepLinkClickTracker,
  PAYLOAD,
  useAnalyticsContext,
} from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import { ClickContextType } from 'analytics/context/ClickContext.types';
import PreloadedImage from 'components/Head/PreloadedImage';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import {
  HERO_IMAGE_BOTTOM_URL,
  HERO_IMAGE_TOP_URL,
  PICKS_VIDEO_SRC,
} from 'pages/constants';
import { MODALS, showModal } from 'store/modules/modals/modals';

import PicksBadge from '../PicksBadge/PicksBadge';

import styles from './PicksHero.module.scss';

const PicksHero = () => {
  const isMobile = useMediaQuery(device.down.md);

  const handleMobileDownloadAppClick = () => {
    const analyticsContext = useAnalyticsContext();
    const clickContext: ClickContextType = useClickContext();
    const deeplinkTracker = new DeepLinkClickTracker(true);
    deeplinkTracker.payload({
      [PAYLOAD.ACTION]: 'open_sms',
    });

    analyticsContext.track(
      new Click(_merge({}, clickContext, deeplinkTracker.json()) as Click)
    );
  };

  return (
    <>
      <PreloadedImage src={HERO_IMAGE_TOP_URL} />
      <div
        style={{ backgroundImage: `url(${HERO_IMAGE_TOP_URL})` }}
        className={styles['hero-top']}
      >
        <PicksBadge />
        <h1 className={styles['hero-title']}>
          Amazing Seats. Dynamic Deals. Less Hassle.
        </h1>
        <p className={styles['hero-description']}>
          Gametime Picks is the easiest way to find and buy tickets to live
          events. Get the best deals on great seats at incredible events near
          you, all with less hassle.
        </p>
        {isMobile ? (
          <AppLink onMobileDownloadAppClick={handleMobileDownloadAppClick}>
            <button className={styles['hero-cta']}>Download the App</button>
          </AppLink>
        ) : (
          <button
            onClick={() => showModal(MODALS.GET_APP)}
            className={styles['hero-cta']}
          >
            Download the App
          </button>
        )}
      </div>
      <div
        style={{ backgroundImage: `url(${HERO_IMAGE_BOTTOM_URL})` }}
        className={styles['hero-bottom']}
      >
        <div className={styles['hero-video-container']}>
          <iframe
            className={styles['hero-video']}
            title="vimeo-player"
            src={PICKS_VIDEO_SRC}
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
};

export default PicksHero;
