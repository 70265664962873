import { createSelector } from 'reselect';

import { Performer } from 'models';
import { RootState } from 'store/store';

const selectPerformersState = (state: RootState) => state.performers;

export const selectFetchPerformersData = createSelector(
  selectPerformersState,
  (performersState) => {
    const fetchPerformersData = performersState.fetchPerformersData;
    return {
      ...fetchPerformersData,
      performers: fetchPerformersData.performers.map(
        (performer) => new Performer(performer)
      ),
    };
  }
);

export const selectIsPerformersLoading = (state: RootState) =>
  selectPerformersState(state).status === 'loading';
