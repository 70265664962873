import React, { useState } from 'react';
import _merge from 'lodash/merge';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import MetroSelectorModal from 'components/SelectorModals/MetroSelector/MetroSelector';
import { selectIsHomepageRedesignV1Experiment } from 'experiments';
import CaretDownIcon from 'icons/CaretDownIcon';
import SearchHeroLocationIcon from 'icons/SearchHeroLocationIcon';
import { useAppSelector } from 'store';
import colors from 'styles/colors.constants';
import { Metro } from 'types';

import styles from './MetroPicker.module.scss';

interface MetroPickerProps {
  currentMetro: Metro;
}

type Analytics = {
  track: (arg: Click | unknown) => void;
};

const MetroPicker = ({ currentMetro }: MetroPickerProps) => {
  const analytics: Analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const [openModal, setOpenModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const isHomeRedesign = useAppSelector((state) =>
    selectIsHomepageRedesignV1Experiment(state)
  );

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const toggleModal = () => {
    if (!openModal) {
      const tracker = new ClickTracker().interaction(
        Click.INTERACTIONS.CHANGE_LOCATION()
      );
      analytics.track(
        new Click(_merge({}, clickContext, tracker.json()) as Click)
      );
    }
    setOpenModal(!openModal);
  };

  const caretFillColor =
    isHover || isHomeRedesign ? colors.gametimeGreenLight : colors.gray400;

  return (
    <>
      <div
        data-testid="metro-picker-container"
        className={styles['metro-picker-container']}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={toggleModal}
      >
        <div className={styles['metro-picker']}>
          <SearchHeroLocationIcon />
          <span className={styles['metro-title']}>{currentMetro.name}</span>
          <div className={styles['metro-icon']}>
            <CaretDownIcon
              width={isHomeRedesign ? '24' : '16'}
              height={isHomeRedesign ? '24' : '16'}
              fill={caretFillColor}
            />
          </div>
        </div>
      </div>
      <MetroSelectorModal
        onHide={toggleModal}
        show={openModal}
        redirectToMetro
      />
    </>
  );
};

export default MetroPicker;
