import React from 'react';
import PropTypes from 'prop-types';

import iosStoreBadge from 'assets/download-on-the-app-store-badge.svg';
import playStoreBadge from 'assets/get-it-on-google-play-badge.svg';

import DeepLinkBadge from './DeepLinkBadge';

import styles from './AppBadgesRow.module.scss';

export const altIOSText = 'Get the Gametime app on the Apple app store';
export const altAndroidText = 'Get the Gametime app on Google Play';

const AppBadgesRow = ({ messaging, campaignType }) => (
  <div className={styles['app-banner']}>
    {messaging && <p className={styles['app-banner-title']}>{messaging}</p>}
    <div className={styles['app-badges-container']}>
      {/* consider renaming this component, Props can be simplified on next PR */}
      <DeepLinkBadge
        campaignType={campaignType}
        badgeImgSrc={iosStoreBadge}
        altMessage={altIOSText}
      />
      <DeepLinkBadge
        campaignType={campaignType}
        badgeImgSrc={playStoreBadge}
        altMessage={altAndroidText}
      />
    </div>
  </div>
);

AppBadgesRow.propTypes = {
  messaging: PropTypes.string,
  campaignType: PropTypes.string,
};

export default AppBadgesRow;
