import { SOURCE_PAGE_TYPE_NAMES, TARGET_PAGE_TYPE_NAMES } from '../constants';

import { TrackEvent } from './TrackEvent';

const INTERACTION_NAMES = {
  ACCEPT_TICKETS: 'accept_tickets',
  ACCESS_TICKETS: 'access_tickets',
  ACCOUNT: 'account',
  AFFIRM_BANNER: 'affirm_banner',
  ALL_IN_FILTER: 'all_in_filter',
  GT_PICKS_FILTER: 'gt_picks_filter',
  BACK: 'back',
  BANNER: 'banner',
  BREADCRUMB: 'breadcrumb',
  BUTTON: 'button',
  CAROUSEL: 'carousel',
  CAROUSEL_TILE: 'carousel_tile',
  CELL: 'cell',
  CHANGE_TICKET_QUANTITY: 'change_ticket_quantity',
  CHANGE_LOCATION: 'change_location',
  CLOSE_PANEL: 'close_panel',
  CANCEL: 'cancel',
  CANCEL_ACCCOUNT_DELETION: 'cancel_account_deletion',
  COLLECTION: 'collection',
  CONFIRM: 'confirm',
  CONFIRM_ACCOUNT_DELETION: 'confirm_account_deletion',
  CONTINUE_BUTTON: 'continue_button',
  DATE_PICKER: 'date_picker',
  DOWNLOAD_APP: 'download_app',
  FLASH_DEAL_INFO: 'flash_deal_info',
  FOOTER: 'footer',
  GT_SHIELD_BUTTON: 'gt_shield_button',
  GET_TICKETS: 'get_tickets',
  HEADER: 'header',
  HERO: 'hero',
  HARMONY_TOGGLE_DISABLED: 'harmony_toggle_disabled',
  HARMONY_TOGGLE_ENABLED: 'harmony_toggle_enabled',
  HOME_AWAY_FILTER: 'home_away_filter',
  INSURANCE_OPT_IN: 'insurance_opt_in',
  INSURANCE_OPT_OUT: 'insurance_opt_out',
  LEARN_MORE: 'learn_more',
  LIST: 'list',
  MENU_BAR: 'menu_bar',
  NONE: 'none',
  OMNIBAR: 'omnibar',
  OMNIBAR_RADIO_FEEDBACK: 'radio_feedback',
  OPEN_PANEL: 'open_panel',
  PARKING_MODULE: 'parking_module',
  PRICE_BREAKDOWN: 'price_breakdown',
  PRICE_BUCKET: 'price_bucket',
  PURCHASE_BUTTON: 'purchase_button',
  RADIO_FEEDBACK: 'radio_feedback',
  REDEEM_OFFER: 'redeem_offer',
  REQUEST_ACCOUNT_DELETION: 'request_account_deletion',
  RESEND: 'resend',
  SAVE_PROFILE: 'save_profile',
  SEARCH_BAR: 'search_bar',
  SEARCH_TILE: 'search_tile',
  SEAT_MAP: 'seat_map',
  SEAT_MAP_CONTINUE: 'seat_map_continue',
  SEAT_MAP_PIN: 'seat_map_pin',
  SEE_MORE: 'see_more',
  SELECTOR: 'selector',
  SEND_CODE: 'send_code',
  SEND_LOGIN_LINK: 'send_login_link',
  SETTINGS_TOGGLE: 'settings_toggle',
  SMS_VERIFICATION: 'sms_verification',
  STATIC_PAGE: 'static_page',
  TAB: 'tab',
  TILE: 'tile',
  VENUE: 'venue',
  VERIFY: 'verify',
  ZONE_DEAL_INFO: 'zone_deal_info',
  ZOOM_IN: 'zoom_in',
  ZOOM_OUT: 'zoom_out',
  FOCUS_CARD: 'focus_card',
  FOCUS_CARD_CANCEL: 'focus_card_cancel',
  FORWARD_ARROW: 'forward_arrow',
  BACK_ARROW: 'back_arrow',
};

export class Click extends TrackEvent {
  static INTERACTIONS = {
    ACCEPT_TICKETS: () => ({ interaction: INTERACTION_NAMES.ACCEPT_TICKETS }),
    ACCESS_TICKETS: () => ({ interaction: INTERACTION_NAMES.ACCESS_TICKETS }),
    AFFIRM_BANNER: () => ({
      interaction: INTERACTION_NAMES.AFFIRM_BANNER,
    }),
    ALL_IN_FILTER: () => ({ interaction: INTERACTION_NAMES.ALL_IN_FILTER }),
    GT_PICKS_FILTER: () => ({ interaction: INTERACTION_NAMES.GT_PICKS_FILTER }),
    BACK: () => ({ interaction: INTERACTION_NAMES.BACK }),
    BANNER: () => ({ interaction: INTERACTION_NAMES.BANNER }),
    BLOG: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.BLOG }),
    BLOG_POST: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.BLOG_POST }),
    BREADCRUMB: () => ({ interaction: INTERACTION_NAMES.BREADCRUMB }),
    BUTTON: () => ({ interaction: INTERACTION_NAMES.BUTTON }),
    CANCEL_ACCCOUNT_DELETION: () => ({
      interaction: INTERACTION_NAMES.CANCEL_ACCCOUNT_DELETION,
    }),
    CONFIRM_ACCOUNT_DELETION: () => ({
      interaction: INTERACTION_NAMES.CONFIRM_ACCOUNT_DELETION,
    }),
    CAROUSEL: () => ({
      interaction: INTERACTION_NAMES.CAROUSEL,
    }),
    CELL: () => ({ interaction: INTERACTION_NAMES.CELL }),
    CHANGE_TICKET_QUANTITY: () => ({
      interaction: INTERACTION_NAMES.CHANGE_TICKET_QUANTITY,
    }),
    CHANGE_LOCATION: () => ({ interaction: INTERACTION_NAMES.CHANGE_LOCATION }),
    CHECKOUT: () => ({ source_page_type: TARGET_PAGE_TYPE_NAMES.CHECKOUT }),
    CLOSE_PANEL: () => ({ interaction: INTERACTION_NAMES.CLOSE_PANEL }),
    COLLECTION: () => ({ source_page_type: TARGET_PAGE_TYPE_NAMES.COLLECTION }),
    CONFIRM: () => ({
      interaction: INTERACTION_NAMES.CONFIRM,
    }),
    CONTINUE_BUTTON: (eventId, listingId) => ({
      interaction: INTERACTION_NAMES.CONTINUE_BUTTON,
      event_id: eventId,
      listing_id: listingId,
    }),
    CANCEL: () => ({ interaction: INTERACTION_NAMES.CANCEL }),
    CATEGORY: () => ({ source_page_type: TARGET_PAGE_TYPE_NAMES.CATEGORY }),
    DATE_PICKER: () => ({
      interaction: INTERACTION_NAMES.DATE_PICKER,
      source_page_type: TARGET_PAGE_TYPE_NAMES.PERFORMER,
    }),
    DOWNLOAD_APP: () => ({ interaction: INTERACTION_NAMES.DOWNLOAD_APP }),
    EMAIL: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.EMAIL }),
    EVENT: (pageViewType = null) => {
      const payload = { source_page_type: SOURCE_PAGE_TYPE_NAMES.EVENT };

      if (pageViewType) {
        payload.mode = pageViewType;
      }

      return payload;
    },
    FLASH_DEAL_INFO: () => ({ interaction: INTERACTION_NAMES.FLASH_DEAL_INFO }),
    FOOTER: () => ({ interaction: INTERACTION_NAMES.FOOTER }),
    GET_TICKETS: () => ({ interaction: INTERACTION_NAMES.GET_TICKETS }),
    GT_SHIELD_BUTTON: () => ({
      interaction: INTERACTION_NAMES.GT_SHIELD_BUTTON,
    }),
    HEADER: () => ({ interaction: INTERACTION_NAMES.HEADER }),
    HERO: () => ({ interaction: INTERACTION_NAMES.HERO }),
    HARMONY_TOGGLE_DISABLED: () => ({
      interaction: INTERACTION_NAMES.HARMONY_TOGGLE_DISABLED,
    }),
    HARMONY_TOGGLE_ENABLED: () => ({
      interaction: INTERACTION_NAMES.HARMONY_TOGGLE_ENABLED,
    }),
    HOMEPAGE: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.HOMEPAGE }),
    HOME_AWAY_FILTER: () => ({
      interaction: INTERACTION_NAMES.HOME_AWAY_FILTER,
      source_page_type: TARGET_PAGE_TYPE_NAMES.PERFORMER,
    }),
    INSTALL: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.INSTALL }),
    INSURANCE_OPTIONS_SELECTION: ({
      isSelected,
      eventId,
      listingId,
      performerId,
    }) => ({
      interaction: isSelected
        ? INTERACTION_NAMES.INSURANCE_OPT_IN
        : INTERACTION_NAMES.INSURANCE_OPT_OUT,
      event_id: eventId,
      listing_id: listingId,
      performer_id: performerId,
    }),
    LEARN_MORE: () => ({ interaction: INTERACTION_NAMES.LEARN_MORE }),
    LIST: () => ({ interaction: INTERACTION_NAMES.LIST }),
    LISTING: () => ({ source_page_type: TARGET_PAGE_TYPE_NAMES.LISTING }),
    MAIN_METRO_PAGE: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.MAIN_METRO_PAGE,
    }),
    MENU_BAR: () => ({ interaction: INTERACTION_NAMES.MENU_BAR }),
    METRO: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.METRO }),
    MY_TICKETS: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.MY_TICKETS }),
    NONE: () => ({ interaction: INTERACTION_NAMES.NONE }),
    OMNIBAR: (isFeedback) => {
      if (isFeedback) {
        return { interaction: INTERACTION_NAMES.OMNIBAR_RADIO_FEEDBACK };
      }
      return { interaction: INTERACTION_NAMES.OMNIBAR };
    },
    OPEN_PANEL: () => ({ interaction: INTERACTION_NAMES.OPEN_PANEL }),
    ORDER_CONFIRMATION: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.ORDER_CONFIRMATION,
    }),
    PARKING_MODULE: () => ({
      interaction: INTERACTION_NAMES.PARKING_MODULE,
    }),
    PERFORMER: () => ({ source_page_type: TARGET_PAGE_TYPE_NAMES.PERFORMER }),
    PRICE_BREAKDOWN: () => ({ interaction: INTERACTION_NAMES.PRICE_BREAKDOWN }),
    PRICE_BUCKET: () => ({ interaction: INTERACTION_NAMES.PRICE_BUCKET }),
    PROFILE: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.PROFILE }),
    PURCHASE_BUTTON: () => ({
      interaction: INTERACTION_NAMES.PURCHASE_BUTTON,
    }),
    RADIO_FEEDBACK: () => ({ interaction: INTERACTION_NAMES.RADIO_FEEDBACK }),
    REDEEM_CODE: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.REDEEM_CODE,
    }),
    REDEEM_OFFER: () => ({
      interaction: INTERACTION_NAMES.REDEEM_OFFER,
    }),
    REQUEST_ACCOUNT_DELETION: () => ({
      interaction: INTERACTION_NAMES.REQUEST_ACCOUNT_DELETION,
    }),
    RESEND: () => ({
      interaction: INTERACTION_NAMES.RESEND,
    }),
    SAVE_PROFILE: () => ({
      intersection: INTERACTION_NAMES.SAVE_PROFILE,
    }),
    SCHEDULE: () => ({ source_page_type: TARGET_PAGE_TYPE_NAMES.SCHEDULE }),
    SEARCH: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.SEARCH }),
    SEARCH_BAR: () => ({ interaction: INTERACTION_NAMES.SEARCH_BAR }),
    SEARCH_TILE: () => ({ interaction: INTERACTION_NAMES.SEARCH_TILE }),
    SEAT_MAP: () => ({ interaction: INTERACTION_NAMES.SEAT_MAP }),
    SEAT_MAP_CONTINUE: () => ({
      interaction: INTERACTION_NAMES.SEAT_MAP_CONTINUE,
    }),
    SEAT_MAP_PIN: () => ({ interaction: INTERACTION_NAMES.SEAT_MAP_PIN }),
    SEE_MORE: () => ({ interaction: INTERACTION_NAMES.SEE_MORE }),
    SELECTOR: () => ({ interaction: INTERACTION_NAMES.SELECTOR }),
    SEND_CODE: () => ({
      interaction: INTERACTION_NAMES.SEND_CODE,
    }),
    SEND_LOGIN_LINK: () => ({
      interaction: INTERACTION_NAMES.SEND_LOGIN_LINK,
    }),
    SETTINGS_TOGGLE: () => ({ interaction: INTERACTION_NAMES.SETTINGS_TOGGLE }),
    SMS_VERIFICATION: () => ({
      interaction: INTERACTION_NAMES.SMS_VERIFICATION,
    }),
    SPORTS_BY_METRO: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.SPORTS_BY_METRO,
    }),
    STATIC_PAGE: () => ({
      source_page_type: TARGET_PAGE_TYPE_NAMES.STATIC_PAGE,
    }),
    SUPER_BOWL: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.SUPER_BOWL }),
    TAB: () => ({ interaction: INTERACTION_NAMES.TAB }),
    TICKET: () => ({ source_page_type: TARGET_PAGE_TYPE_NAMES.TICKET }),
    TILE: (isCarouselItem) => {
      if (isCarouselItem) {
        return { interaction: INTERACTION_NAMES.CAROUSEL_TILE };
      }
      return { interaction: INTERACTION_NAMES.TILE };
    },
    FOCUSED_CARD: () => ({
      interaction: INTERACTION_NAMES.FOCUS_CARD,
    }),
    FOCUSED_CARD_CANCEL: () => ({
      interaction: INTERACTION_NAMES.FOCUS_CARD_CANCEL,
    }),
    TRANSFER_TICKETS: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.TRANSFER_TICKETS,
    }),
    UNKNOWN: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.UNKNOWN }),
    VENUE: () => ({ source_page_type: TARGET_PAGE_TYPE_NAMES.VENUE }),
    VERIFY: () => ({
      interaction: INTERACTION_NAMES.VERIFY,
    }),
    ZONE_DEAL_INFO: () => ({ interaction: INTERACTION_NAMES.ZONE_DEAL_INFO }),
    ZOOM_IN: () => ({
      interaction: INTERACTION_NAMES.ZOOM_IN,
    }),
    ZOOM_OUT: () => ({
      interaction: INTERACTION_NAMES.ZOOM_OUT,
    }),
    FORWARD_ARROW: () => ({
      interaction: INTERACTION_NAMES.FORWARD_ARROW,
    }),
    BACK_ARROW: () => ({
      interaction: INTERACTION_NAMES.BACK_ARROW,
    }),
  };

  static SOURCE_PAGE_TYPES = {
    ACCOUNT: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.ACCOUNT }),
    ACCOUNT_VERIFICATION: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.ACCOUNT_VERIFICATION,
    }),
    ADD_CREDIT_CARD_MANUAL: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.ADD_CREDIT_CARD_MANUAL,
    }),
    ADD_EMAIL: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.ADD_EMAIL }),
    ADD_PHONE_NUMBER: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.ADD_PHONE_NUMBER,
    }),
    BLOG: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.BLOG }),
    BLOG_POST: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.BLOG_POST }),
    CATEGORY: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.CATEGORY }),
    CHECKOUT: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.CHECKOUT }),
    COLLECTION: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.COLLECTION }),
    CVV_VERIFICATION: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.CVV_VERIFICATION,
    }),
    EVENT: (pageViewType = null) => {
      const payload = { source_page_type: SOURCE_PAGE_TYPE_NAMES.EVENT };

      if (pageViewType) {
        payload.mode = pageViewType;
      }

      return payload;
    },
    HOMEPAGE: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.HOMEPAGE }),
    INSTALL: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.INSTALL }),
    LISTING: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.LISTING }),
    LOGIN: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.LOGIN }),
    MAIN_METRO_PAGE: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.MAIN_METRO_PAGE,
    }),
    METRO: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.METRO }),
    MY_ACCOUNT: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.MY_ACCOUNT }),
    MY_TICKETS: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.MY_TICKETS }),
    NOT_FOUND: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.NOT_FOUND }),
    OMNIBAR_PAGES: (displayKey) => {
      switch (displayKey) {
        case 'filterId':
          return { source_page_type: SOURCE_PAGE_TYPE_NAMES.OMNIBAR_FILTER };
        case 'sortId':
          return { source_page_type: SOURCE_PAGE_TYPE_NAMES.OMNIBAR_SORT };
        case 'schedule':
          return { source_page_type: SOURCE_PAGE_TYPE_NAMES.SCHEDULE };
        case 'allInPricing':
          return { source_page_type: TARGET_PAGE_TYPE_NAMES.ALL_IN_FILTER };
        case 'gtPicksFilter':
          return { source_page_type: SOURCE_PAGE_TYPE_NAMES.GT_PICKS_FILTER };
        default:
          return { source_page_type: SOURCE_PAGE_TYPE_NAMES.OMNIBAR_QUANTITY };
      }
    },
    ORDER_CONFIRMATION: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.ORDER_CONFIRMATION,
    }),
    PERFORMER: (performerId) => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.PERFORMER,
      performer_id: performerId,
    }),
    PROFILE: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.PROFILE }),
    REDEEM_CODE: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.REDEEM_CODE,
    }),
    SEARCH: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.SEARCH }),
    SCHEDULE: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.SCHEDULE }),
    SMS_VERIFICATION: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.SMS_VERIFICATION,
    }),
    SPORTS_BY_METRO: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.SPORTS_BY_METRO,
    }),
    STATIC_PAGE: (slug) => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.STATIC_PAGE,
      page_name: slug,
    }),
    TICKET: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.TICKET }),
    TICKET_QUANTITY: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.TICKET_QUANTITY,
    }),
    TRANSFER_TICKETS: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.TRANSFER_TICKETS,
    }),
    UNKNOWN: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.UNKNOWN }),
    VERIFY_BILLING_ADDRESS: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.VERIFY_BILLING_ADDRESS,
    }),
    VERIFY_SHIPPING_ADDRESS: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.VERIFY_SHIPPING_ADDRESS,
    }),
    VENUE: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.VENUE }),
    GAMETIME_PICKS: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.GAMETIME_PICKS,
    }),
  };

  static TARGET_PAGE_TYPES = {
    ACCOUNT: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.ACCOUNT }),
    ADD_CREDIT_CARD_MANUAL: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.ADD_CREDIT_CARD_MANUAL,
    }),
    AFFIRM_PAYMENT_METHOD: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.AFFIRM_PAYMENT_METHOD,
    }),
    APP_STORE: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.APP_STORE }),
    APPLE_PAY_PAYMENT_METHOD: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.APPLE_PAY_PAYMENT_METHOD,
    }),
    BETMGM: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.BETMGM,
    }),
    BLOG_POST: (slug) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.BLOG_POST,
      post_id: slug,
    }),
    CATEGORY: (categoryName) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.CATEGORY,
      category_name: categoryName,
    }),
    CHECKOUT: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.CHECKOUT }),
    COLLECTION: (collectionName) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.COLLECTION,
      collection_name: collectionName,
    }),
    CVV_VERIFICATION: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.CVV_VERIFICATION,
    }),
    EMAIL: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.EMAIL }),
    EVENT: (eventId) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.EVENT,
      event_id: eventId,
    }),
    GAMETIME_GUARANTEE: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.GAMETIME_GUARANTEE,
    }),
    HOMEPAGE: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.HOMEPAGE }),
    INSTALL: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.INSTALL }),
    LISTING: (
      listingId,
      eventId,
      allInPricing,
      displayedPrice,
      isListingOverlay = false
    ) => {
      const base = {
        target_page_type: TARGET_PAGE_TYPE_NAMES.LISTING,
        listing_id: listingId,
        event_id: eventId,
        all_in_pricing: allInPricing,
        displayed_price: displayedPrice,
      };

      if (isListingOverlay) {
        base.source_page_type = TARGET_PAGE_TYPE_NAMES.LISTING;
      }

      return base;
    },
    MAGIC_LINK: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.MAGIC_LINK,
    }),
    MAIN_METRO_PAGE: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.MAIN_METRO_PAGE,
    }),
    METRO: (metro) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.METRO,
      metro,
    }),
    MY_TICKETS: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.MY_TICKETS }),
    NONE: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.NONE }),
    NOT_FOUND: () => ({ source_page_type: SOURCE_PAGE_TYPE_NAMES.NOT_FOUND }),
    OMNIBAR_PAGES: (displayKey) => {
      switch (displayKey) {
        case 'filterId':
          return { target_page_type: TARGET_PAGE_TYPE_NAMES.OMNIBAR_FILTER };
        case 'sortId':
          return { target_page_type: TARGET_PAGE_TYPE_NAMES.OMNIBAR_SORT };
        case 'schedule':
          return { target_page_type: TARGET_PAGE_TYPE_NAMES.SCHEDULE };
        case 'allInPricing':
          return { target_page_type: TARGET_PAGE_TYPE_NAMES.ALL_IN_FILTER };
        case 'price_bucket':
          return { target_page_type: TARGET_PAGE_TYPE_NAMES.PRICE_BUCKET };
        case 'gtPicksFilter':
          return { target_page_type: TARGET_PAGE_TYPE_NAMES.GT_PICKS_FILTER };
        default:
          return { target_page_type: TARGET_PAGE_TYPE_NAMES.OMNIBAR_QUANTITY };
      }
    },
    ORDER_CONFIRMATION: (transactionId) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.ORDER_CONFIRMATION,
      transaction_id: transactionId,
    }),
    PAYPAL_PAYMENT_METHOD: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.PAYPAL_PAYMENT_METHOD,
    }),
    PERFORMER: (performerId) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.PERFORMER,
      performer_id: performerId,
    }),
    PROFILE: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.PROFILE }),
    REDEEM_CODE: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.REDEEM_CODE,
    }),
    REVIEW_MODAL: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.REVIEW_MODAL,
    }),
    SCHEDULE: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.SCHEDULE }),
    SEARCH: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.SEARCH }),
    SMS_VERIFICATION: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.SMS_VERIFICATION,
    }),
    SPORTS_BY_METRO: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.SPORTS_BY_METRO,
    }),
    STATIC_PAGE: (pageName) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.STATIC_PAGE,
      page_name: pageName,
    }),
    SUPER_BOWL: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.SUPER_BOWL }),
    TICKET: (ticketId, transactionId, eventId) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.TICKET,
      ticket_id: ticketId,
      transaction_id: transactionId,
      event_id: eventId,
    }),
    TICKET_QUANTITY: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.TICKET_QUANTITY,
    }),
    TRANSFER_TICKETS: () => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.TRANSFER_TICKETS,
    }),
    UNKNOWN: () => ({ target_page_type: TARGET_PAGE_TYPE_NAMES.UNKNOWN }),
    VERIFY_BILLING_ADDRESS: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.VERIFY_BILLING_ADDRESS,
    }),
    VERIFY_SHIPPING_ADDRESS: () => ({
      source_page_type: SOURCE_PAGE_TYPE_NAMES.VERIFY_SHIPPING_ADDRESS,
    }),
    VENUE: (venueId) => ({
      target_page_type: TARGET_PAGE_TYPE_NAMES.VENUE,
      venue_id: venueId,
    }),
  };

  _eventName = 'click';
}
