import React from 'react';
import classNames from 'classnames';

import { Listing } from 'models';
import { isObjectEmpty } from 'utils/objects';

import { usePinPosition } from '../hooks';
import { useTransformContext } from '../SeatMapInteraction';

import styles from './PinContainer.module.scss';

interface PinContainerProps {
  children: React.ReactNode;
  mapImage: { width: number; height: number };
  listing: Listing;
  customStyles?: React.CSSProperties;
  isLargePin: boolean;
  customSize?: number;
  pinContainerClassNames?: string;
}

const PIN_HEIGHT_LARGE = 54;
const PIN_HEIGHT_SMALL = 38;

/**
 * Note:
 * PinContainer should be used on all pins to
 * encapsulate zoom logic and to prevent/reduce unwanted re-renders.
 * PinContainer must be placed inside TransformWrapper Map (view test file).
 */
const PinContainer = ({
  children,
  mapImage,
  listing,
  customStyles = {},
  isLargePin,
  customSize,
  pinContainerClassNames = '',
}: PinContainerProps) => {
  const { state } = useTransformContext();

  const { widthRatio, heightRatio, inverseScale } = usePinPosition(
    mapImage.width,
    mapImage.height,
    state.scale
  );

  if (isObjectEmpty(listing) || mapImage.width === -1) {
    return null;
  }

  const coord = {
    top: listing.position.y * heightRatio,
    left: listing.position.x * widthRatio,
  };

  const baseStyles: React.CSSProperties = {
    ...coord,
  };

  if (customSize) {
    const adjustedHeight = customSize * inverseScale;
    baseStyles.marginTop = -adjustedHeight;
    baseStyles.transform = `translateX(-6px) scale(${inverseScale})`;
  } else {
    const inverseTranslateX = (inverseScale / 2) * 100;

    const pinHeight = isLargePin ? PIN_HEIGHT_LARGE : PIN_HEIGHT_SMALL;
    const adjustedHeight = pinHeight * inverseScale;
    baseStyles.transform = `translateX(-${inverseTranslateX}%) scale(${inverseScale})`;
    baseStyles.marginTop = -adjustedHeight;
  }

  return (
    <div
      id={listing.id}
      data-testid="pin-container"
      className={classNames(styles['pin-container'], pinContainerClassNames)}
      style={{ ...baseStyles, ...customStyles }}
    >
      {children}
    </div>
  );
};

export default PinContainer;
