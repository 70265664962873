export type URLSearchParamsInit = ConstructorParameters<
  typeof URLSearchParams
>[0];

export const addQuery = (
  path: string,
  search: string,
  queryObj: Record<string, string | number | boolean | null | undefined>
) => {
  const params = new URLSearchParams(search);
  Object.entries(queryObj).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      params.set(key, value.toString());
    }
  });

  // our Jest > JSDom version does not support URLSearchParams.size
  const size = [...params].length;

  return size > 0 ? `${path}?${params}` : path;
};

export const isInvalidHref = (href: unknown) =>
  typeof href !== 'string' || !href || href.trim() === '#';

const STANDARD_UTM_PARAMS = {
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_TERM: 'utm_term',
  UTM_CONTENT: 'utm_content',
} as const;

/**
 * Returns an object containing standard and custom UTM parameters from a
 * valid URLSearchParams init value. The five standard UTM parameters are
 * prioritized first, then any additional parameters beginning with "utm_"
 * are included in the order they are listed, up to an optionally specified
 * limit. Duplicate parameters will only use the first value,
 * subsequent occurrences will be omitted.
 *
 * Current default limit is 20 as requested by Growth team.
 *
 * see: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
export function getUTMSearchParams(init: URLSearchParamsInit, limit = 20) {
  const searchParams = new URLSearchParams(init);

  const params: Record<string, string> = {};

  // prioritize standard UTM params and remove from collection
  Object.values(STANDARD_UTM_PARAMS).forEach((key) => {
    const value = searchParams.get(key);
    if (value) {
      params[key] = value;
    }

    // param can still exist with falsy value
    if (searchParams.has(key)) {
      searchParams.delete(key);
    }
  });

  // collect remaining custom UTM params in order received up to limit
  for (const [key, value] of searchParams) {
    if (Object.keys(params).length >= limit) {
      break;
    }

    if (key.startsWith('utm_') && !params[key]) {
      params[key] = value;
    }
  }

  return params;
}

export function secureUrl(url?: string) {
  return url?.replace(/^http:\/\//, 'https://');
}

export const queryObjectToSearchString = (
  query: Record<string, string | number | boolean | null | undefined>
) => {
  const params = new URLSearchParams();
  for (const key in query) {
    const value = query[key];
    if (value !== undefined && value !== null) {
      params.set(key, value.toString());
    }
  }

  return params.toString();
};

export const searchStringToQueryObject = (search = '') =>
  Object.fromEntries(new URLSearchParams(search));

export const removeQueryParams = (search: string, keys: string[]) => {
  const params = new URLSearchParams(search);

  keys.forEach((key) => {
    params.delete(key);
  });

  const stringParams = params.toString();
  return stringParams !== '' ? `?${stringParams}` : '';
};
