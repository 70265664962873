import React, { useMemo } from 'react';
import classNames from 'classnames';
import ExternalIcon from 'ui/ExternalIcon/ExternalIcon';

import Image from 'components/Image/Image';
import { Deal } from 'models';
import colors from 'styles/colors.constants';

import styles from './SeatMapPinExclusives.module.scss';

interface BigPinConfig {
  button?: React.ReactNode;
  imageProps?: React.ComponentProps<typeof Image>;
}

const getPinStyles = (deal?: Deal, isSelected?: boolean) => {
  const pinStyles = {
    '--pin-color': colors.gray300,
    '--body-border-color': colors.gray300,
    '--body-background': isSelected ? colors.white : 'rgba(0, 0, 0, 0.75)',
    '--body-color': isSelected ? colors.black : colors.white,
    '--hover-body-background': colors.white,
    '--hover-body-border-color': colors.gray200,
    '--zone-marker-visibility': 'hidden',
  };

  if (deal) {
    pinStyles['--pin-color'] = colors.gray200;
    pinStyles['--body-border-color'] = colors.gray200;
  }

  if (deal?.colorGradient) {
    pinStyles['--pin-color'] = deal.colorGradient[0];
    pinStyles['--body-border-color'] = colors.whiteTransparentLight;
    pinStyles['--body-background'] =
      `linear-gradient(${deal.colorGradient.join(', ')}) border-box`;
    pinStyles['--body-color'] = colors.black;
    pinStyles['--hover-body-background'] =
      `linear-gradient(white, white) padding-box,  linear-gradient(${deal.colorGradient.join(', ')})`;
    pinStyles['--hover-body-border-color'] = colors.transparent;
  }

  if (deal?.slug === 'zone') {
    pinStyles['--pin-color'] = deal.color;
    pinStyles['--body-border-color'] = deal.color;
    pinStyles['--body-color'] = deal.color;
    pinStyles['--hover-body-border-color'] = deal.color;
    pinStyles['--zone-marker-visibility'] = 'visible';
  }

  return pinStyles;
};

function SeatMapPinExclusives({
  text,
  isSelected = false,
  bigPinConfig,
  deal,
}: {
  text?: string;
  isSelected?: boolean;
  bigPinConfig?: BigPinConfig;
  deal?: Deal;
}) {
  const pinStyles = useMemo(
    () => getPinStyles(deal, isSelected),
    [deal, isSelected]
  );

  const smallIconColor = deal?.colorGradient ? colors.black : deal?.color;
  const dealIconColor = !bigPinConfig ? smallIconColor : deal?.color;

  return (
    <div
      className={classNames(styles['seat-map-pin'], {
        [styles['selected']]: isSelected,
      })}
      style={pinStyles as React.CSSProperties}
    >
      <div
        className={classNames(styles['body'], {
          [styles['no-icon']]: !deal,
          [styles['big-pin']]: !!bigPinConfig,
        })}
      >
        <div
          className={classNames(styles['body-container'], {
            [styles['big-pin']]: !!bigPinConfig,
          })}
        >
          <div className={styles['content']}>
            {deal?.iconURL && (
              <ExternalIcon
                src={deal.iconURL}
                title={deal.title}
                color={dealIconColor}
                colorGradient={bigPinConfig ? deal.colorGradient : undefined}
              />
            )}
            <span className={styles['text']}>{text}</span>
          </div>
          {bigPinConfig?.button && (
            <div className={styles['big-pin-button']}>
              {bigPinConfig.button}
            </div>
          )}
        </div>
        {bigPinConfig && (
          <div className={styles['image-container']}>
            <div className={styles['image-overlay']} />
            <Image
              {...bigPinConfig.imageProps}
              className={classNames(
                styles['big-pin-image'],
                bigPinConfig.imageProps?.className
              )}
            />
          </div>
        )}
      </div>
      <svg
        className={styles['pin-stick']}
        width="32"
        height="25"
        viewBox="0 0 32 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          className={styles['zone-marker']}
          style={{ fill: deal?.color } as React.CSSProperties}
        >
          <circle opacity="0.2" cx="16" cy="14" r="8" />
          <ellipse opacity="0.2" cx="16" cy="14" rx="16" ry="11" />
        </g>
        <circle cx="16" cy="13" r="3" fill="black" />
        <g
          filter={deal?.slug === 'zone' ? 'url(#filter0_d_47_1936)' : undefined}
        >
          <path
            className={styles['pin-stick-path']}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 0H13C14.1046 0 15 .8954 15 2V13C15 13.5523 15.4477 14 16 14 16.5523 14 17 13.5523 17 13V2C17 .8954 17.8954 0 19 0Z"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_47_1936"
            x="9"
            y="0"
            width="14"
            height="22"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_47_1936"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_47_1936"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default React.memo(SeatMapPinExclusives);
