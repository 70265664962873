import React from 'react';

import styles from './VerifiedBlock.module.scss';

function VerifiedBlock() {
  return (
    <div
      className={styles.content}
      role="region"
      aria-labelledby="verified-header"
    >
      <h3 id="verified-header" className={styles.title}>
        Verified Tickets
      </h3>
      <p className={styles.message}>100% Gametime Guaranteed</p>
    </div>
  );
}

export default VerifiedBlock;
