import React, { useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useMeasure } from 'react-use';
import { useEvent } from 'contexts/EventContext';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { selectIsNoNFLAutozoomDesktopExperiment } from 'experiments';
import { device, useMediaQuery, useResize } from 'hooks';
import { Deal, Disclosure } from 'models';
import { sortListings } from 'pages/Event/helpers';
import { CATEGORIES } from 'store/modules/categories/category.helpers';
import { updateMapHarmony } from 'store/modules/data/Listings/actions';
import { listMapHarmonyToggleSelector } from 'store/modules/data/Listings/selectors';
import { selectAllDeals } from 'store/modules/resources/resource.selectors';

import { useMapDimensions, useMapTracking } from './hooks';
import ListMapHarmonyControl from './ListMapHarmony';
import { SeatMapImage, SeatMapPins } from './SeatMap';
import SeatMapControls from './SeatMapControls';
import {
  SeatMapInteraction,
  SeatMapInteractionMain,
} from './SeatMapInteraction';
import SeatMapLoadIndicator from './SeatMapLoadIndicator';

import styles from './ListingsMapView.module.scss';

const ListingsMapView = ({
  highlightedListingId,
  handleMapInteraction,
  handleListingSelection,
  onListingClose,
  onPinHover,
  isEventPage = false,
  isListingDetailsPage = false,
  allDeals,
  allDisclosures,
  isAllInPrice = false,
  isListingFlow,
  initiateCheckoutFlow,
  handleListMapHarmonyToggleTracking,
  isCheckout,
  dispatch,
  listMapHarmonyToggleIsOn,
  isHarmonyPlusOverlay,
  onTouchInteractionStart,
  hasGalleryViewMobileListing,
  showSinglePin,
  isNoNFLAutozoomDesktop,
  isGalleryViewV3Experiment,
  isAnimating,
  onTouchInteractionEnd,
}) => {
  const isMobile = useMediaQuery(device.down.md);
  const { fullEvent, displayedListings, activeListing } = useEvent();

  const seatMapPinListings = useMemo(() => {
    if (showSinglePin) {
      return [activeListing];
    }

    return sortListings(displayedListings);
  }, [displayedListings, showSinglePin, activeListing]);

  const seatMapRef = useRef();
  const mapImageRenderMsRef = useRef(0);

  const [isMapLoaded, setMapLoaded] = useState(false);
  const [isMapError, setMapError] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [focusedPins, setFocusedPins] = useState([]);
  const isDeviceLargeUp = useMediaQuery(device.up.lg);
  const isEventPageDesktop = isEventPage && isDeviceLargeUp;

  const mapTracking = useMapTracking();

  const [measureRef, { width: rectWidth = 0, height: rectHeight = 0 }] =
    useMeasure();

  const shouldShowHarmony =
    isMapLoaded &&
    isHarmonyPlusOverlay &&
    isEventPageDesktop &&
    !fullEvent.isMusicCategory &&
    seatMapPinListings.length > 0;

  const observeMapHarmony = shouldShowHarmony && listMapHarmonyToggleIsOn;

  /*
      Handle window resize map harmony
  */
  const debounceHarmony = useMemo(() => {
    return _debounce(() => dispatch(updateMapHarmony()), 1000);
  }, [dispatch]);

  useResize(() => observeMapHarmony && debounceHarmony());

  /*
    a) On map image load sets the map image proportions.
    b) On window resize, calculates pin's coordinates based on map size.
  */
  const mapImage = useMapDimensions(rectWidth, rectHeight, isMapLoaded);

  // Track measurements when loading the page
  const handlePinsRenderTracking = (lastPinRenderMs) => {
    if (!isEventPage) return;
    const trackingData = mapTracking.getLoadTime(
      lastPinRenderMs,
      mapImageRenderMsRef.current
    );
    handleMapInteraction(trackingData);
  };

  /*
      OnMapInteraction is called when user interacts with the map.
      It handles the following, not in order:
        a) Updates map harmony (if observeMapHarmony is true)
        b) Handles zoom coach banner (mobile only)
        c) Handles map tracking
  */
  const onMapInteraction = (mapState) => {
    // Exit execution
    if (isMapError || !isEventPage || !mapState.interaction.eventType) {
      return;
    }

    // Get tracking data for the map interaction
    const mapInteraction = mapTracking.getData(mapState);

    // Update map harmony if it is being observed
    if (observeMapHarmony) {
      const timeoutLength = 500;
      setTimeout(() => dispatch(updateMapHarmony()), timeoutLength);
    }

    // Handle the map interaction tracking
    handleMapInteraction(mapInteraction.data);

    if (onTouchInteractionEnd) onTouchInteractionEnd();
  };

  /*
    Handle map controls zoom in/out and reset
  */
  const handleZoomControls = (mapState) => {
    setTimeout(() => onMapInteraction(mapState), 500);
  };

  const setMapImageRenderMs = (mapImageRenderMs) => {
    if (!mapImageRenderMsRef.current) {
      mapImageRenderMsRef.current = mapImageRenderMs;
    }
  };

  const shouldShowMapControls =
    !hasGalleryViewMobileListing ||
    (hasGalleryViewMobileListing && isGalleryViewV3Experiment);

  return (
    <div ref={measureRef} className={styles['map-container']}>
      <section
        ref={setMapRef}
        style={{
          width: mapImage.width,
        }}
      >
        <SeatMapLoadIndicator loaded={isMapLoaded} error={isMapError} />
        {mapRef && (
          <SeatMapInteraction
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
            isMobile={isMobile}
            onPanInteractionEnd={onMapInteraction}
            zoomInteractionEnd={onMapInteraction}
            onTouchInteractionStart={onTouchInteractionStart}
            shouldExecuteOnTouchInteractionStart={
              hasGalleryViewMobileListing && isEventPage
            }
          >
            {({ zoomIn, zoomOut, state, resetTransform }) => {
              const { scale } = state;
              return (
                <>
                  {shouldShowMapControls && (
                    <SeatMapControls
                      onZoomIn={() => {
                        if (scale === 5) return;
                        zoomIn();
                        handleZoomControls(state);
                      }}
                      onZoomOut={() => {
                        if (scale === 1) return;
                        zoomOut();
                        handleZoomControls(state);
                      }}
                      reset={() => {
                        resetTransform();
                        setFocusedPins([]);
                        handleZoomControls(state);
                      }}
                      allowReset={scale > 1}
                      isGalleryViewV3Experiment={isGalleryViewV3Experiment}
                      isAnimating={isAnimating}
                    />
                  )}
                  {shouldShowHarmony && (
                    <ListMapHarmonyControl
                      handleListMapHarmonyToggleTracking={
                        handleListMapHarmonyToggleTracking
                      }
                    />
                  )}
                  <SeatMapInteractionMain isLoaded={isMapLoaded}>
                    <SeatMapImage
                      ref={seatMapRef}
                      mapUrl={fullEvent.event.mapUrl}
                      scale={scale}
                      onLoad={(mapImageRenderMs) => {
                        setMapLoaded(true);
                        setMapImageRenderMs(mapImageRenderMs);
                      }}
                      onError={() => setMapError(true)}
                      noZoomOnLoad={
                        isNoNFLAutozoomDesktop &&
                        !isMobile &&
                        fullEvent.category === CATEGORIES.NFL
                      }
                    />
                    <SeatMapPins
                      listings={seatMapPinListings}
                      mapImage={mapImage}
                      allDeals={allDeals}
                      allDisclosures={allDisclosures}
                      isMapLoaded={isMapLoaded}
                      venueName={fullEvent.venue.name}
                      isMLBEvent={fullEvent.isMLBEvent}
                      highlightedListingId={highlightedListingId}
                      isListingDetailsPage={isListingDetailsPage}
                      onPinClick={handleListingSelection}
                      onPinHover={onPinHover}
                      onListingClose={onListingClose}
                      isAllInPriceActive={isAllInPrice}
                      focusedPins={focusedPins}
                      currentListingId={activeListing?.id}
                      initiateCheckoutFlow={initiateCheckoutFlow}
                      isListingFlow={isListingFlow}
                      observeMapHarmony={observeMapHarmony}
                      isCheckout={isCheckout}
                      isHarmonyPlusOverlay={isHarmonyPlusOverlay}
                      onLastPinRender={handlePinsRenderTracking}
                      showGalleryViewTooltip={isDeviceLargeUp}
                    />
                  </SeatMapInteractionMain>
                </>
              );
            }}
          </SeatMapInteraction>
        )}
      </section>
    </div>
  );
};

ListingsMapView.propTypes = {
  handleListingSelection: PropTypes.func.isRequired,
  onListingClose: PropTypes.func,
  onPinHover: PropTypes.func.isRequired,
  highlightedListingId: PropTypes.string,
  allDeals: PropTypes.objectOf(PropTypes.instanceOf(Deal)),
  allDisclosures: PropTypes.objectOf(PropTypes.instanceOf(Disclosure)),
  isEventPage: PropTypes.bool,
  isListingDetailsPage: PropTypes.bool,
  isAllInPrice: PropTypes.bool,
  initiateCheckoutFlow: PropTypes.func,
  isListingFlow: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  handleMapInteraction: PropTypes.func.isRequired,
  listMapHarmonyToggleIsOn: PropTypes.bool,
  handleListMapHarmonyToggleTracking: PropTypes.func,
  isCheckout: PropTypes.bool,
  isHarmonyPlusOverlay: PropTypes.bool,
  onTouchInteractionStart: PropTypes.func.isRequired,
  hasGalleryViewMobileListing: PropTypes.bool.isRequired,
  showSinglePin: PropTypes.bool.isRequired,
  isNoNFLAutozoomDesktop: PropTypes.bool.isRequired,
  isGalleryViewV3Experiment: PropTypes.bool.isRequired,
  isAnimating: PropTypes.bool.isRequired,
  onTouchInteractionEnd: PropTypes.func,
};

const mapStateToProps = (state) => ({
  allDeals: selectAllDeals(state),
  listMapHarmonyToggleIsOn: listMapHarmonyToggleSelector(state),
  isNoNFLAutozoomDesktop: selectIsNoNFLAutozoomDesktopExperiment(state),
});

export default connect(mapStateToProps)(ListingsMapView);
