import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Tag from 'ui/Tag/Tag';

import DealTag from 'components/DealTag';
import Image from 'components/Image/Image';
import MLBBadge from 'components/MLBBadge/MLBBadge';
import Disclosures from 'components/Modals/DisclosuresModal/Disclosures';
import TicketPrice from 'components/TicketPrice/TicketPrice';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import {
  selectIsPercentageVsDollarsExperiment,
  selectIsWebExclusivesV3Experiment,
} from 'experiments';
import { DiscountIcon } from 'icons';
import { Deal, Disclosure, Listing } from 'models';
import { DEAL_VALUE_PERCENTILE } from 'pages/Listing/constants';
import { RootState } from 'store';
import { selectAllDeals } from 'store/modules/resources/resource.selectors';

import styles from './ListingFocusedCard.module.scss';

export interface ListingFocusedCardProps {
  listing: Listing;
  allDisclosures: Record<string, Disclosure>;
  isAllInPrice: boolean;
  isMLBEvent: boolean;
  isMobileVariant?: boolean;
  showListingSource?: boolean;
  bordered?: boolean;
  showDiscountInDollars: boolean;
  isWebExclusivesV3Experiment: boolean;
  allDeals: Record<string, Deal>;
}

// only show savings in dollars if the savings amount is greater than or equal
// to this value in whole dollars, otherwise continue showing percentage
const SHOW_DISCOUNT_IN_DOLLARS_MINIMUM = 10;

// TODO: how can we make this backend driven?
type TopValueDealType = 'super' | 'flash' | 'best';
export function isTopValueDeal(dealType: string): dealType is TopValueDealType {
  const topValueDealTypes: TopValueDealType[] = ['super', 'flash', 'best'];
  return topValueDealTypes.includes(dealType as TopValueDealType);
}

export const ListingFocusedCard = ({
  listing,
  allDisclosures,
  isAllInPrice,
  isMLBEvent,
  isMobileVariant = false,
  showListingSource = false,
  bordered = false,
  showDiscountInDollars,
  isWebExclusivesV3Experiment,
  allDeals,
}: ListingFocusedCardProps) => {
  const ticketPrice = isAllInPrice
    ? listing.getTotalPrice()
    : listing.getPrice();

  const isWidePriceLabel =
    listing.isDiscounted() && ticketPrice.toString().length > 3;

  function getValueTag() {
    if (listing.isDiscounted()) {
      const savingsAmount = listing.getSavingsAmount();

      const priceMarkdown =
        showDiscountInDollars &&
        savingsAmount >= SHOW_DISCOUNT_IN_DOLLARS_MINIMUM ? (
          <span>
            <span className={styles['dollar-sign']}>$</span>
            {savingsAmount} OFF
          </span>
        ) : (
          <>{listing.getDiscountPercent()}% OFF</>
        );

      const iconSize = isWebExclusivesV3Experiment ? 12 : 16;

      return (
        <Tag
          title={priceMarkdown}
          icon={<DiscountIcon width={iconSize} height={iconSize} />}
          size={isWebExclusivesV3Experiment ? 'small' : 'default'}
          bordered
        />
      );
    }

    if (isWebExclusivesV3Experiment && isTopValueDeal(listing.dealType)) {
      return (
        <Tag
          title={`Top ${DEAL_VALUE_PERCENTILE[listing.dealType]}% Value`}
          size="small"
          bordered
        />
      );
    }

    return null;
  }

  const deal = listing.dealType && allDeals[listing.dealType];

  const coloredBorderStyles = deal?.colorGradient
    ? {
        background: `linear-gradient(${deal.colorGradient.join(', ')}) border-box`,
        border: `1.5px solid transparent`,
      }
    : {};

  const finalPriceStyle: React.CSSProperties = deal?.colorGradient
    ? { color: deal.colorGradient[1] }
    : {};

  return (
    <div
      className={classNames(styles['card-container'], {
        [styles['card-container-mobile']]: isMobileVariant,
        [styles['bordered']]: bordered,
      })}
      style={coloredBorderStyles}
    >
      <Image
        className={styles.image}
        {...listing.getImageOptions()}
        lazyLoad={false}
      />

      <div className={styles['deal-badge']}>
        {deal && <DealTag deal={deal} />}
      </div>

      <div className={styles['content-column-left']}>
        {listing.hasZoneTicketDisclosure && <ZoneTag />}
        {isMLBEvent && <MLBBadge />}
        <h3 className={styles['section-name']}>{listing.sectionGroup}</h3>
        <div className={styles['seat-details-container']}>
          <span
            className={classNames(styles['seat-details'], {
              [styles['wide-price-label']]: isWidePriceLabel,
            })}
          >
            {listing.section}, Row {listing.row}
          </span>

          <Disclosures
            disclosures={listing.disclosures}
            allDisclosures={allDisclosures}
            onlyIcon
          />
        </div>
        {showListingSource && (
          <span
            className={classNames(styles['listing-source'], {
              [styles['wide-price-label']]: isWidePriceLabel,
            })}
          >
            Ticket Source: {listing.source}
          </span>
        )}
      </div>

      <div className={styles['content-column-right']}>
        {getValueTag()}

        {isAllInPrice && (
          <span className={styles['all-in-pricing']}>All-In</span>
        )}

        <div className={styles['price-info']}>
          {listing.isDiscounted() && (
            <TicketPrice
              className={styles['price-before-discount']}
              isPriceCrossedOut
              price={ticketPrice + listing.getSavingsAmount()}
            />
          )}
          <TicketPrice
            price={ticketPrice}
            className={classNames({
              [styles['final-price']]: deal?.colorGradient,
            })}
            style={finalPriceStyle}
          />
        </div>
      </div>

      <span className={styles['card-gradient']} />
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    showDiscountInDollars: selectIsPercentageVsDollarsExperiment(state),
    isWebExclusivesV3Experiment: selectIsWebExclusivesV3Experiment(state),
    allDeals: selectAllDeals(state),
  };
}

export default connect(mapStateToProps)(ListingFocusedCard);
