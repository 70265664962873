import { CATEGORY_GROUP_CONFIG } from 'modules/CategoryGroups/CategoryGroups.helpers';
import { getCategoryPrefix } from 'store/modules/categories/category.helpers';
import { urlDate } from 'utils/datetime';
import { formatUrl } from 'utils/format';

import { isValidEvent } from './resource.validators';

const eventPathnameSelectorByEventVenue = (event, venue) => {
  const { name, id, datetime_local, category } = event;
  const { city, state, name: venueName } = venue;

  if (
    !name ||
    !id ||
    !datetime_local ||
    !city ||
    !state ||
    !venueName ||
    !category
  ) {
    return '';
  }

  return (
    `/${formatUrl(getCategoryPrefix(category))}` +
    `/${formatUrl(`${name} tickets`)}` +
    `/${formatUrl(
      `${urlDate(datetime_local)} ${city} ${state} ${venueName}`
    )}/events/${id}`
  );
};

export const getPerformerPath = (performer) => {
  const { name, slug, team_id } = performer || {};
  if (!name || (!slug && !team_id)) {
    return '';
  }
  return `/${formatUrl(`${name} tickets`)}/performers/${slug || team_id}`;
};

export const getMetroPerformersPathByMetro = (
  { id, name } = {},
  tagId = ''
) => {
  if (!name || !id) return '';

  const formattedName = formatUrl(`${name} tickets`);
  const tagPath = tagId ? `/${tagId}` : '';

  return `/${formattedName}/metros/${id}${tagPath}`;
};

export const getPerformerPathByPerformer = (performer) =>
  getPerformerPath(performer);

export const getEventShortName = (event) => {
  const { category, performers } = event;

  if (category === CATEGORY_GROUP_CONFIG.SPORT.id) {
    const homeTeam = performers.find(
      (performer) => performer.home_team === true
    );
    const awayTeam = performers.find(
      (performer) => performer.away_team === true
    );

    return `${awayTeam.short_name} at ${homeTeam.short_name}`;
  }
  return performers[0].short_name;
};

export const getEventPath = (event) => {
  if (!event) {
    return '';
  }

  const {
    event_id: id,
    category,
    performerCategory,
    stats: { datetime_local },
    venue: { city, state, name: venueName },
  } = event;

  return eventPathnameSelectorByEventVenue(
    {
      name: getEventShortName(event),
      id,
      datetime_local,
      category: performerCategory || category,
    },
    {
      city,
      state,
      name: venueName,
    }
  );
};

export const getValidEventPathForPerformer = (performer) => {
  const validEvent = performer.schedule.some((event) => isValidEvent(event));
  if (validEvent) {
    return getEventPath(validEvent);
  }
  return null;
};
