import React from 'react';

import Image from 'components/Image/Image';

import styles from './SeatMapPinLarge.module.scss';

type SeatMapPinLargeProps = {
  imgProps: {
    alt?: string;
    seoPostfix?: string;
    seoPrefix?: string;
    src?: string;
  };
};

const SeatMapPinLarge = ({ imgProps }: SeatMapPinLargeProps) => {
  return (
    <div className={styles['pin-head']}>
      <div className={styles['pin-container']}>
        <Image className={styles['pin-image']} {...imgProps} />
      </div>
    </div>
  );
};

export default SeatMapPinLarge;
