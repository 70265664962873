import React, { useMemo } from 'react';

import colors from 'styles/colors.constants';

import 'external-svg-loader';

import styles from './ExternalIcon.module.scss';

interface ExternalIconProps {
  src: string;
  color?: string;
  /**
   * An array of colors to create a gradient fill for the icon. If provided, the
   * `color` prop will be ignored.
   */
  colorGradient?: string[];
  title?: string;
  width?: number;
  height?: number;
  testId?: string;
}

const ExternalIcon = ({
  src,
  color = colors.white,
  colorGradient,
  title = 'disclosure',
  width = 16,
  height = 16,
  testId,
}: ExternalIconProps) => {
  const { fillColor, gradientId } = useMemo(() => {
    if (colorGradient) {
      const gradientId = `svg-gradient-${colorGradient.join('-')}`;

      return {
        fillColor: `url(#${gradientId})`,
        gradientId,
      };
    }

    return {
      fillColor: color,
      gradientId: undefined,
    };
  }, [colorGradient, color]);

  return (
    <svg role="img" aria-label={title} width={width} height={height}>
      {colorGradient && (
        <defs>
          <linearGradient id={gradientId} gradientTransform="rotate(90)">
            {colorGradient.map((color, index) => {
              return (
                <stop
                  key={color}
                  offset={index / (colorGradient.length - 1)}
                  stopColor={color}
                />
              );
            })}
          </linearGradient>
        </defs>
      )}
      <svg
        data-src={src}
        data-testid={testId}
        className={styles['custom-icon-fill']}
        style={{ '--fill-color': fillColor } as React.CSSProperties}
      />
    </svg>
  );
};

export default ExternalIcon;
