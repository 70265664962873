import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ClickTracker } from 'analytics';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import TableViewCell from 'components/TableViewCell/TableViewCell';
import { selectIsHomepageRedesignV1Experiment } from 'experiments';
import { RootState } from 'store';

import styles from './MiniCard.module.scss';

interface MiniCardProps {
  title: string;
  subtitle: string;
  isSmall: boolean;
  isSearchBar: boolean;
  isActive?: boolean;
  primaryColor: string;
  emoji?: string;
  /**
   * Properties for the image on the mini card
   * @property {string} src - The source URL of the image
   * @property {string} alt - Descriptive alt text for accessibility.
   *                          Required for images that are not purely decorative.
   */
  imageProps: { src: string; alt?: string };
  to: string;
  onClick?: VoidFunction;
  clickTracker: ClickTracker | object;
}

const MiniCard = ({
  title,
  subtitle,
  isSmall,
  isSearchBar = false,
  isActive = false,
  imageProps,
  primaryColor,
  emoji,
  to,
  onClick,
  clickTracker,
}: MiniCardProps) => {
  const isSearchRedesign =
    useSelector((state: RootState) =>
      selectIsHomepageRedesignV1Experiment(state)
    ) && isSearchBar;
  const thumbnail = imageProps.src && (
    <div
      className={classNames([
        styles.thumbnail,
        { [styles['home-redesign-thumbnail']]: isSearchRedesign },
      ])}
    >
      {isSearchRedesign && emoji ? (
        <div
          className={styles['icon-container']}
          style={{ backgroundColor: primaryColor }}
          data-testid="performer-icon-container"
        >
          <span className={styles.emoji} aria-hidden="true">
            {emoji}
          </span>
        </div>
      ) : (
        <Image src={imageProps.src} lazyLoad={false} alt={imageProps.alt} />
      )}
    </div>
  );

  return (
    <Link to={to} onClick={onClick} clickTracker={clickTracker}>
      <TableViewCell
        thumbnail={thumbnail}
        title={title}
        subtitle={subtitle}
        isSearchBar={isSearchBar}
        isSmall={isSmall}
        isActive={isActive}
      />
    </Link>
  );
};

export default MiniCard;
