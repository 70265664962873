import { Review } from 'types';

export const DESKTOP_SEARCH_HERO_BACKGROUND_URL =
  'https://s3.us-west-2.amazonaws.com/assets.gametime.co/discover/discover.jpg';

export const MOBILE_SEARCH_HERO_BACKGROUND_URL =
  'https://s3.us-west-2.amazonaws.com/assets.gametime.co/discover/discover-mobile.jpg';

export const REVIEWS: Review[] = [
  {
    id: 'static-review-1',
    reviewer_name: 'n/a',
    date: '10/7/24',
    title: 'First time buying NFL tickets',
    stars: 5,
    review:
      'I compared multiple sites prior to finally finding a reasonable price through game time to take my boyfriend to his first 49er game for our anniversary. I am so excited! Thank you Game Time for my first experience purchasing NFL tickets!',
  },
  {
    id: 'static-review-2',
    reviewer_name: 'n/a',
    date: '9/2/24',
    stars: 5,
    title: 'Great Prices',
    review:
      'This app is very easy to use and I love the way that you can click "all in" and get a total of what your ticket price will be without surprises at the end with a bunch of fees. I  know exactly what my price will be so it\'s easier to make a selection',
  },
  {
    id: 'static-review-3',
    reviewer_name: 'n/a',
    date: '4/8/24',
    stars: 5,
    title: 'Best App for Tickets',
    review:
      "I've used gametime for all my ticket needs. Was able to get VERY close courtside seats for my moms birthday and close seats for comedy shows and baseball games. Working with affirm to be able to make payments on tickets helps a lot!",
  },
];

export const categories = [
  {
    name: 'NFL Football',
    imagePath: 'https://assets.gametime.co/discover/categories/nfl.jpg',
    url: '/c/nfl-football',
  },
  {
    name: 'NBA Basketball',
    imagePath: 'https://assets.gametime.co/discover/categories/nba.jpg',
    url: '/c/nba-basketball',
  },
  {
    name: 'MLB Baseball',
    imagePath: 'https://assets.gametime.co/discover/categories/mlb.jpg',
    url: '/c/mlb-baseball',
  },
  {
    name: 'NHL Hockey',
    imagePath: 'https://assets.gametime.co/discover/categories/nhl.jpg',
    url: '/c/nhl-hockey',
  },
  {
    name: 'Concerts',
    imagePath: 'https://assets.gametime.co/discover/categories/music.jpg',
    url: '/c/concert',
  },
  {
    name: 'NCAA Football',
    imagePath:
      'https://assets.gametime.co/discover/categories/ncaa-football.jpg',
    url: '/c/college-football',
  },
  {
    name: 'Comedy',
    imagePath: 'https://assets.gametime.co/discover/categories/comedy.jpg',
    url: '/c/comedy',
  },
  {
    name: 'NCAA Basketball',
    imagePath:
      'https://assets.gametime.co/discover/categories/ncaa-basketball.jpg',
    url: '/c/college-basketball',
  },
] as const;
