import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Click, ClickTracker, withAnalyticsContext } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import HeaderPromoCategories from 'components/HeaderPromoCategories/HeaderPromoCategories';

import { CATEGORIES_DROPDOWN } from '../HeaderDropdown/components/CategoriesDropdown/constants';

import styles from './CategoriesButtonList.module.scss';

@withAnalyticsContext
@withClickContext()
class CategoriesButtonList extends Component {
  handleClick = (event) => {
    const { clickContext, isHomepage } = this.props;
    const category = event.target.getAttribute('data-category');

    let section = category?.toLowerCase();
    if (section === 'theater') {
      section = 'comedy_and_theater';
    }

    const tracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.HEADER())
      .sourcePageType(
        isHomepage
          ? Click.SOURCE_PAGE_TYPES.HOMEPAGE()
          : clickContext.sourcePageType
      )
      .payload({ section });
    this.props.analyticsContext.track(new Click(tracker.json()));

    this.props.onClick(category);
  };

  render() {
    const { selected } = this.props;

    return (
      <div className={styles['category-selectors']}>
        <button
          className={classNames(styles['category-selector-button'], {
            [styles.selected]: selected === CATEGORIES_DROPDOWN.SPORTS,
          })}
          data-category={CATEGORIES_DROPDOWN.SPORTS}
          onClick={this.handleClick}
        >
          SPORTS
        </button>
        <button
          className={classNames(styles['category-selector-button'], {
            [styles.selected]: selected === CATEGORIES_DROPDOWN.MUSIC,
          })}
          data-category={CATEGORIES_DROPDOWN.MUSIC}
          onClick={this.handleClick}
        >
          MUSIC
        </button>
        <button
          className={classNames(styles['category-selector-button'], {
            [styles.selected]: selected === CATEGORIES_DROPDOWN.THEATER,
          })}
          data-category={CATEGORIES_DROPDOWN.THEATER}
          onClick={this.handleClick}
        >
          SHOWS
        </button>
        <HeaderPromoCategories />
      </div>
    );
  }
}

CategoriesButtonList.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func,
  promoLink: PropTypes.shape({
    url: PropTypes.string,
    clickTracker: PropTypes.object,
    title: PropTypes.string,
  }),
  analyticsContext: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
  clickContext: PropTypes.object,
  isHomepage: PropTypes.bool,
};

export default CategoriesButtonList;
