import React from 'react';

import styles from './BulletList.module.scss';

interface BulletListProps {
  listItems: React.ReactNode[];
}

function BulletList({ listItems }: BulletListProps) {
  return (
    <div className={styles['bullet-list']}>
      {listItems.map((item) => {
        if (!item) return null;
        return (
          <span key={item.toString()} className={styles['bulleted-item']}>
            {item}
          </span>
        );
      })}
    </div>
  );
}

export default BulletList;
