import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { getCategoryPath } from 'store/modules/categories/category.helpers';
import { fetchAllPerformers } from 'store/modules/data/Performers/actions';
import { selectAllPerformersInCategories } from 'store/modules/data/Performers/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { getMetroPerformersPathByMetro } from 'store/modules/resources/resource.paths';
import {
  selectAllMetros,
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import colors from 'styles/colors.constants';

import SitemapURLsList from './components/SitemapURLsList';

import styles from './Sitemap.module.scss';

const MAX_NUMBER_OF_ELEMENTS_PER_SECTION = 40;
class Sitemap extends Component {
  static propTypes = {
    categories: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, performers: PropTypes.array })
    ),
    metros: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  render() {
    const { categories, metros } = this.props;

    return (
      <ContainerTemplate
        canShowGoogleAdbanner
        header={
          <MinimalHeader search showAccount showHamburger showCategories />
        }
        footer={<GTFooter />}
      >
        <HeadTitle title="Sitemap" />
        <div className={styles.container}>
          <h1 className={styles.title}>Sitemap</h1>

          {categories.map((category) => {
            if (!category.performers.length) return null;

            const performerItems = category.performers
              .slice(0, MAX_NUMBER_OF_ELEMENTS_PER_SECTION)
              .map((performer) => ({
                name: performer.name,
                path: performer.getPath(),
              }));

            performerItems.push({
              name: `More ${category.name.toUpperCase()} tickets`,
              path: `/html-sitemap/performers/${category.name}`,
              color: colors.white,
            });

            // Add matchups link for sports categories with more than 1 performer
            if (
              category.performers[0].isSportsCategoryGroup &&
              performerItems.length
            ) {
              performerItems.push({
                name: `${category.name.toUpperCase()} Matchups`,
                path: `/html-sitemap/${category.name}-matchups`,
                color: colors.brightPink,
              });
            }

            return (
              <React.Fragment key={category.name}>
                <h2 className={styles.subtitle}>
                  <a href={getCategoryPath(category.name)}>{category.name}</a>
                </h2>
                <SitemapURLsList urlItems={performerItems} />
              </React.Fragment>
            );
          })}

          {metros.length > 0 ? (
            <>
              <h2 className={styles.subtitle}>
                Major Cities and Metropolitan Areas
              </h2>
              <SitemapURLsList
                urlItems={metros
                  .slice(0, MAX_NUMBER_OF_ELEMENTS_PER_SECTION)
                  .map((metro) => ({
                    name: `Events in ${metro.name}`,
                    path: getMetroPerformersPathByMetro(metro),
                  }))}
              />
            </>
          ) : null}
        </div>
      </ContainerTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: selectAllPerformersInCategories(state),
  metros: selectAllMetros(state),
});

const SitemapWrapper = connect(mapStateToProps)(Sitemap);

const loader =
  ({ store }) =>
  async () => {
    await store.dispatch(fetchMetros());
    const state = store.getState();
    const metro = selectUserMetro(state) || selectClosestMetro(state);
    await store.dispatch(fetchAllPerformers(metro));

    return null;
  };

SitemapWrapper.loader = loader;

export default SitemapWrapper;
