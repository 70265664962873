import React, { useMemo } from 'react';
import ExternalIcon from 'ui/ExternalIcon/ExternalIcon';
import Tag from 'ui/Tag/Tag';

import { Deal } from 'models';
import colors from 'styles/colors.constants';

export default function DealTag({ deal }: { deal: Deal }) {
  const background = useMemo(() => {
    if (deal.colorGradient) {
      return `linear-gradient(${deal.colorGradient.join(', ')})`;
    }
    return deal.color;
  }, [deal]);

  return (
    <Tag
      title={deal.title}
      background={background}
      color={colors.black}
      icon={
        deal.iconURL && (
          <ExternalIcon
            src={deal.iconURL}
            title={deal.title}
            color={colors.black}
          />
        )
      }
    />
  );
}
