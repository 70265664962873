import React from 'react';
import classNames from 'classnames';

import { Click, ClickTracker } from 'analytics';
import Link from 'components/Link/Link';
import colors from 'styles/colors.constants';

import styles from './CMSContentCard.module.scss';

const getIsLightColor = (hexColor: string) => {
  // Remove the # if present
  hexColor = hexColor.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the relative luminance
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  // Return true if light, false if dark
  return luminance > 127.5;
};
type CardFields = {
  background_color: string;
  title: string;
  subtitle: string;
  button_cta_text?: string;
  button_link: string;
  tag_text: string;
  image: string;
};

type CMSContentCardProps = {
  fields: CardFields;
  isMobile?: boolean;
  clickTracker: ClickTracker;
  isSingleton?: boolean;
};

const CMSContentCard: React.FC<CMSContentCardProps> = ({
  fields,
  isMobile = false,
  clickTracker,
  isSingleton,
}) => {
  const {
    title,
    subtitle,
    background_color: backgroundColor,
    button_cta_text,
    button_link: buttonLink,
    tag_text: tagText,
    image,
  } = fields;
  // the theme of the button background is the opposite of the card background
  const isLightColor = getIsLightColor(backgroundColor || colors.gray600);
  const buttonCtaText = button_cta_text || 'Get Tickets';

  function getTargetPageType() {
    if (fields.button_link.includes('performers'))
      return Click.TARGET_PAGE_TYPES.PERFORMER();
    if (fields.button_link.includes('event'))
      return Click.TARGET_PAGE_TYPES.EVENT();
    return Click.TARGET_PAGE_TYPES.CATEGORY();
  }

  return (
    <Link
      to={buttonLink}
      key={buttonLink}
      className={classNames(styles['cms-content-card'], {
        [styles['dark-text']]: isLightColor,
        [styles['light-text']]: !isLightColor,
      })}
      style={{
        backgroundColor: backgroundColor,
      }}
      clickTracker={clickTracker.targetPageType(getTargetPageType())}
    >
      <div
        className={classNames(styles.details, {
          [styles['singleton']]: isSingleton,
        })}
      >
        <div className={styles.tag}>
          <span className={styles['tag-text']}>{tagText}</span>
        </div>
        <div
          className={classNames(styles['title-container'], {
            [styles['center']]: isSingleton,
          })}
        >
          <h2 className={styles.title}>{title}</h2>
          <h3 className={styles.subtitle}>{subtitle}</h3>
        </div>

        {!isMobile && (
          <div
            style={{ backgroundColor }}
            className={classNames(styles['link-button'], {
              [styles.dark]: isLightColor,
            })}
          >
            <span className={styles['cta-text']}>{buttonCtaText}</span>
          </div>
        )}
      </div>
      <div className={styles['img-zoom-container']}>
        <img className={styles.img} src={image} alt={title} />
      </div>
    </Link>
  );
};

export default CMSContentCard;
