import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { FullEvent, Listing } from 'models';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';
import ListingPromoBanner from 'pages/Event/components/ListingPromoBanner/ListingPromoBanner';
import { EVENTBAR_VIEWS } from 'pages/Event/Event.constants';

import CarouselListing from '../CarouselListing';
import ExpiredEvent from '../ExpiredEvent';
import Listings from '../Listings';

import styles from './EventBar.module.scss';

const EventBar = ({
  onListingHover,
  onListingsTouchStart,
  onListingsTouchMove,
  isCheckout,
  children,
  schedule,
  relatedPerformers,
  fullEvent,
  listingsInDisplayGroups,
  sidebarView,
  allDisclosures,
  allDeals,
  zoomLevel,
  isHarmonyPlusOverlay,
  isWebExclusivesV3Experiment,
}) => {
  const isDesktop = useMediaQuery(device.up.lg);
  const renderListings = () => {
    const eventBanner = fullEvent.event.banner;
    const eventId = fullEvent.id;

    const props = {
      fullEvent: fullEvent,
      allDeals: allDeals,
      zoomLevel: zoomLevel,
      allDisclosures: allDisclosures,
      onListingHover: onListingHover,
    };

    return (
      <>
        <ListingPromoBanner
          headline={eventBanner.headline}
          subtitle={eventBanner.subtitle}
          emoji={eventBanner.emoji}
          eventId={eventId}
        />

        {listingsInDisplayGroups.map((displayGroup) => {
          if (displayGroup.listings.length && displayGroup.type) {
            if (displayGroup.type === 'carousel') {
              return (
                <CarouselListing
                  {...props}
                  key={`${displayGroup.title}-${displayGroup.deal}-${displayGroup.type}`}
                  isWebExclusivesV3Experiment={isWebExclusivesV3Experiment}
                  listings={displayGroup.listings}
                  dealType={displayGroup.deal}
                />
              );
            }

            if (displayGroup.type === 'deals_list') {
              return (
                <React.Fragment
                  key={`${displayGroup.title}-${displayGroup.deal}-${displayGroup.type}`}
                >
                  <h3 className={styles['heading']}>More Great Deals</h3>
                  <div className={styles['deals-section-listings']}>
                    <div className={styles['listings']}>
                      {displayGroup.listings.map((listing, index) => (
                        <ListingCard
                          key={listing.id}
                          listingIndex={index}
                          fullEvent={fullEvent}
                          listing={listing}
                          onHover={onListingHover}
                          zoomLevel={zoomLevel}
                          allDisclosures={allDisclosures}
                        />
                      ))}
                    </div>
                  </div>
                  {/* display heading for next list only if deals_list is present */}
                  <h3 className={styles['heading']}>All Tickets</h3>
                </React.Fragment>
              );
            }

            if (displayGroup.type === 'list') {
              return (
                <Listings
                  {...props}
                  key={`${displayGroup.title}-${displayGroup.deal}-${displayGroup.type}`}
                  listings={displayGroup.listings}
                />
              );
            }
          }

          return null;
        })}
      </>
    );
  };

  const renderSidebarContent = () => {
    if (isHarmonyPlusOverlay && isDesktop) {
      return (
        <>
          {children}
          {renderListings()}
        </>
      );
    }

    switch (sidebarView) {
      case EVENTBAR_VIEWS.LISTING_PURCHASE:
        return <>{children}</>;
      case EVENTBAR_VIEWS.EXPIRED:
        return (
          <ExpiredEvent
            schedule={schedule}
            relatedPerformers={relatedPerformers}
          />
        );
      case EVENTBAR_VIEWS.LISTINGS:
      default:
        return renderListings();
    }
  };

  return (
    <div
      onTouchStart={onListingsTouchStart}
      onTouchMove={onListingsTouchMove}
      className={classNames(styles['event-bar'], {
        [styles.checkout]: isCheckout,
        [styles['is-exclusives-v3']]: isWebExclusivesV3Experiment,
      })}
    >
      {renderSidebarContent()}
    </div>
  );
};

EventBar.propTypes = {
  onListingHover: PropTypes.func,
  onListingsTouchStart: PropTypes.func,
  onListingsTouchMove: PropTypes.func,
  isCheckout: PropTypes.bool.isRequired,
  children: PropTypes.node,
  schedule: PropTypes.array,
  relatedPerformers: PropTypes.array,
  fullEvent: PropTypes.instanceOf(FullEvent),
  listingsInDisplayGroups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      deal: PropTypes.string,
      listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)).isRequired,
      type: PropTypes.oneOf(['carousel', 'list', 'deals_list']).isRequired,
    })
  ).isRequired,
  sidebarView: PropTypes.oneOf(Object.values(EVENTBAR_VIEWS)),
  allDisclosures: PropTypes.object,
  allDeals: PropTypes.object,
  zoomLevel: PropTypes.number,
  isHarmonyPlusOverlay: PropTypes.bool,
  isWebExclusivesV3Experiment: PropTypes.bool.isRequired,
};

export default EventBar;
