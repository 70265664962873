import React from 'react';
import { useLocation } from 'react-router-dom';

import config from 'config/config';
import { getSourcePageType } from 'utils/appLinkUtils';

type AppLinkProps = {
  children: React.ReactNode;
  onMobileDownloadAppClick: () => void;
};

const AppLink = ({ children, onMobileDownloadAppClick }: AppLinkProps) => {
  const location = useLocation();
  const sourcePageType = getSourcePageType(location.pathname);
  const link = config.DEEP_LINKS[sourcePageType];

  return (
    <a onClick={onMobileDownloadAppClick} href={link}>
      {children}
    </a>
  );
};

export default AppLink;
