import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'hoc/withRouter';
import PropTypes from 'prop-types';

import { Click, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import SimpleHeroSection from 'components/SimpleHeroSection/SimpleHeroSection';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { selectAllMetros } from 'store/modules/resources/resource.selectors';
import { updateUserPreference as updateUserPreferenceDispatch } from 'store/modules/userPreference/userPreference';

import Sections from './Sections';

import styles from './MainMetro.module.scss';

@TrackPageView(() => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.MAIN_METRO_PAGE(),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.MAIN_METRO_PAGE(),
}))
class MainMetro extends Component {
  static propTypes = {
    allMetros: PropTypes.array.isRequired,
    updateUserPreference: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  };

  renderMeta() {
    const desc = `Looking for events near you? Get the cheapest tickets to the best events in sports, music,
      and theater in 70+ cities. 100% Verified Tickets. Best Price Guarantee!`;
    return (
      <div>
        <HeadTitle title="Events Near Me" />
        <HeadDescription description={desc} />
      </div>
    );
  }

  renderHeroSection() {
    return (
      <SimpleHeroSection
        title="Events Near Me"
        breadcrumbProps={{
          breadcrumbs: [HOMEPAGE_BREADCRUMB_CONFIG],
        }}
        backgroundImage="https://assets.gametime.co/web/skyline.png"
      />
    );
  }

  renderBody() {
    const { allMetros, router } = this.props;
    return (
      <div className={styles['main-metro-container']}>
        <Sections category="Sports" allMetros={allMetros} router={router} />
        <Sections
          category="Music"
          allMetros={allMetros}
          updateUserPreference={this.props.updateUserPreference}
          router={router}
        />
        <Sections
          category="Shows"
          allMetros={allMetros}
          updateUserPreference={this.props.updateUserPreference}
          router={router}
        />
      </div>
    );
  }

  render() {
    return (
      <ContainerTemplate
        canShowGoogleAdbanner
        header={
          <MinimalHeader search showCategories showAccount showHamburger />
        }
        footer={<GTFooter />}
      >
        {this.renderMeta()}
        {this.renderHeroSection()}
        {this.renderBody()}
      </ContainerTemplate>
    );
  }
}

const mapDispatchToProps = {
  updateUserPreference: updateUserPreferenceDispatch,
};

const mapStateToProps = (state) => {
  return {
    allMetros: selectAllMetros(state),
  };
};

const loader =
  ({ store: { dispatch } }) =>
  async () => {
    await dispatch(fetchMetros());
    return null;
  };

const MainMetroWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainMetro)
);

MainMetroWrapper.loader = loader;

export default MainMetroWrapper;
