import React from 'react';

import { CircleInfoIcon } from 'icons';
import colors from 'styles/colors.constants';

import styles from './MLBCollectionAlert.module.scss';

const MLBCollectionAlert = () => {
  return (
    <div className={styles['mlb-account-alert']}>
      <div className={styles['mlb-account-alert-icon']}>
        <CircleInfoIcon fill={colors.softYellow} aria-hidden="true" />
      </div>
      <span className={styles['mlb-info-collection-text']}>
        If you already have an MLB Ballpark account, please use that email
        address.
      </span>
    </div>
  );
};

export default MLBCollectionAlert;
