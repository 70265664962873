import React, { useRef, useState } from 'react';

import { device, useMediaQuery } from 'hooks/useMediaQuery';

import { SEAT_MAP_IMAGE_WIDTH } from '../../SeatMap.constants';
import { useTransformContext } from '../../SeatMapInteraction';

import styles from '../SeatMap.module.scss';

type SeatMapImageProps = {
  scale?: number;
  onLoad: (imgLoadTime: number) => void;
  onError: (error: boolean) => void;
  mapUrl: string;
  noZoomOnLoad?: boolean;
};

const SeatMapImage = React.forwardRef<HTMLImageElement, SeatMapImageProps>(
  ({ scale = 1, onLoad, mapUrl, onError, noZoomOnLoad = false }, ref) => {
    const [loading, setLoading] = useState(true);

    const isMobile = useMediaQuery(device.down.md);
    const maxScale = Math.ceil(scale);
    const minMapSize = SEAT_MAP_IMAGE_WIDTH / 4;
    const imgSize =
      isMobile || maxScale !== 1
        ? maxScale * minMapSize
        : maxScale * minMapSize * 2;

    const srcUrl = new URL(mapUrl);
    srcUrl.searchParams.set('width', imgSize.toString());
    srcUrl.searchParams.set('auto', 'webp');
    if (imgSize >= 3200) {
      srcUrl.searchParams.set('disable', 'upscale');
    }
    const src = srcUrl.toString();

    const isInitialLoad = useRef(true);
    const imageLoadTimeRef = useRef(performance.now());
    const { zoomIn } = useTransformContext();
    const handleLoad = () => {
      if (isInitialLoad.current) {
        // calculate the time difference in milliseconds
        const imgLoadTime = Math.round(
          performance.now() - imageLoadTimeRef.current
        );

        setLoading(false);
        onLoad(imgLoadTime);

        if (!noZoomOnLoad) {
          setTimeout(() => zoomIn(0.4), 300);
        }

        isInitialLoad.current = false;
      }
    };

    const handleError = () => {
      onError(true);
    };

    if (!mapUrl && loading) return null;

    return (
      <img
        ref={ref}
        alt="Seat Map"
        draggable={false}
        className={styles.seatMapImage}
        onLoad={handleLoad}
        onError={handleError}
        src={src}
      />
    );
  }
);

export default SeatMapImage;
