module.exports = {
  domain: 'web-testing.gametime.co',
  API_URL: 'https://testing.gametime.co',
  MOBILE_API_URL: 'https://mobile-testing.gametime.co',
  appleAppAssociationConstants: {
    universalLinkSuffix: '.beta',
  },
  PUSHER_KEY: '6020149ea8c2fbe74232',
  BRAZE: {
    API_KEY: '5d58f8d7-3186-4377-b3de-2a8d8c0e00b9',
    BASE_URL: 'sdk.iad-05.braze.com',
  },
  DEEP_LINKS: {
    homepage: 'https://gametime-beta.go.link/crZn8',
    metro: 'https://gametime-beta.go.link/8r6kh',
    picks: 'https://gametime-beta.go.link/9ExK6',
    order_confirmation: 'https://gametime-beta.go.link/7jjbG',
    why_gametime: 'https://gametime-beta.go.link/bMhDP',
  },
};
