import React from 'react';

import PreloadedImage from 'components/Head/PreloadedImage';
import SearchBox from 'components/Search/SearchBox/SearchBox';
import MetroPicker from 'components/SelectorModals/MetroPicker/MetroPicker';
import { device, useMediaQuery } from 'hooks';
import { SEARCH_HERO_BACKGROUND_URL } from 'pages/constants';
import { Metro } from 'types';

import styles from './SearchHero.module.scss';

interface SearchHeroProps {
  currentMetro: Metro;
  handleHeroSearchboxFocus?: () => void;
}

const SearchHero = ({
  currentMetro,
  handleHeroSearchboxFocus,
}: SearchHeroProps) => {
  const isMobile = useMediaQuery(device.down.md);

  return (
    <>
      <PreloadedImage src={SEARCH_HERO_BACKGROUND_URL} />
      <div
        style={{
          backgroundImage: `url(${SEARCH_HERO_BACKGROUND_URL})`,
        }}
        className={styles['search-hero-container']}
      >
        <div className={styles['search-hero-header']}>
          <span className={styles['search-hero-title']}>
            Find your next great experience.
          </span>
          <span className={styles['search-hero-subtitle']}>
            Sports · Music · Shows
          </span>
        </div>
        <div className={styles['search-hero-box']}>
          <SearchBox
            onFocus={isMobile ? handleHeroSearchboxFocus : undefined}
            isSearchHero
            isHomepage
          />
        </div>
        {isMobile && <MetroPicker currentMetro={currentMetro} />}
      </div>
    </>
  );
};

export default SearchHero;
