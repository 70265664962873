import React from 'react';

import colorsConstants from 'styles/colors.constants';

import styles from '../../Sitemap.module.scss';

export type SitemapURLItem = {
  name: string;
  path: string;
  color?: string;
};

type SitemapURLsListProps = {
  urlItems: SitemapURLItem[];
};

const DEFAULT_COLOR = colorsConstants.gray400;

const SitemapURLsList = ({ urlItems }: SitemapURLsListProps) => (
  <nav aria-label="Sitemap URLs Navigation">
    <ul className={styles['urls-container']} role="list">
      {urlItems.map((item) => (
        <li key={item.name}>
          <a
            className={styles['url-link']}
            href={item.path}
            style={{ color: item.color || DEFAULT_COLOR }}
          >
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  </nav>
);

export default SitemapURLsList;
