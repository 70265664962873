import { createSelector } from 'reselect';

import Collection from 'models/Collection';
import { COLLECTION_VIEWS } from 'pages/Collection/constants';
import { selectCurrentMetro } from 'store/modules/resources/resource.selectors';

import { getFilterKey } from './filterReducer';

// This collection will be the top hero carousel collection.
const COLLECTION_TYPES = {
  POPULAR: 'popular',
  FEATURED: 'featured',
};

const SUPPORTED_COLLECTION_RESOURCE_TYPES = ['events'];

const COLLECTIONS_SORT_BY_SLUG = {
  superbowl_lix: 1,
  popular: 2,
  popular_marketshare: 2,
  promoted: 3,
  under_50: 4,
  this_weekend: 5,
  web_music: 6,
  web_sport: 7,
  web_theater: 8,
};

const _selectCollections = (state) => state.data.collections;

const _selectCollectionsFilters = (state) => state.dataFilters.collections;

const _selectCollectionsIdsByFilters = (state, filters) => {
  const filterKey = getFilterKey(filters);
  return _selectCollectionsFilters(state)[filterKey];
};
const _selectCollectionViewByFilters = (_, filters) => filters.view;

export const selectCollectionsByFilters = createSelector(
  _selectCollections,
  _selectCollectionsIdsByFilters,
  _selectCollectionViewByFilters,
  (collectionObjects, collectionIds, view) => {
    if (!collectionIds) {
      return [];
    }
    return collectionIds.map(
      (collectionId) =>
        new Collection(
          collectionObjects[collectionId],
          typeof view === 'string' ? view : ''
        )
    );
  }
);

export const selectPopularCollectionByMetro = createSelector(
  selectCurrentMetro,
  _selectCollections,
  _selectCollectionsFilters,
  (metro, collections, filters) => {
    if (!metro?.id) return null;
    const ids = filters[`${metro.id}+view_web_discover`];
    if (!ids) {
      return null;
    }
    const popular = ids.find((id) => collections[id].slug === 'popular');
    const collection = collections[popular];

    return collection
      ? new Collection(collection, COLLECTION_VIEWS.WEB_DISCOVER)
      : null;
  }
);

/* using the web_discover view for now so the see all button works. If this is adopted,
 * the view should be updated and the correct redirect or page should be created
 */
// note: this helps with memoization, BUT a more elegant solution is needed.
const emptyArrayPerformerPageCollection = [];
export const selectPerformerPageCollections = (state, filterKey) => {
  const collectionIds = state.dataFilters.collections[filterKey];
  if (!collectionIds) {
    return emptyArrayPerformerPageCollection;
  }
  const collections = collectionIds.map(
    (collectionId) =>
      new Collection(
        state.data.collections[collectionId],
        COLLECTION_VIEWS.WEB_DISCOVER
      )
  );
  return collections;
};
/** IMPORTANT this is dragging our homepage/metropage performance
 * below I removed returning collections inside a new object everytime and combined the categoryId logic,
 * but this still needs refactored/optimized.
 */
export const metroPageCollectionsSelector = (state, filterKeys) => {
  const { metro, categoryGroupId = '', view = '' } = filterKeys;
  const metroCollections = selectCollectionsByFilters(state, { metro, view });

  // filter the supported collections, set a default featured collection, and create models
  let collections = metroCollections
    .filter((c) => {
      if (c.slug === COLLECTION_TYPES.FEATURED) {
        c.setUISectionIndex(0);
        return false;
      }

      return SUPPORTED_COLLECTION_RESOURCE_TYPES.includes(c.resource);
    }) // filter out unknown collections
    .filter((c) => COLLECTIONS_SORT_BY_SLUG[c.slug])
    .map((c, index) => {
      c.setUISectionIndex(index + 1);
      return c;
    })
    .sort((a, b) => {
      if (COLLECTIONS_SORT_BY_SLUG[a.slug] < COLLECTIONS_SORT_BY_SLUG[b.slug])
        return -1;
      if (COLLECTIONS_SORT_BY_SLUG[a.slug] > COLLECTIONS_SORT_BY_SLUG[b.slug])
        return 1;
      return 0;
    });

  // if categoryGroupId is defined, filter and map the collections that have at least one event in that category
  if (categoryGroupId) {
    collections = collections
      .filter((c) => !!c.eventsInCategoryCount(categoryGroupId))
      .map((c) => c.setEventsByCategory(categoryGroupId));
  }

  return collections;
};

function generateHeroEvents(currentMetroCollections) {
  const popularCollection = currentMetroCollections.find(
    (c) => c.slug === COLLECTION_TYPES.POPULAR
  );
  const featuredCollection = currentMetroCollections.find(
    (c) => c.slug === COLLECTION_TYPES.FEATURED
  );
  const popularEvents =
    (popularCollection && popularCollection.eventsList) || [];
  const topPick = featuredCollection && featuredCollection.eventsList[0];
  const heroEvents = topPick ? [topPick] : [];

  for (let i = 0; i < popularEvents.length; i++) {
    if (heroEvents.length === 3) {
      break;
    }
    const e = popularEvents[i];
    if (!topPick || e.id !== topPick.id) {
      heroEvents.push(e);
    }
  }

  return heroEvents;
}

export const selectHeroCarouselEvents = (state, filterKeys) => {
  const currentMetroCollections = selectCollectionsByFilters(state, filterKeys);

  if (currentMetroCollections && currentMetroCollections.length) {
    return generateHeroEvents(currentMetroCollections);
  }

  return [];
};
