import { LISTING } from './listing.actionTypes';

const initialState = {
  isUpdatingMapHarmony: false,
  listMapHarmonyToggleIsOn: false,
  hoveredListingId: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LISTING.UPDATE_MAP_HARMONY:
      return {
        ...state,
        isUpdatingMapHarmony: action.updating,
      };
    case LISTING.UPDATE_LIST_MAP_HARMONY_TOGGLE:
      return {
        ...state,
        listMapHarmonyToggleIsOn: action.toggleIsOn,
        isUpdatingMapHarmony: true,
      };
    case LISTING.SET_HOVERED_LISTING_ID:
      return {
        ...state,
        hoveredListingId: action.hoveredListingId,
      };
    default:
      return state;
  }
}
