import React from 'react';

import styles from './PriceGuaranteeBlock.module.scss';

function PriceGuaranteeBlock() {
  return (
    <div
      className={styles.content}
      role="region"
      aria-labelledby="price-guarantee-header"
    >
      <h3 id="price-guarantee-header" className={styles.title}>
        Gametime Best Price Guarantee
      </h3>
      <p className={styles.message}>
        Best price or we'll send you 110% of the difference.
      </p>
    </div>
  );
}

export default PriceGuaranteeBlock;
