import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';
import AppLink from 'ui/AppLink/AppLink';
import IconButton from 'ui/IconButton';

import {
  Click,
  ClickTracker,
  PAYLOAD,
  StaticPageClickTracker,
  useAnalyticsContext,
  View,
} from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import iosStoreBadge from 'assets/download-on-the-app-store-badge.svg';
import blackLogoIcon from 'assets/gametime-app-icon.png';
import playStoreBadge from 'assets/get-it-on-google-play-badge.svg';
import GTUniversalModal from 'components/GTUniversalModal/GTUniversalModal';
import UserPhoneForm from 'components/UserPhoneForm/UserPhoneForm';
import config from 'config/config';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import CloseLineIcon from 'icons/CloseLineIcon';
import { useAppDispatch } from 'store';
import { sendAppDownloadSMS } from 'store/modules/user/actions';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import { getSourcePageType } from 'utils/appLinkUtils';
import { denormalizePhoneNumber } from 'utils/phone';

import styles from './GetAppModal.module.scss';

const SCREENS = {
  FORM_SCREEN: 'form_screen',
  SUCCESS_SCREEN: 'success_screen',
};

const GetAppModal = ({ show = false, onHide }) => {
  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const [currentScreen, setCurrentScreen] = useState(SCREENS.FORM_SCREEN);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery(device.down.md);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleHide = () => {
    setCurrentScreen(SCREENS.FORM_SCREEN);
    setError(null);
    onHide();
  };

  const handleSubmit = (form) => {
    const { phone } = form;
    const tracker = new ClickTracker();
    const sourcePageType = getSourcePageType(location.pathname);
    const link = config.DEEP_LINKS[sourcePageType];

    tracker.payload({
      [PAYLOAD.ACTION]: 'send_sms',
      link_url: link,
      [PAYLOAD.PAGE_TYPE]: View.PAGE_TYPES.INSTALL(),
      [PAYLOAD.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.INSTALL(),
    });
    const trackProperties = tracker.json();
    analytics?.track(new Click(_merge({}, clickContext, trackProperties)));

    dispatch(updateUserPreference({ phone }));
    dispatch(sendAppDownloadSMS(denormalizePhoneNumber(phone), link))
      .then(() => {
        setCurrentScreen(SCREENS.SUCCESS_SCREEN);
      })
      .catch(() => {
        setError('Please try again later.');
      });
  };

  const handleDownloadAppClick = (pageName) => {
    const tracker = new StaticPageClickTracker(pageName).interaction({
      interaction: 'get_app_modal',
    });

    analytics.track(new Click(_merge({}, clickContext, tracker.json())));
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case SCREENS.SUCCESS_SCREEN:
        return (
          <div className={styles.modal}>
            <IconButton
              className={styles.close}
              icon={<CloseLineIcon />}
              onClick={onHide}
            />
            <span className={styles.tada} role="img" aria-hidden="true">
              🎉
            </span>
            <h2 className={styles.title}>Sent</h2>
            <h3 className={styles.subtitle}>
              We've sent a link to your phone.
            </h3>
          </div>
        );

      case SCREENS.FORM_SCREEN:
      default:
        analytics?.track(
          new View({
            pageType: View.PAGE_TYPES.INSTALL(),
            sourcePageType: Click.SOURCE_PAGE_TYPES.INSTALL(),
            interaction: 'get_app_modal',
          })
        );

        return (
          <div className={styles.modal}>
            <IconButton
              className={styles.close}
              icon={<CloseLineIcon />}
              onClick={onHide}
            />
            <div className={styles['modal-content']}>
              <span className={styles['logo-icon']}>
                <img src={blackLogoIcon} alt="" />
              </span>
              <h2 id="get-the-app" className={styles['app-install-title']}>
                Get the App
              </h2>
              {isMobile ? (
                <div className={styles['app-install-icons']}>
                  <AppLink>
                    <img
                      src={iosStoreBadge}
                      alt="Get the Gametime app on the Apple App Store"
                    />
                  </AppLink>
                  <AppLink>
                    <img
                      src={playStoreBadge}
                      alt="Get the Gametime app on Google Play"
                    />
                  </AppLink>
                </div>
              ) : (
                <div className={styles['app-install-form']}>
                  <h3 className={styles.subtitle}>
                    Just enter your phone number and we'll
                    <br />
                    send you a link.
                  </h3>
                  <hr className={styles['form-separator']} />
                  <UserPhoneForm
                    handleUserPhoneFormSubmit={handleSubmit}
                    ctaText="Send Link"
                  />
                  {error && <span className={styles.error}>{error}</span>}
                  <p className={styles.note}>
                    <span>Available for free on the </span>
                    <a
                      className={styles.emphasis}
                      href={config.APP_STORE_URL}
                      onClick={() => handleDownloadAppClick('ios_download')}
                    >
                      App Store
                    </a>
                    <span> and </span>
                    <a
                      className={styles.emphasis}
                      href={config.PLAY_STORE_URL}
                      onClick={() => handleDownloadAppClick('android_download')}
                    >
                      Google Play.
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <GTUniversalModal
      show={show}
      onHide={handleHide}
      aria={{ labelledby: 'get-the-app' }}
    >
      {renderScreen()}
    </GTUniversalModal>
  );
};

GetAppModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
};

export default GetAppModal;
