import React from 'react';

import { useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import ReactSlickCarousel from 'components/ReactSlickCarousel/ReactSlickCarousel';
import { device, useMediaQuery } from 'hooks';

import styles from './TopArrowsCarousel.module.scss';

interface TopArrowsCarouselProps<T> {
  header: React.ReactNode;
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  onTopForwardClick?: () => void;
  onTopBackClick?: () => void;
  name: string;
  className?: string;
  getItemKey?: (item: T) => string;
}

function TopArrowsCarousel<T>({
  header,
  items,
  renderItem,
  onTopForwardClick,
  onTopBackClick,
  name,
  className,
  getItemKey,
}: TopArrowsCarouselProps<T>) {
  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const isMobile = useMediaQuery(device.down.md);

  const renderHeader = () => {
    return <div className={styles['carousel-header']}>{header}</div>;
  };

  return (
    <div className={styles['carousel-container']}>
      <ReactSlickCarousel
        key={`top-arrow-carousel-${name}`}
        title="top-arrow-carousel"
        analytics={analytics}
        clickContext={clickContext}
        items={items}
        renderItem={renderItem}
        spacing={isMobile ? 16 : 24}
        slideWidth={isMobile ? 320 : 357}
        renderHeader={renderHeader}
        topArrows
        onTopForwardClick={onTopForwardClick}
        onTopBackClick={onTopBackClick}
        className={className}
        getItemKey={getItemKey}
      />
    </div>
  );
}

export default TopArrowsCarousel;
