import React from 'react';
import { add, getYear, isAfter, parseISO, startOfDay } from 'date-fns';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';

import { BADGES } from 'components/UrgencyBadge/UrgencyBadge';
import { EVENT_TYPE } from 'pages/Performer/EventTypeTabBar/constants';
import {
  CATEGORIES,
  getCategoryPrefix,
} from 'store/modules/categories/category.helpers';
import colors from 'styles/colors.constants';
import {
  DATE_FORMAT_M_D,
  DAY_DATE_FORMAT,
  DAY_DATE_YEAR_FORMAT,
  DAY_FORMAT,
  formatDate,
  formatUpcomingDate,
  getOverrideDayLabelConfig,
  isLastCall,
  isTodayOrTonight,
  TIME_FORMAT,
  urlDate,
} from 'utils/datetime';
import { formatUrl } from 'utils/format';
import { DEFAULT_NEARBY_DISTANCE, distanceBetweenLocations } from 'utils/geo';

import Event from './Event';
import EventTag from './EventTag';
import Performer from './Performer';
import Venue from './Venue';

export const NAME_TYPES = {
  DEFAULT: 'default',
  SHORT: 'short',
  MEDIUM: 'medium',
};

const nameKeyLookup = {
  [NAME_TYPES.DEFAULT]: 'name',
  [NAME_TYPES.SHORT]: 'shortName',
  [NAME_TYPES.MEDIUM]: 'mediumName',
};

export default class FullEvent {
  constructor(fullEvent = {}, searchSessionId) {
    this.event = new Event(fullEvent.event);
    this.venue = new Venue(fullEvent.venue);

    this.performersList = fullEvent.performers
      ? fullEvent.performers.map((performer) => new Performer(performer))
      : [];
    /* Custom Properties */
    this.uniquePerformersList = _uniqBy(this.performersList, 'name');
    this.datetimeFriendly = formatUpcomingDate(
      this.event.datetimeLocal,
      this.event.datetimeUtc
    );
    this.overrideDayLabelConfig = getOverrideDayLabelConfig(
      this.event.datetimeLocal,
      this.event.datetimeUtc
    );
    this.eventTag = new EventTag(
      this.event.datetimeUtc,
      this.event.datetimeLocal,
      this.venue.timezone
    );
    this.algoliaFields = {
      queryId: fullEvent.meta?.query_id,
      resultPosition: fullEvent.meta?.result_position,
      searchIndex: fullEvent.meta?.search_index,
      searchSessionId: searchSessionId,
    };
    this.popularityScore = fullEvent.meta?.popularity_score;
  }

  /**
   * Getters
   */

  badgeProps({ hours = 3, isTopHotEvent = false, isTopDealEvent = false }) {
    if (isLastCall(this.event.datetimeUtc, this.venue.timezone, hours))
      return BADGES.LAST_CALL;

    if (isTopDealEvent) return BADGES.GT_DEALS;

    if (isTopHotEvent) return BADGES.TOP_EVENT;

    return null;
  }

  tagProps(hours = 3, isTopPick = false) {
    return this.eventTag.props(hours, isTopPick);
  }

  getTagData() {
    if (isLastCall(this.event.datetimeUtc, this.venue.timezone, 3)) {
      return {
        title: 'LAST CALL',
        emoji: '0x23f1',
        background: 'linear-gradient(#65FFB5,#00D5E6)',
      };
    }
    if (isTodayOrTonight(this.event.datetimeUtc, this.venue.timezone)) {
      return { title: 'TODAY', emoji: '0x1f5d3', background: colors.white };
    }

    const { headline, emoji } = this.event.banner;

    if (!headline || !emoji) return null;
    return { title: headline, emoji, background: colors.white };
  }

  getUISectionIndex() {
    return this.sectionIndex == null ? -1 : this.sectionIndex;
  }

  getDate(format = DAY_DATE_FORMAT) {
    const { date_tbd, datetimeLocal } = this.event;

    return date_tbd ? 'Date TBD' : formatDate(datetimeLocal, format);
  }

  getDateLabels() {
    const { date_tbd } = this.event;
    const dateLabel = date_tbd ? 'Date' : this.getDate(DATE_FORMAT_M_D);
    const day =
      this.overrideDayLabelConfig?.overrideDayLabelShort ||
      this.overrideDayLabelConfig?.overrideDayLabel ||
      this.getDate(DAY_FORMAT);
    const dayLabel = date_tbd ? 'TBD' : _capitalize(day);

    return { dateLabel, dayLabel };
  }

  getTime(format = TIME_FORMAT) {
    const { time_tbd, datetimeLocal } = this.event;

    return time_tbd
      ? 'Time TBD'
      : formatDate(datetimeLocal, format).toUpperCase();
  }

  getMonthDay() {
    const { date_tbd, datetimeLocal } = this.event;
    return date_tbd ? 'Date TBD' : formatDate(datetimeLocal, DATE_FORMAT_M_D);
  }

  getDateTime() {
    const { date_tbd, datetimeLocal } = this.event;
    if (!date_tbd && getYear(parseISO(datetimeLocal)) !== getYear(new Date())) {
      return [this.getDate(DAY_DATE_YEAR_FORMAT), this.getTime()];
    }
    return [this.getDate(), this.getTime()];
  }

  get dateTimeVenue() {
    const items = this.getDateTime();
    const { name: venueName } = this.venue;

    if (venueName) {
      items.push(venueName);
    }

    return items;
  }

  get id() {
    return this.event ? this.event.id : '';
  }

  getPath() {
    const { id, category, datetimeLocal } = this.event;
    const { name: venueName, city, state } = this.venue;
    const categoryPrefix = getCategoryPrefix(category);

    return (
      `/${formatUrl(categoryPrefix)}` +
      `/${formatUrl(`${this.getShortName()} tickets`)}` +
      `/${formatUrl(
        `${urlDate(datetimeLocal)} ${city} ${state} ${venueName}`
      )}/events/${id}`
    );
  }

  get banner() {
    return this.event?.banner;
  }

  get category() {
    return this.event?.category;
  }

  get isMusicCategory() {
    return this.category === 'music';
  }

  get isMLBEvent() {
    return this.category === CATEGORIES.MLB;
  }

  get hasMatchupLogos() {
    return (
      this.isMLBEvent &&
      this.uniquePerformersList.length === 2 &&
      this.getPrimaryPerformer().logoImageUrl &&
      this.getPrimaryPerformer().logoBgColor &&
      this.getSecondaryPerformer().logoImageUrl &&
      this.getSecondaryPerformer().logoBgColor
    );
  }

  getName(performerNamesSeparator = 'at', secondaryPerformerFirst = true) {
    return this._getName(
      NAME_TYPES.DEFAULT,
      performerNamesSeparator,
      secondaryPerformerFirst
    );
  }

  getMediumName(
    performerNamesSeparator = 'at',
    secondaryPerformerFirst = true
  ) {
    return this._getName(
      NAME_TYPES.MEDIUM,
      performerNamesSeparator,
      secondaryPerformerFirst
    );
  }

  getShortName(performerNamesSeparator = 'at', secondaryPerformerFirst = true) {
    return this._getName(
      NAME_TYPES.SHORT,
      performerNamesSeparator,
      secondaryPerformerFirst
    );
  }

  getShortNameMarkup(
    performerNamesSeparator = 'at',
    secondaryPerformerFirst = true
  ) {
    return this._getName(
      NAME_TYPES.SHORT,
      performerNamesSeparator,
      secondaryPerformerFirst,
      true
    );
  }

  getCategoryGroup() {
    const performer = this.getPrimaryPerformer();
    return performer?.categoryGroup || '';
  }

  isParkingEvent() {
    return this.event.venueConfig === EVENT_TYPE.PARKING.id;
  }

  hasExclusives() {
    return this.event.hasExclusives;
  }

  getPrimaryPerformer() {
    return this._getPerformer(true);
  }

  getSecondaryPerformer() {
    return this._getPerformer(false);
  }

  get venueName() {
    return this.venue ? this.venue.name : '';
  }

  get venueCityState() {
    const city = this.venue ? this.venue.city : '';
    const state = this.venue ? this.venue.state : '';
    return `${city}${city ? ',' : ''} ${state}`;
  }

  get venueState() {
    return this.venue ? this.venue.state : '';
  }

  _getPriceValue(price) {
    return price.prefee / 100;
  }

  getPrice() {
    return this._getPriceValue(this.event.minPrice);
  }

  get highPrice() {
    return this.event.highPrice
      ? this._getPriceValue(this.event.highPrice)
      : null;
  }

  set highPrice(price) {
    this.event.highPrice = price;
  }

  getPrimaryPerformerImageUrl() {
    return this.getPrimaryPerformer().heroImageUrl;
  }

  get currencyPrefix() {
    return this.venue.showCurrency ? 'USD $' : '$';
  }

  getAltText() {
    if (
      this.uniquePerformersList.length === 1 ||
      !this.isSportsCategoryGroup()
    ) {
      return `${this.getPrimaryPerformer().name} will be playing at ${
        this.venue.name
      } in ${this.venue.city}`;
    }
    return `${this.getPrimaryPerformer().name} will be playing ${
      this.getSecondaryPerformer().name
    } at ${this.venue.name} in ${this.venue.city}`;
  }

  getPerformerId = () => {
    const primaryPerformer = this.getPrimaryPerformer();
    return _get(primaryPerformer, 'slug');
  };

  get venueId() {
    return this.venue ? this.venue.id : '';
  }

  get metro() {
    return this.venue ? this.venue.metro : '';
  }

  getRelatedEvents = () => {
    return _get(this.event, 'relatedEvents');
  };

  /**
   * Setters
   */

  setUISectionIndex(sectionIndex) {
    this.sectionIndex = sectionIndex;
  }

  /**
   * Boolean
   */

  isSportsCategoryGroup() {
    if (!this.performersList || !this.performersList.length) {
      return false;
    }

    return this.performersList[0].isSportsCategoryGroup;
  }

  isPastEvent() {
    const now = new Date();
    const eventDate = parseISO(this.event.datetimeUtc);
    const endOfEventDate = add(startOfDay(eventDate), { days: 1 });

    return isAfter(now, endOfEventDate);
  }

  isUpcomingEvent() {
    return !this.isPastEvent();
  }

  isNearbyLatLong(latLong) {
    const distance = distanceBetweenLocations(
      {
        lat: this.venue.location.lat,
        lon: this.venue.location.lon,
      },
      latLong
    );

    return distance < DEFAULT_NEARBY_DISTANCE;
  }

  isValid() {
    if (!this.event || !this.event.salesCutOff) {
      return false;
    }

    return new Date(this.event.salesCutOff).getTime() > Date.now();
  }

  get daysSinceExpiration() {
    if (!this.event || !this.event.salesCutOff) {
      return null;
    }

    const salesCutOffDate = new Date(this.event.salesCutOff);
    const currentDate = new Date();

    if (currentDate < salesCutOffDate) {
      return 0;
    }

    const diffInTime = currentDate.getTime() - salesCutOffDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    return Math.floor(diffInDays);
  }

  isNameOverriden(type) {
    const nameKey = nameKeyLookup[type];
    return !!this.event.nameOverride[nameKey];
  }

  /**
   * Private
   */

  _getPerformer(primary) {
    const primaryPerformerRef = this.event.performersList.find(
      (performer) => performer.primary === primary
    );
    if (!primaryPerformerRef) {
      return null;
    }

    return this.performersList.find(
      (performer) => performer.id === primaryPerformerRef.id
    );
  }

  _getName(
    type,
    performerNamesSeparator = 'at',
    secondaryPerformerFirst = true,
    addMarkup = false
  ) {
    const nameKey = nameKeyLookup[type];

    if (this.event.nameOverride[nameKey]) {
      return this.event.nameOverride[nameKey];
    }

    if (
      this.uniquePerformersList.length === 1 ||
      !this.isSportsCategoryGroup()
    ) {
      const primaryPerformer = this.getPrimaryPerformer();
      return primaryPerformer ? primaryPerformer[nameKey] : '';
    }

    let firstListedPerformer = '';
    let secondListedPerformer = '';
    if (secondaryPerformerFirst) {
      firstListedPerformer = `${this.getSecondaryPerformer()[nameKey]}`;
      secondListedPerformer = `${this.getPrimaryPerformer()[nameKey]}`;
    } else {
      firstListedPerformer = `${this.getPrimaryPerformer()[nameKey]}`;
      secondListedPerformer = `${this.getSecondaryPerformer()[nameKey]}`;
    }

    if (addMarkup) {
      return (
        <span>
          {firstListedPerformer}
          <span className="sports-name-separator">
            {' '}
            {performerNamesSeparator}{' '}
          </span>
          {secondListedPerformer}
        </span>
      );
    }

    return `${firstListedPerformer} ${performerNamesSeparator} ${secondListedPerformer}`;
  }
}
