export const PURCHASE_CTA_TEXTS = {
  LISTING: 'Buy now',
  CONFIRM: 'Pay Gametime',
  DEFAULT: 'Continue',
  PURCHASE_USER_VERIFY: 'Verify',
};

export const URGENCY_MESSAGING_THRESHOLD = 4;

export const WIDTH_MOBILE_SEAT_MAP = 320;

export const DEAL_VALUE_PERCENTILE = { super: 1, flash: 5, best: 10 };
