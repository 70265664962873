import React from 'react';
import { Outlet, redirect, useOutletContext } from 'react-router-dom';

import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';
import { useAppSelector } from 'store';
import { isUserLoggedIn } from 'store/modules/user/user.selectors';

import PurchaseFlowProvider from './PurchaseFlowContext';
import { DataLoader } from './routes.utils';

function ProtectedCheckoutRoute() {
  const { listing, event } = useOutletContext<{
    listing: Listing;
    event: FullEvent;
  }>();
  const isLoggedIn = useAppSelector(isUserLoggedIn);

  if (isLoggedIn) {
    return (
      <PurchaseFlowProvider listing={listing} event={event}>
        <Outlet context={{ listing, event }} />
      </PurchaseFlowProvider>
    );
  }

  return null;
}

/**
 * If the user is not logged in, redirect them to the checkout login page.
 * After logging in, they will be redirected back to the checkout page.
 */
const loader: DataLoader =
  ({ store }) =>
  async ({ params: { eventId, listingId } }) => {
    if (!isUserLoggedIn(store.getState())) {
      return redirect(
        `/events/${eventId}/listings/${listingId}/checkout/login`
      );
    }

    return null;
  };

ProtectedCheckoutRoute.loader = loader;

export default ProtectedCheckoutRoute;
