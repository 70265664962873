import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _debounce from 'lodash/debounce';

import styles from './MapListSwitch.module.scss';

interface MapListLabelProps {
  isActive: boolean;
  children: React.ReactNode;
}

const MapListLabel = ({ isActive, children }: MapListLabelProps) => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScrollEnd = _debounce(() => setIsScrolling(false), 1000);
    const handleScroll = () => {
      setIsScrolling(true);
      handleScrollEnd();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={classNames(styles['label-content'], {
        [styles.transparent]: isScrolling,
        [styles.active]: isActive,
      })}
    >
      {children}
    </div>
  );
};

export default MapListLabel;
