import React from 'react';

import colors from 'styles/colors.constants';

import styles from './ValuePropCard.module.scss';

interface ValuePropCardProps {
  children: React.ReactNode;
  gradientColor?: string;
}

function ValuePropCard({
  children,
  gradientColor = colors.white,
}: ValuePropCardProps) {
  return (
    <article
      className={styles.card}
      style={{
        background: `radial-gradient(circle at bottom, ${gradientColor}, ${colors.gray800} 80%)`,
      }}
    >
      {children}
    </article>
  );
}

export default ValuePropCard;
