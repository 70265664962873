import { UnknownAction } from '@reduxjs/toolkit';

import { ACTIONS } from './constants';

export type EventPageLoadTime = {
  eventPageRequestsStartTime: number;
  eventPageRequestsFinishTime: number;
};

export interface EventPageAction extends UnknownAction {
  type: string;
  eventsPageData: EventPageLoadTime;
}

const initialState: EventPageLoadTime = {
  eventPageRequestsStartTime: 0,
  eventPageRequestsFinishTime: 0,
};

export default function reducer(
  state: EventPageLoadTime | undefined = initialState,
  action: EventPageAction
) {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return {
        ...action.eventsPageData,
      };
    default:
      return state;
  }
}
