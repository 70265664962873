import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAppContext } from 'contexts/AppContext';
import withRouter from 'hoc/withRouter';
import PropTypes from 'prop-types';

import {
  Click,
  PAYLOAD,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import { getCategoryBreadcrumbConfig } from 'components/Breadcrumbs/breadcrumb.helpers';
import Collection from 'components/Collection/Collection.tsx';
import EnsurePath from 'components/EnsurePath/EnsurePath';
import { EVENT_TYPES } from 'components/FullEventPagination/constants';
import FullEventsSection from 'components/FullEventPagination/FullEventsSection';
import GTGuaranteeInlineBanner from 'components/GTGuaranteeInlineBanner/GTGuaranteeInlineBanner';
import HeadCanonicalPath from 'components/Head/CanonicalPath';
import HeadDescription from 'components/Head/Description';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import { getCanonicalQuery } from 'components/Head/utils';
import HeroSection from 'components/HeroSection/HeroSection';
import getSportsTeamJsonLD from 'components/JsonLD/helpers/sportsTeam';
import JsonLD from 'components/JsonLD/JsonLD';
import ParkingEventsSection from 'components/ParkingEventSection/ParkingEventsSection';
import PerformerContent from 'components/Performer/PerformerContent';
import PerformersSection from 'components/Performer/PerformersSection';
import PerformerFilters from 'components/PerformerFilters/PerformerFilters';
import {
  DATE_RANGE_FILTERS,
  GAME_TYPE_FILTERS,
} from 'components/PerformerFilters/PerformerFilters.constants';
import PerformerLinks from 'components/PerformerLinks/PerformerLinks';
import PerformerLogo from 'components/PerformerLogo/PerformerLogo';
import PerformersInContextCarousel from 'components/PerformersInContextCarousel/PerformersInContextCarousel';
import ReviewsCarousel from 'components/ReviewsCarousel/ReviewsCarousel';
import Section from 'components/Section/Section';
import TabBar from 'components/TabBar/TabBar';
import {
  selectIsAppReviewsExperiment,
  selectIsMLBTeamLogosPerformerExperiment,
  selectIsPerformerInlinePricingMobileExperiment,
} from 'experiments';
import {
  Collection as CollectionModel,
  FullEvent,
  Performer,
  PerformerContent as PCModel,
  PerformerInContext,
} from 'models';
import { COLLECTION_VIEWS } from 'pages/Collection/constants';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import NotFound from 'pages/NotFound/NotFound';
import {
  currentLocationSelector,
  showAppSpinner,
  updateCurrentLocation,
} from 'store/modules/app/app';
import { CATEGORIES } from 'store/modules/categories/category.helpers';
import { fetchPerformerContentBySlug } from 'store/modules/content/performers/actions';
import { selectPerformerContentBySlug } from 'store/modules/content/performers/selectors';
import { fetchCollections } from 'store/modules/data/Collections/actions';
import { selectPerformerPageCollections } from 'store/modules/data/Collections/selectors';
import { fetchFullEvents } from 'store/modules/data/FullEvents/actions';
import {
  calculateTopPickPerformers,
  fetchAllPerformers,
  fetchPerformerBySlug,
  fetchPerformers,
  fetchPerformersByCategory,
  fetchTrendingPerformersByCategoryGroup,
} from 'store/modules/data/Performers/actions';
import {
  makeGetSelectPerformerBySlug,
  makeGetSelectPerformersByCategory,
  selectRelatedPerformersBySlug,
  selectTopPickPerformers,
} from 'store/modules/data/Performers/selectors';
import { fetchPerformersInContext } from 'store/modules/data/PerformersInContext/actions';
import { selectPerformersInContextByFilters } from 'store/modules/data/PerformersInContext/selectors';
import { fetchReviews } from 'store/modules/data/Reviews/actions';
import { selectReviews } from 'store/modules/data/Reviews/selectors';
import { selectSearchTestData } from 'store/modules/data/Search/selectors';
import { getPerformerContentSlug } from 'store/modules/performers/performers.helpers';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectAllMetros,
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { fetchUserPurchasesIfIdle } from 'store/modules/userPurchases/actions';
import {
  getDefaultRange,
  getNextWeekendDates,
  getSingleDayRange,
  getThisWeekendDates,
} from 'utils/datetime';
import { addQuery, searchStringToQueryObject } from 'utils/url';

import { EVENT_TYPE } from './EventTypeTabBar/constants';
import SeoEventsTable from './SeoEventsTable/SeoEventsTable';
import { getPerformerPathname } from './helpers';
import { getHeroTitle } from './utils';

import styles from './Performer.module.scss';

const MLB_GAMES_PER_SEASON = 162;
export const PERFORMERS_IN_CONTEXT_PER_PAGE = 30;

const PARKING_SLUG = 'parking';

const REVIEWS_CAROUSEL_DISPLAY_INDEX = 5;

const mapPropsToPathname = ({ performer, matchupPerformer, isParkingPage }) => {
  return getPerformerPathname(performer, matchupPerformer, isParkingPage);
};

@EnsurePath(mapPropsToPathname)
@TrackPageView(({ performer, location, searchTestData }) => {
  if (!performer) {
    return null;
  }
  const query = searchStringToQueryObject(location.search);

  return {
    [TRACK.PAGE_TYPE]: View.PAGE_TYPES.PERFORMER(performer.id),
    payload: {
      [PAYLOAD.QUERY_ID]: query.queryId,
      [PAYLOAD.RESULT_POSITION]: query.resultPosition,
      [PAYLOAD.SEARCH_INDEX]: query.searchIndex,
      [PAYLOAD.SEARCH_SESSION_ID]: query.searchSessionId,
      [PAYLOAD.SEARCH_TEST_ID]: searchTestData?.testId,
      [PAYLOAD.SEARCH_VARIANT_ID]: searchTestData?.variantId,
    },
    dev: {
      performer_slug: performer.slug,
    },
  };
})
@withClickContext(({ performer }) => {
  if (!performer) {
    return undefined;
  }
  return {
    [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.PERFORMER(performer.id),
  };
})
@withAnalyticsContext
class PerformerPage extends Component {
  static propTypes = {
    performer: PropTypes.instanceOf(Performer),
    collections: PropTypes.arrayOf(PropTypes.instanceOf(CollectionModel)),
    categoryConfig: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      hidden: PropTypes.bool,
    }),
    performerGenreConfig: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string.isRequired,
      hidden: PropTypes.bool.isRequired,
    }),
    relatedPerformers: PropTypes.arrayOf(PropTypes.instanceOf(Performer)),
    performerContent: PropTypes.instanceOf(PCModel),
    matchupPerformer: PropTypes.instanceOf(Performer),
    params: PropTypes.object.isRequired,
    fetchAllPerformers: PropTypes.func.isRequired,
    fetchFullEvents: PropTypes.func.isRequired,
    fetchCollections: PropTypes.func.isRequired,
    currentMetro: PropTypes.object,
    performerPageData: PropTypes.arrayOf(
      PropTypes.shape({
        events: PropTypes.array,
        more: PropTypes.bool.isRequired,
        params: PropTypes.object.isRequired,
      })
    ),
    showMLBMarketplaceTag: PropTypes.bool,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    fetchPerformersInContext: PropTypes.func,
    fetchTrendingPerformersByCategoryGroup: PropTypes.func.isRequired,
    isMLBPerformer: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }).isRequired,
    showAppSpinner: PropTypes.func,
    metros: PropTypes.array,
    closestMetro: PropTypes.object,
    isMatchupPerformer: PropTypes.bool.isRequired,
    genrePerformers: PropTypes.arrayOf(
      PropTypes.instanceOf(PerformerInContext)
    ),
    performerGenre: PropTypes.string,
    isParkingPage: PropTypes.bool,
    isMatchupPage: PropTypes.bool,
    isPerformerInlinePricingMobileExperiment: PropTypes.bool,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    router: PropTypes.object.isRequired,
    clickContext: PropTypes.object,
    searchTestData: PropTypes.shape({
      testId: PropTypes.string,
      variantId: PropTypes.string,
    }),
    calculateTopPickPerformers: PropTypes.func.isRequired,
    topPickPerformers: PropTypes.arrayOf(
      PropTypes.instanceOf(Performer).isRequired
    ).isRequired,
    isAppReviewsExperiment: PropTypes.bool,
    isMLBTeamLogosPerformerExperiment: PropTypes.bool,
    reviews: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = this.#getInitialState();

    this.setActiveTabItem = this.setActiveTabItem.bind(this);
    this.fetchMoreEvents = this.fetchMoreEvents.bind(this);
    this.getParkingEventIds = this.getParkingEventIds.bind(this);
    this.fetchEvents = this.fetchEvents.bind(this);
    this.handleSelectDateRange = this.handleSelectDateRange.bind(this);
    this.setGameTypeFilter = this.setGameTypeFilter.bind(this);
    this.setDateRangeFilter = this.setDateRangeFilter.bind(this);
  }

  /**
   * Constructs initial pagination state for tabs and selects an initial
   * active tab key. This should also be used to generate fresh pagination
   * state if the performer slug changes during the component lifecycle.
   */
  #getInitialState() {
    const { performerPageData, isParkingPage, performer } = this.props;

    const activeTabItem = isParkingPage
      ? EVENT_TYPE.PARKING.id
      : EVENT_TYPE.EVENTS.id;

    if (!Array.isArray(performerPageData)) {
      return {
        tabsState: {},
        activeTabItem,
        parkingEventIds: [],
        topEvents: {
          hotEvents: [],
          dealEvents: [],
        },
        currentMetro: this.props.currentMetro,
        range: {},
      };
    }

    const tabsState = Object.fromEntries(
      this.props.performerPageData.map(({ events, more, params }) => [
        params.eventType,
        {
          isEmpty: !events?.length,
          isLoading: false,
          more,
          params,
          fullEvents: this.getMatchupFullEvents(
            events.map((e) => new FullEvent(e))
          ),
        },
      ])
    );

    const allPerformerEvents = tabsState.allPerformerEvents || {
      fullEvents: [],
    };
    const performerEvents = allPerformerEvents.fullEvents.filter(
      (e) => e?.event.minPrice.total > 0
    );

    return {
      tabsState,
      performerEvents,
      activeTabItem,
      parkingEventIds: this.getParkingEventIds(tabsState),
      topEvents: this.getTopEventsByPopularityScore(tabsState),
      currentMetro: this.props.currentMetro,
      gameTypeFilter: GAME_TYPE_FILTERS.HOME_AWAY,
      range: getDefaultRange(performer),
      dateRangeFilter: DATE_RANGE_FILTERS.ALL_DATES,
    };
  }

  componentDidMount() {
    const { tabsState } = this.state;

    this.fetchRelatedPerformers(this.props.currentMetro);
    this.setState({
      parkingEventIds: this.getParkingEventIds(tabsState),
      topEvents: this.getTopEventsByPopularityScore(tabsState),
    });
  }

  componentDidUpdate(prevProps) {
    const { currentMetro } = this.props;

    if (prevProps.params.slug !== this.props.params.slug) {
      // reset state if slug in URL changes
      this.setState(this.#getInitialState());
      this.fetchRelatedPerformers(currentMetro);
      this.fetchEvents(
        this.props.currentMetro,
        this.getDateRangeParams(),
        this.getPerformerFilter()
      );
    }
    // get new performer events if currentMetro changes
    if (prevProps.currentMetro !== this.props.currentMetro) {
      this.fetchPerformersOnMetroChange(this.props.currentMetro);
      this.fetchEvents(
        this.props.currentMetro,
        this.getDateRangeParams(),
        this.getPerformerFilter()
      );
    }
  }

  /**
   * Fetching more events with fetchPaginatedFullEvents adds the events to
   * Redux state, but we also need to update local state for loading/pagination
   * in the tabs.
   */
  async fetchMoreEvents(eventType) {
    this.setState((state) => ({
      tabsState: {
        ...state.tabsState,
        [eventType]: {
          ...state.tabsState[eventType],
          isLoading: true,
        },
      },
    }));

    const params = this.state.tabsState[eventType].params;
    const dateRange = this.getDateRangeParams();
    const performerFilter = this.getPerformerFilter();
    const res = await this.props.fetchFullEvents({
      ...params,
      ...dateRange,
      ...performerFilter,
      page: params?.page + 1,
    });

    const fullEvents = this.getMatchupFullEvents(
      res.events.map((e) => new FullEvent(e))
    );

    this.setState((state) => ({
      tabsState: {
        ...state.tabsState,
        [eventType]: {
          ...state.tabsState[eventType],
          isLoading: false,
          more: res?.more,
          params: {
            ...state.tabsState[eventType].params,
            page: res?.page,
            per_page: res?.per_page,
          },
          fullEvents: [...state.tabsState[eventType].fullEvents, ...fullEvents],
        },
      },
    }));
  }

  async fetchPerformersOnMetroChange(currentMetro) {
    const {
      fetchPerformersInContext,
      fetchTrendingPerformersByCategoryGroup,
      fetchCollections,
      fetchAllPerformers,
      isMLBPerformer,
      performer,
      calculateTopPickPerformers,
    } = this.props;
    // update local performers - this is a patch to accomodate SearchBox having mixed dependencies
    // TODO: this code should be removed once SearchBox is refactored to not rely on redux
    await fetchAllPerformers(currentMetro);
    await fetchTrendingPerformersByCategoryGroup(currentMetro.id);

    const genre = performer.getPerformerGenre();
    if (performer.category === CATEGORIES.MUSIC && genre) {
      // update genre performers carousel
      try {
        await fetchPerformersInContext({
          metro: currentMetro.id,
          category_group: 'concert',
          genre: genre.split('-')[0],
          limit: 15,
          skipCache: true,
          sort_by: '-event.trending_score',
        });
      } catch (err) {
        console.error('Error fetching genre performers: ', err);
      }
    }
    // update popular performers carousel
    await fetchCollections({
      metro: currentMetro.id,
      with_results: true,
      view: COLLECTION_VIEWS.WEB_PERFORMER,
    });

    // update top picks performer links
    if (isMLBPerformer) {
      try {
        const performersInContext = await fetchPerformersInContext({
          metro: currentMetro.id,
          limit: PERFORMERS_IN_CONTEXT_PER_PAGE,
        });
        calculateTopPickPerformers(performersInContext?.performers, performer);
      } catch (error) {
        console.error('Performer Top Pick fetch error: ', error);
      }
    }
  }

  async fetchEvents(currentMetro, rangeParams = {}, performerFilter = {}) {
    this.props.showAppSpinner(true);
    const tabsConfig = [
      {
        eventType: EVENT_TYPES.NEAR.eventType,
        [EVENT_TYPES.NEAR.eventSlug]: this.props.params.slug,
        metro: currentMetro.id,
      },
      {
        eventType: EVENT_TYPES.ALL.eventType,
        [EVENT_TYPES.ALL.eventSlug]: this.props.params.slug,
      },
    ];

    this.setState((state) => ({
      tabsState: {
        ...state.tabsState,
        [EVENT_TYPES.NEAR.eventType]: {
          ...state.tabsState[EVENT_TYPES.NEAR.eventType],
          isLoading: true,
        },
        [EVENT_TYPES.ALL.eventType]: {
          ...state.tabsState[EVENT_TYPES.ALL.eventType],
          isLoading: true,
        },
      },
    }));

    const events = await Promise.all(
      tabsConfig.map((tabConfig) =>
        this.props
          .fetchFullEvents({
            ...tabConfig,
            page: 1,
            per_page: this.props.params.isMatchupPage
              ? MLB_GAMES_PER_SEASON
              : 15,
            resetState: false,
            zEvents01: 'pub_v0',
            ...rangeParams,
            ...performerFilter,
          })
          .then((res) => ({
            events: res?.events,
            more: res?.more,
            params: {
              ...tabConfig,
              page: res?.page,
              per_page: res?.per_page,
            },
          }))
          .catch((error) => {
            console.error('Error fetching performer events: ', error);
            return {
              events: [],
              more: false,
              params: tabConfig,
            };
          })
      )
    );

    const nextTabsState = events.reduce((acc, { events, more, params }) => {
      const validMatchUpEvents = this.getMatchupFullEvents(
        events.map((e) => new FullEvent(e))
      ).filter((event) => event.isValid());

      acc[params.eventType] = {
        isEmpty: !validMatchUpEvents?.length,
        isLoading: false,
        more,
        params,
        fullEvents: validMatchUpEvents,
      };
      return acc;
    }, {});

    this.setState((state) => ({
      tabsState: {
        ...state.tabsState,
        ...nextTabsState,
      },
      parkingEventIds: this.getParkingEventIds(nextTabsState),
      topEvents: this.getTopEventsByPopularityScore(nextTabsState),
      activeTabItem: state.activeTabItem,
    }));

    this.props.showAppSpinner(false);
  }

  getDateRangeParams() {
    const { range } = this.state;

    return range.start && range.end
      ? {
          sales_cutoff_after: range.start,
          sales_cutoff_before: range.end,
        }
      : {};
  }

  async handleSelectDateRange(dateRange) {
    const { currentMetro, performer } = this.props;

    if (dateRange === this.state.dateRangeFilter) return;

    switch (dateRange) {
      case DATE_RANGE_FILTERS.TODAY:
        const todayRange = getSingleDayRange(new Date());
        this.setState({ range: todayRange });
        await this.fetchEvents(
          currentMetro,
          {
            sales_cutoff_after: todayRange.start,
            sales_cutoff_before: todayRange.end,
          },
          this.getPerformerFilter()
        );
        break;
      case DATE_RANGE_FILTERS.THIS_WEEKEND:
        const thisWeekendRange = getThisWeekendDates(new Date());
        this.setState({ range: thisWeekendRange });
        await this.fetchEvents(
          currentMetro,
          {
            sales_cutoff_after: thisWeekendRange.start,
            sales_cutoff_before: thisWeekendRange.end,
          },
          this.getPerformerFilter()
        );
        break;
      case DATE_RANGE_FILTERS.NEXT_WEEKEND:
        const nextWeekendRange = getNextWeekendDates(new Date());
        this.setState({ range: nextWeekendRange });
        await this.fetchEvents(
          currentMetro,
          {
            sales_cutoff_after: nextWeekendRange.start,
            sales_cutoff_before: nextWeekendRange.end,
          },
          this.getPerformerFilter()
        );
        break;
      default:
        this.setState({ range: getDefaultRange(performer) });
        await this.fetchEvents(
          currentMetro,
          { ...getDefaultRange(performer) },
          this.getPerformerFilter()
        );
    }
  }

  async fetchRelatedPerformers(currentMetro) {
    const { performer, fetchPerformersInContext } = this.props;
    if (!performer) return;
    if (performer.category === CATEGORIES.MUSIC) {
      // update genre performers carousel
      return fetchPerformersInContext({
        metro: currentMetro.id,
        category_group: 'concert',
        genre: performer.getPerformerGenre()?.split('-')[0],
        limit: 15,
        skipCache: true,
        sort_by: '-event.trending_score',
      });
    }
  }
  renderParkingMeta() {
    const {
      performer,
      performerContent,
      matchupPerformer,
      location: { pathname, search },
    } = this.props;
    const query = searchStringToQueryObject(search);
    let pageTitle = matchupPerformer?.name
      ? performer.getMatchupHeadTitle(matchupPerformer)
      : performer.getHeadTitle();
    let pageDescription = matchupPerformer?.slug
      ? performer.getMatchupHeadDescription(matchupPerformer)
      : performer.getHeadDescription();

    // If performer has metaTitle and metaDescription from CMS, overwrite them
    if (performerContent && performerContent.isActive) {
      const {
        metaTitle,
        metaParkingTitle,
        metaParkingDescription,
        metaDescription,
      } = performerContent;

      if (metaParkingTitle || metaTitle) {
        pageTitle = metaParkingTitle || metaTitle;
      }
      if (metaParkingDescription || metaDescription) {
        pageDescription = metaParkingDescription || metaDescription;
      }
    }

    return (
      <div>
        {!matchupPerformer && <HeadImage src={performer.heroImageUrl} />}
        <HeadDescription description={pageDescription} />
        <HeadCanonicalPath path={pathname} query={getCanonicalQuery(query)} />
        <HeadTitle title={pageTitle} />
      </div>
    );
  }

  renderMeta() {
    const {
      performer,
      performerContent,
      matchupPerformer,
      location: { pathname, search },
    } = this.props;
    const query = searchStringToQueryObject(search);
    let pageTitle = matchupPerformer?.name
      ? performer.getMatchupHeadTitle(matchupPerformer)
      : performer.getHeadTitle();
    let pageDescription = matchupPerformer?.slug
      ? performer.getMatchupHeadDescription(matchupPerformer)
      : performer.getHeadDescription();

    // If performer has metaTitle and metaDescription from CMS, overwrite them
    if (performerContent && performerContent.isActive) {
      const { metaTitle, metaDescription } = performerContent;

      if (metaTitle) {
        pageTitle = metaTitle;
      }
      if (metaDescription) {
        pageDescription = metaDescription;
      }
    }

    return (
      <div>
        {!matchupPerformer && <HeadImage src={performer.heroImageUrl} />}
        <HeadDescription description={pageDescription} />
        <HeadCanonicalPath path={pathname} query={getCanonicalQuery(query)} />
        <HeadTitle title={pageTitle} />
        {performer.isSportsCategoryGroup && (
          <JsonLD json={getSportsTeamJsonLD(performer)} />
        )}
      </div>
    );
  }

  setActiveTabItem(tabItem) {
    const { performer, matchupPerformer, router } = this.props;
    this.setState({ activeTabItem: tabItem });
    if (tabItem === EVENT_TYPE.PARKING.id) {
      const redirectPath = performer.getParkingPath(
        this.props.matchupPerformer
      );
      router.navigate(
        {
          pathname: redirectPath,
        },
        { replace: true }
      );
    } else {
      const performerPath = matchupPerformer
        ? performer.getMatchupPath(matchupPerformer)
        : performer.getPath();
      router.navigate({ pathname: performerPath }, { replace: true });
    }
  }

  getMatchupFullEvents(fullEvents) {
    const { matchupPerformer } = this.props;

    if (!fullEvents) {
      return [];
    }

    if (matchupPerformer && matchupPerformer.slug) {
      return fullEvents.filter((event) =>
        event.uniquePerformersList.some(
          (eventPerformer) => eventPerformer.slug === matchupPerformer.slug
        )
      );
    }

    return fullEvents;
  }

  getTopEventsByPopularityScore(tabsState) {
    if (Object.keys(tabsState).length === 0) {
      return [];
    }

    const allEvents = this.getMatchupFullEvents(
      tabsState[EVENT_TYPES.ALL.eventType]?.fullEvents
    );

    const nearbyEvents = this.getMatchupFullEvents(
      tabsState[EVENT_TYPES.NEAR.eventType]?.fullEvents
    );

    const fullEvents = [...allEvents, ...nearbyEvents];

    const topFullEvents = fullEvents
      .reduce((events, event) => {
        return events.some((e) => e.id === event.id)
          ? events
          : [...events, event];
      }, [])
      .sort((a, b) => b.popularityScore - a.popularityScore);

    const top10FullEvents = Math.max(Math.ceil(topFullEvents.length * 0.1), 1);

    const hotEvents = topFullEvents
      .slice(0, top10FullEvents)
      .map((event) => event.id);

    // Get top 10% of events with deal
    const topFullEventsWithDeal = topFullEvents.filter((event) =>
      event.hasExclusives()
    );

    const top10PercentEventsWithDeal = Math.max(
      Math.ceil(topFullEventsWithDeal.length * 0.1),
      1
    );

    const dealEvents = topFullEventsWithDeal
      .slice(0, top10PercentEventsWithDeal)
      .map((event) => event.id);

    return {
      hotEvents,
      dealEvents,
    };
  }

  getParkingEventIds(tabsState) {
    if (Object.keys(tabsState).length === 0) {
      return [];
    }

    const allEvents = this.getMatchupFullEvents(
      tabsState[EVENT_TYPES.ALL.eventType]?.fullEvents
    );

    const nearbyEvents = this.getMatchupFullEvents(
      tabsState[EVENT_TYPES.NEAR.eventType]?.fullEvents
    );

    const parkingEvents = [...allEvents, ...nearbyEvents];

    return parkingEvents.reduce((parkingEventIds, parkingEvent) => {
      const parkingEventId = parkingEvent.getRelatedEvents().parking?.[0];

      if (parkingEventId && !parkingEventIds.includes(parkingEventId)) {
        parkingEventIds.push(parkingEventId);
      }

      return parkingEventIds;
    }, []);
  }

  getPerformerFilter() {
    switch (this.state.gameTypeFilter) {
      case GAME_TYPE_FILTERS.HOME:
        return {
          primaryPerformerId: this.props.performer.id,
        };
      case GAME_TYPE_FILTERS.AWAY:
        return {
          secondaryPerformerId: this.props.performer.id,
        };
      case GAME_TYPE_FILTERS.HOME_AWAY:
      default:
        return {};
    }
  }

  setGameTypeFilter(gameTypeFilter) {
    if (this.state.gameTypeFilter !== gameTypeFilter) {
      this.setState({ gameTypeFilter });
      let gameTypeParams = {};
      switch (gameTypeFilter) {
        case GAME_TYPE_FILTERS.HOME:
          gameTypeParams = { primaryPerformerId: this.props.performer.id };
          break;
        case GAME_TYPE_FILTERS.AWAY:
          gameTypeParams = { secondaryPerformerId: this.props.performer.id };
          break;
        default:
          break;
      }

      this.fetchEvents(
        this.props.currentMetro,
        this.getDateRangeParams(),
        gameTypeParams
      );
    }
  }

  setDateRangeFilter(dateRangeFilter) {
    if (this.state.dateRangeFilter !== dateRangeFilter) {
      this.setState({ dateRangeFilter });
    }
  }

  render() {
    const {
      categoryConfig,
      collections,
      currentMetro,
      matchupPerformer,
      performer,
      performerGenreConfig,
      showMLBMarketplaceTag,
      isMLBPerformer,
      location,
      metros,
      closestMetro,
      isMatchupPerformer,
      genrePerformers,
      performerGenre,
      isParkingPage,
      isPerformerInlinePricingMobileExperiment,
      appContext: {
        state: { isMobile },
      },
      analyticsContext,
      clickContext,
      isAppReviewsExperiment,
      isMLBTeamLogosPerformerExperiment,
      reviews,
    } = this.props;

    if (!performer || !this.state) {
      return <NotFound />;
    }

    const {
      activeTabItem,
      parkingEventIds,
      performerEvents,
      tabsState,
      topEvents,
      gameTypeFilter,
      dateRangeFilter,
    } = this.state;
    const currentPageConfig = {
      ...performer.getBreadcrumbConfig(),
      hidden: true,
    };

    // if performer genre does not exist, filter below will remove the null value
    const breadcrumbs = [
      HOMEPAGE_BREADCRUMB_CONFIG,
      categoryConfig,
      currentPageConfig,
      performerGenreConfig,
    ].filter(Boolean);

    const heroTitle = getHeroTitle({
      performer,
      matchup: matchupPerformer,
      contentTemplate: searchStringToQueryObject(location.search).c_t,
    });

    const currentPage = tabsState[EVENT_TYPES.ALL.eventType]?.params?.page;
    const renderNoEvents = tabsState[EVENT_TYPES.ALL.eventType]?.isEmpty;

    const showInlinePricing =
      isPerformerInlinePricingMobileExperiment && isMobile;

    const tabs = [{ ...EVENT_TYPE.EVENTS, key: EVENT_TYPE.EVENTS.id }];

    if (parkingEventIds.length > 0 || isParkingPage) {
      tabs.push({ ...EVENT_TYPE.PARKING, key: EVENT_TYPE.PARKING.id });
    }
    const renderAllEvents =
      tabsState[EVENT_TYPES.ALL.eventType] &&
      tabsState[EVENT_TYPES.ALL.eventType].fullEvents;

    const renderNearEvents =
      tabsState[EVENT_TYPES.NEAR.eventType] &&
      tabsState[EVENT_TYPES.NEAR.eventType].fullEvents;

    const showMLBHeaderLogo =
      isMLBTeamLogosPerformerExperiment && isMLBPerformer && isMatchupPerformer;

    const heroLogo = showMLBHeaderLogo && (
      <PerformerLogo performer={performer} />
    );

    const imageProps = {
      ...performer.getImageOptions(),
      lazyLoad: false,
      isPreloaded: true,
    };

    if (showMLBHeaderLogo) {
      imageProps.src = performer.headerImageUrl;
    }

    const nearbyEventsQuantity =
      tabsState[EVENT_TYPES.NEAR.eventType]?.fullEvents?.length || 0;

    const allEventsQuantity =
      tabsState[EVENT_TYPES.ALL.eventType]?.fullEvents?.length || 0;

    const showReviewsCarouselAfterNearbyEvents =
      isAppReviewsExperiment &&
      nearbyEventsQuantity >= REVIEWS_CAROUSEL_DISPLAY_INDEX;

    const showReviewsCarouselInAllEvents =
      isAppReviewsExperiment &&
      nearbyEventsQuantity < REVIEWS_CAROUSEL_DISPLAY_INDEX &&
      allEventsQuantity > 0;

    const showReviewsCarouselAfterAllEvents =
      isAppReviewsExperiment &&
      !showReviewsCarouselAfterNearbyEvents &&
      !showReviewsCarouselInAllEvents;

    const allEventsDisplayIndex =
      REVIEWS_CAROUSEL_DISPLAY_INDEX - nearbyEventsQuantity;

    const reviewsComponentDisplayIndex = Math.max(0, allEventsDisplayIndex);

    return (
      <GTContainer
        canShowGoogleAdbanner
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          className: styles.header,
          showHamburger: true,
        }}
        bannerProps={{
          className: styles.banner,
          campaign: 'performer',
        }}
        className={styles['performer-container']}
      >
        {isParkingPage ? this.renderParkingMeta() : this.renderMeta()}
        <HeroSection
          className={styles['performer-hero-section']}
          breadcrumbProps={{ breadcrumbs }}
          title={heroTitle}
          imageProps={imageProps}
          currentMetro={currentMetro}
          showMLBMarketplaceTag={showMLBMarketplaceTag}
          heroLogo={heroLogo}
        />
        {!!Object.keys(tabsState).length && (
          <>
            <div className={styles['tab-bar-container']}>
              <TabBar
                activeTab={activeTabItem}
                onChange={this.setActiveTabItem}
                tabs={tabs}
              />
            </div>
            <div className={styles['body-container']}>
              <PerformerFilters
                metros={metros}
                currentMetro={currentMetro}
                closestMetro={closestMetro}
                isNearEmpty={tabsState[EVENT_TYPES.NEAR.eventType]?.isEmpty}
                onSelectDateRange={this.handleSelectDateRange}
                isHomeAwayFilterEnabled={isMatchupPerformer}
                setGameTypeFilter={this.setGameTypeFilter}
                setDateRangeFilter={this.setDateRangeFilter}
                dateRangeFilter={dateRangeFilter}
                currentGameTypeFilter={gameTypeFilter}
              />
              {activeTabItem === EVENT_TYPE.EVENTS.id ? (
                <>
                  {!!renderNearEvents && (
                    <FullEventsSection
                      renderNoEvents={renderNoEvents}
                      paginationState={tabsState[EVENT_TYPES.NEAR.eventType]}
                      event={EVENT_TYPES.NEAR}
                      performer={performer}
                      fetchMoreEvents={this.fetchMoreEvents}
                      matchupPerformer={matchupPerformer}
                      topEvents={topEvents}
                      showUrgencyBadge
                      showInlinePricing={showInlinePricing}
                    />
                  )}
                  {showReviewsCarouselAfterNearbyEvents && (
                    <div className={styles['performer-reviews-container']}>
                      <div className={styles['performer-reviews']}>
                        <ReviewsCarousel
                          reviews={reviews}
                          performer={performer}
                        />
                      </div>
                    </div>
                  )}
                  {!!renderAllEvents && (
                    <FullEventsSection
                      renderNoEvents={renderNoEvents}
                      paginationState={tabsState[EVENT_TYPES.ALL.eventType]}
                      event={EVENT_TYPES.ALL}
                      performer={performer}
                      fetchMoreEvents={this.fetchMoreEvents}
                      matchupPerformer={matchupPerformer}
                      topEvents={topEvents}
                      showUrgencyBadge
                      showInlinePricing={showInlinePricing}
                      customComponent={
                        showReviewsCarouselInAllEvents && (
                          <ReviewsCarousel
                            reviews={reviews}
                            performer={performer}
                          />
                        )
                      }
                      componentDisplayIndex={reviewsComponentDisplayIndex}
                    />
                  )}
                </>
              ) : (
                <ParkingEventsSection
                  eventIds={parkingEventIds}
                  performer={performer}
                />
              )}
              {showReviewsCarouselAfterAllEvents && (
                <div className={styles['performer-reviews-container']}>
                  <div className={styles['performer-reviews']}>
                    <ReviewsCarousel reviews={reviews} performer={performer} />
                  </div>
                </div>
              )}
              <div className={styles['performer-trust-inline']}>
                <GTGuaranteeInlineBanner />
              </div>
              <div className={styles['performers-carousel-container']}>
                {genrePerformers?.length > 0 && (
                  <div className={styles['performer-carousel']}>
                    <PerformersInContextCarousel
                      showSeeAll
                      seeAllLinkPath={`/concert-tickets/genre/${performerGenre}`}
                      carouselTitle={`${performerGenre} Performers`}
                      performersInContext={genrePerformers}
                      sectionIndex={1}
                    />
                  </div>
                )}
                {collections.map((collection, index) => (
                  <div
                    key={collection.id}
                    className={styles['performer-carousel']}
                  >
                    <Collection
                      collection={collection}
                      collectionTitle={collection.title}
                      currentMetro={currentMetro}
                      sectionIndex={index + 2} // other indices reserved
                      analytics={analyticsContext}
                      clickContext={clickContext}
                    />
                  </div>
                ))}
              </div>
              <PerformerContent
                performer={this.props.performer}
                performerContent={this.props.performerContent}
                matchupPerformer={this.props.matchupPerformer}
                isParkingPage={isParkingPage}
              />
              {isMLBPerformer && this.props.relatedPerformers?.length > 0 && (
                <Section
                  className={styles['performer-links-container']}
                  showHeadline={false}
                  headline=""
                >
                  <PerformerLinks
                    relatedPerformers={this.props.relatedPerformers}
                    topPickPerformers={this.props.topPickPerformers}
                    category={performer.category}
                  />
                </Section>
              )}
              {performerEvents?.length > 0 && (
                <SeoEventsTable
                  performerEvents={performerEvents}
                  category={performer.category}
                  performerName={performer.name}
                  metros={metros}
                />
              )}
              <PerformersSection
                category={this.props.performer.category}
                relatedPerformers={this.props.relatedPerformers}
                sectionTitle={
                  this.props.performerContent?.relatedPerformersTitle
                }
              />
            </div>
          </>
        )}
        {currentPage > 1 && (
          <a
            className={styles['pagination-link']}
            href={addQuery(location.pathname, location.search, { page: 1 })}
          />
        )}
        {(tabsState[EVENT_TYPES.ALL.eventType]?.more ||
          tabsState[EVENT_TYPES.NEAR.eventType]?.more) && (
          <a
            className={styles['pagination-link']}
            href={addQuery(location.pathname, location.search, {
              page: currentPage + 1,
            })}
          />
        )}
      </GTContainer>
    );
  }
}

const mapStateToProps = (
  state,
  {
    params: {
      slug: originalSlug,
      matchupSlug: originalMatchupSlug,
      parkingSlug,
    },
  }
) => {
  const isParkingPage =
    originalMatchupSlug === PARKING_SLUG || parkingSlug === PARKING_SLUG;
  const isMatchupPage =
    originalMatchupSlug && originalMatchupSlug !== PARKING_SLUG;

  const CMSSlugs = originalSlug.split('-');
  let slug = originalSlug;
  let matchupSlug = originalMatchupSlug;
  if (CMSSlugs.length === 2) {
    [slug, matchupSlug] = CMSSlugs;
  }

  const selectPerformerBySlug = makeGetSelectPerformerBySlug();
  const selectPerformersByCategory = makeGetSelectPerformersByCategory();
  const performer = selectPerformerBySlug(state, slug);

  if (!performer) {
    return {};
  }

  let matchupPerformer = null;
  if (matchupSlug !== slug && isMatchupPage) {
    matchupPerformer = selectPerformerBySlug(state, matchupSlug);
  }

  const relatedPerformers = selectPerformersByCategory(
    state,
    performer.category
  );

  const contentSlug = getPerformerContentSlug(slug, matchupSlug, isMatchupPage);
  const performerContent = selectPerformerContentBySlug(state, contentSlug);
  const relatedCMSPerformers = selectRelatedPerformersBySlug(
    state,
    contentSlug
  );

  const relatedPerformersList =
    relatedCMSPerformers?.length > 0 ? relatedCMSPerformers : relatedPerformers;

  const closestMetro = selectClosestMetro(state);

  const currentMetro = selectUserMetro(state) || closestMetro;

  const showMLBMarketplaceTag = performer.category === CATEGORIES.MLB;

  const filterKey = `${currentMetro.id}+view_${COLLECTION_VIEWS.WEB_PERFORMER}`;
  // currently only one collection is returned, but more are planned for the page
  const collections = selectPerformerPageCollections(state, filterKey);

  const performerGenre = performer.getPerformerGenre();
  const performerGenreConfig = performerGenre && {
    name: performerGenre,
    url: `/concert-tickets/genre/${performerGenre}`,
    hidden: performer.category !== 'music',
  };

  const genrePerformers =
    performerGenre &&
    selectPerformersInContextByFilters(state, {
      metro: currentMetro.id,
      category_group: 'concert',
      genre: performerGenre.split('-')[0],
    });

  const isMLBPerformer = performer.category === CATEGORIES.MLB;
  const isMatchupPerformer = performer.displayType === 'performer_vs_performer';

  const reviews = selectReviews(state);

  return {
    categoryConfig: getCategoryBreadcrumbConfig(performer.category),
    collections,
    currentMetro,
    matchupPerformer,
    performer,
    performerContent,
    performerGenreConfig,
    relatedPerformers: relatedPerformersList,
    genrePerformers,
    performerGenre,
    showMLBMarketplaceTag,
    isMLBPerformer,
    metros: selectAllMetros(state),
    closestMetro,
    isMatchupPerformer,
    performerPageData: state.data.fullEvents.eventsData,
    isPerformerInlinePricingMobileExperiment:
      selectIsPerformerInlinePricingMobileExperiment(state),
    searchTestData: selectSearchTestData(state),
    isParkingPage,
    topPickPerformers: selectTopPickPerformers(state),
    isAppReviewsExperiment: selectIsAppReviewsExperiment(state),
    isMLBTeamLogosPerformerExperiment:
      selectIsMLBTeamLogosPerformerExperiment(state),
    reviews,
  };
};

const mapDispatchToProps = {
  fetchAllPerformers,
  fetchFullEvents,
  fetchCollections,
  fetchPerformersInContext,
  showAppSpinner,
  fetchTrendingPerformersByCategoryGroup,
  calculateTopPickPerformers,
};

const loader =
  ({ store: { dispatch, getState } }) =>
  async ({
    params: { slug: originalSlug, matchupSlug: originalMatchupSlug },
    request,
  }) => {
    const query = searchStringToQueryObject(new URL(request.url).search);
    // Butter CMS Editor links matchup pages as a single hyphen-delimited slug.
    const CMSSlugs = originalSlug.split('-');
    let slug = originalSlug;
    let matchupSlug = originalMatchupSlug;
    if (CMSSlugs.length === 2) {
      [slug, matchupSlug] = CMSSlugs;
    }
    await dispatch(fetchMetros());
    const state = getState();
    const currentMetro = selectUserMetro(state) || selectClosestMetro(state);

    const isMatchupPage =
      originalMatchupSlug && originalMatchupSlug !== PARKING_SLUG;

    const contentSlug = getPerformerContentSlug(
      slug,
      matchupSlug,
      isMatchupPage
    );
    const [cmsContent] = await Promise.all([
      dispatch(fetchPerformerContentBySlug(contentSlug)),
      dispatch(fetchPerformerBySlug(slug, currentMetro)),
    ]);

    const selectPerformerBySlug = makeGetSelectPerformerBySlug();
    const performer = selectPerformerBySlug(getState(), slug);
    if (!performer) {
      return null;
    }
    const relatedPerformerIds = cmsContent.result?.fields.related_performers;

    // neccessary in most components since search works with location lat,long
    const currentLocation = currentLocationSelector(state);
    if (!currentLocation) {
      dispatch(updateCurrentLocation(currentMetro.id));
    }

    const promises = [
      dispatch(
        fetchCollections({
          metro: currentMetro.id,
          with_results: true,
          view: COLLECTION_VIEWS.WEB_PERFORMER,
        })
      ),
      fetchUserPurchasesIfIdle(dispatch, getState),
    ];
    const category = performer.category;
    const isMLBPerformer = category === CATEGORIES.MLB;

    if (isMLBPerformer) {
      const performersInContext = await dispatch(
        fetchPerformersInContext({
          metro: currentMetro.id,
          limit: PERFORMERS_IN_CONTEXT_PER_PAGE,
        })
      ).catch((error) => console.error(error));
      dispatch(
        calculateTopPickPerformers(performersInContext?.performers, performer)
      );
    } else {
      const genre = performer.getPerformerGenre();
      if (category === CATEGORIES.MUSIC && genre) {
        const genreFirstWord = genre.split('-')[0];
        await dispatch(
          fetchPerformersInContext({
            category_group: 'concert',
            genre: genreFirstWord,
            limit: 15,
            metro: currentMetro.id,
            skipCache: true,
            sort_by: '-event.trending_score',
          })
        );
      }
    }
    // fetching related performers based on ids from butter or performers by category
    // ids from butter are a string blob
    if (relatedPerformerIds) {
      // trim leading and trailing whitespace and replace all whitespace with commas
      const ids = relatedPerformerIds.trim().replace(/\s+/g, ',');
      const params = {
        id: ids,
        slug,
        isRelatedPerformers: true,
      };
      promises.push(dispatch(fetchPerformers(params, currentMetro)));
    } else {
      promises.push(
        dispatch(fetchPerformersByCategory(category, currentMetro))
      );
    }
    if (isMatchupPage) {
      promises.push(dispatch(fetchPerformerBySlug(matchupSlug, currentMetro)));
    }

    promises.push(
      dispatch(
        fetchReviews({ slug: performer.slug, category: performer.category })
      )
    );

    const tabsConfig = [
      {
        eventType: EVENT_TYPES.NEAR.eventType,
        [EVENT_TYPES.NEAR.eventSlug]: slug,
        metro: currentMetro.id,
      },
      {
        eventType: EVENT_TYPES.ALL.eventType,
        [EVENT_TYPES.ALL.eventSlug]: slug,
      },
    ];

    const perPage = isMatchupPage ? MLB_GAMES_PER_SEASON : 15;

    tabsConfig.forEach((tabConfig) => {
      promises.push(
        dispatch(
          fetchFullEvents({
            ...tabConfig,
            page: query.page || 1,
            per_page:
              tabConfig.eventType === EVENT_TYPES.NEAR.eventType ? 5 : perPage,
            resetState: false,
            zEvents01: 'pub_v0',
            ...getDefaultRange(performer),
          })
        )
      );
    });

    await Promise.all(promises);

    return null;
  };

const PerformerPageWrapper = withRouter(
  withAppContext(connect(mapStateToProps, mapDispatchToProps)(PerformerPage))
);

PerformerPageWrapper.loader = loader;

export default PerformerPageWrapper;
