import { getSearchResults } from 'services/search/search.service';
import { selectPopularCollectionByMetro } from 'store/modules/data/Collections/selectors';
import { makeGetSelectPerformersByCategoryGroup } from 'store/modules/data/Performers/selectors';
import {
  selectUserDetails,
  zSearchSelector,
} from 'store/modules/user/user.selectors';
import { storage } from 'utils/storage';

/* local storage key */
export const GT_VIEWED_EVENTS = 'GT_viewed_events';

export const ACTIONS = {
  SEARCH_REQUEST: 'SEARCH_REQUEST',
  SEARCH_REQUEST_SUCCESS: 'SEARCH_REQUEST_SUCCESS',
  SEARCH_REQUEST_FAILURE: 'SEARCH_REQUEST_FAILURE',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  SEARCH_RECOMMENDATIONS: 'SEARCH_RECOMMENDATIONS',
  SEARCH_RECOMMENDATIONS_SUCCESS: 'SEARCH_RECOMMENDATIONS_SUCCESS',
  SEARCH_RECOMMENDATIONS_FAILURE: 'SEARCH_RECOMMENDATIONS_FAILURE',
  GET_VIEWED_EVENTS_REQUEST: 'GET_VIEWED_EVENTS_REQUEST',
  GET_VIEWED_EVENTS_REQUEST_SUCCESS: 'GET_VIEWED_EVENTS_REQUEST_SUCCESS',
  GET_VIEWED_EVENTS_REQUEST_FAILURE: 'GET_VIEWED_EVENTS_REQUEST_FAILURE',
};

export const search = (q, metro) => (dispatch, getState) => {
  const state = getState();
  const zSearchList = zSearchSelector(state);
  const user = selectUserDetails(state);
  const params = {
    q,
    lat: metro ? metro.location.coordinates.lat : null,
    lon: metro ? metro.location.coordinates.lon : null,
    ...zSearchList,
  };

  const headers = {};

  if (user) {
    headers.user_id = user.id;
  }

  return dispatch({
    types: [
      ACTIONS.SEARCH_REQUEST,
      ACTIONS.SEARCH_REQUEST_SUCCESS,
      ACTIONS.SEARCH_REQUEST_FAILURE,
    ],
    params,
    promise: (httpClient) =>
      getSearchResults(httpClient, { searchParams: params, headers }),
  });
};

/* Clear Search */
export const clearSearch = () => ({
  type: ACTIONS.CLEAR_SEARCH,
});

const selectPopularEvents = (store) => {
  const popularCollection = selectPopularCollectionByMetro(store);
  if (!popularCollection) {
    return null;
  }

  const eventList = (popularCollection && popularCollection.eventsList) || [];
  return eventList.slice(0, 5);
};

const setViewedEvents = (eventIds) => {
  storage(GT_VIEWED_EVENTS).setItem(eventIds);
};

const getViewedEvents = () => {
  return storage(GT_VIEWED_EVENTS).getItem() || [];
};

export const fetchViewedEvents = () => (dispatch) => {
  const gtEvents = getViewedEvents();

  if (gtEvents.length === 0) {
    /* if we don't return random events will be returned */
    return;
  }

  return dispatch({
    types: [
      ACTIONS.GET_VIEWED_EVENTS_REQUEST,
      ACTIONS.GET_VIEWED_EVENTS_REQUEST_SUCCESS,
      ACTIONS.GET_VIEWED_EVENTS_REQUEST_FAILURE,
    ],
    params: { id: gtEvents.join(',') },
    promise: (httpClient) =>
      httpClient.request({
        path: '/v1/events',
        searchParams: { id: gtEvents.join(',') },
      }),
  });
};

export const fetchNearbyEventResults = (metro) => (dispatch, getState) => {
  const store = getState();
  const getNearbyCategory = makeGetSelectPerformersByCategoryGroup()(
    store,
    'sport'
  );

  const popular = selectPopularEvents(store, metro);

  const data = {
    popular,
    sport: getNearbyCategory.slice(0, 2),
  };

  return dispatch({
    types: [
      ACTIONS.SEARCH_RECOMMENDATIONS,
      ACTIONS.SEARCH_RECOMMENDATIONS_SUCCESS,
      ACTIONS.SEARCH_RECOMMENDATIONS_FAILURE,
    ],

    promise: () => Promise.resolve(data),
  });
};

/* VIEWED EVENTS */
export const setNewViewedEvent = (eventId) => {
  const eventsIds = getViewedEvents();

  if (!eventId) {
    return;
  }

  if (eventsIds.length === 0) {
    return setViewedEvents([eventId]);
  }

  if (eventsIds.includes(eventId)) {
    return;
  }

  if (eventsIds.length >= 3) {
    eventsIds.pop();
  }

  setViewedEvents([eventId, ...eventsIds]);
};
