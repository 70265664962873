import React from 'react';
import classNames from 'classnames';
import IconButton from 'ui/IconButton';

import Link from 'components/Link/Link';
import { ChevronIcon, CloseLineIcon } from 'icons';
import { Deal, Disclosure, Listing } from 'models';
import { listingImagePropsSelector } from 'store/modules/resources/resource.selectors';
import colors from 'styles/colors.constants';
import { formatPriceWithComma } from 'utils/number';

import { useListingPrice, usePinDeal } from '../../../hooks';
import PinContainer from '../../../PinContainer';
import GalleryViewTooltip from '../../GalleryViewTooltip';
import SeatMapPinHead from '../SeatMapPinHead';

import SeatMapPinExclusives from './SeatMapPinExclusives';

import pinStyles from '../../../PinContainer/PinContainer.module.scss';

interface TrackingData {
  price: number;
  isAllInPriceActive: boolean;
  listingIndex: number;
  isListingOverlay: boolean;
  dealName: string;
  isListingDetails: boolean;
}

interface SeatMapPinProps {
  isMLBEvent: boolean;
  listing: Listing;
  listingIndex: number;
  onPinClick: (args: { listing: Listing; tracking: TrackingData }) => void;
  onPinHover: (args: { listing: Listing; tracking: TrackingData }) => void;
  isHighlighted: boolean;
  isListingDetails: boolean;
  mapImage: { width: number; height: number };
  venueName: string;
  allDeals: Record<string, Deal>;
  allDisclosures: Record<string, Disclosure>;
  isAllInPriceActive: boolean;
  priceWithPromoApplied: number;
  onListingClose: VoidFunction;
  isPurchaseListing: boolean;
  initiateCheckoutFlow: VoidFunction;
  isListingFlow: boolean;
  observeMapHarmony: boolean;
  isCheckout: boolean;
  isHarmonyPlusOverlay: boolean;
  showGalleryViewTooltip: boolean;
}

const SeatMapPin = ({
  isMLBEvent,
  listing,
  listingIndex,
  onPinClick,
  onPinHover,
  isHighlighted,
  isListingDetails,
  mapImage,
  venueName,
  allDeals,
  allDisclosures,
  isAllInPriceActive,
  priceWithPromoApplied,
  onListingClose,
  isPurchaseListing,
  initiateCheckoutFlow,
  isListingFlow,
  observeMapHarmony,
  isCheckout,
  isHarmonyPlusOverlay,
  showGalleryViewTooltip,
}: SeatMapPinProps) => {
  const price = useListingPrice({
    listing,
    isAllInPriceActive,
    isListingDetails,
    priceWithPromoApplied,
    isPurchaseListing,
  });

  const deal = usePinDeal({ listing, allDeals, isHighlighted });

  const isFocusOnPurchaseListing = isListingDetails && isPurchaseListing;
  const isLargePin = isFocusOnPurchaseListing && !isHighlighted;
  const isListingOverlay = isHarmonyPlusOverlay && isListingFlow;
  const trackingData: TrackingData = {
    price,
    isAllInPriceActive,
    listingIndex,
    isListingOverlay,
    dealName: deal?.slug || '',
    isListingDetails,
  };

  const handleClick = () => {
    onPinClick({ listing, tracking: trackingData });
  };

  const conditionalClassNames: Parameters<typeof classNames>[0] = {
    [pinStyles['zindex-scale-01']]:
      !listing.dealType && !isLargePin && !isHighlighted,
    [pinStyles['zindex-scale-02']]:
      listing.dealType === 'best' || listing.dealType === 'cheapest',
    [pinStyles['zindex-scale-03']]:
      listing.dealType === 'zone' || listing.dealType === 'flash',
    [pinStyles['zindex-scale-04']]:
      isHighlighted || listing.dealType === 'super',
    [pinStyles['zindex-scale-05']]: isLargePin,
  };

  function getBigPinButton() {
    if (isListingOverlay) {
      return (
        <IconButton
          onClick={initiateCheckoutFlow}
          icon={
            <ChevronIcon
              width="12"
              height="12"
              direction="right"
              color={colors.white}
            />
          }
        />
      );
    }

    if (isCheckout || (isListingFlow && !isHarmonyPlusOverlay)) {
      return (
        <IconButton
          onClick={onListingClose}
          icon={<CloseLineIcon width="16" height="16" fill={colors.white} />}
        />
      );
    }
  }

  const dealInfo = listing.dealType && allDeals[listing.dealType];

  return (
    <PinContainer
      listing={listing}
      mapImage={mapImage}
      isLargePin={isLargePin}
      pinContainerClassNames={classNames(conditionalClassNames)}
    >
      <Link
        data-map-pin
        onClick={isCheckout || isPurchaseListing ? undefined : handleClick}
        rel="nofollow"
      >
        <SeatMapPinHead listing={listing} observeMapHarmony={observeMapHarmony}>
          {isLargePin ? (
            <SeatMapPinExclusives
              isSelected={isHighlighted}
              text={
                isListingFlow ? `$${formatPriceWithComma(price)}` : undefined
              }
              bigPinConfig={{
                imageProps: listingImagePropsSelector(listing, venueName),
                button: getBigPinButton(),
              }}
              deal={dealInfo}
            />
          ) : (
            <GalleryViewTooltip
              disableTooltip={!showGalleryViewTooltip}
              listing={listing}
              isAllInPrice={isAllInPriceActive}
              allDisclosures={allDisclosures}
              isMLBEvent={isMLBEvent}
              onHover={() => onPinHover({ listing, tracking: trackingData })}
            >
              <SeatMapPinExclusives
                isSelected={isHighlighted}
                text={`$${formatPriceWithComma(price)}`}
                deal={dealInfo}
              />
            </GalleryViewTooltip>
          )}
        </SeatMapPinHead>
      </Link>
    </PinContainer>
  );
};

export default React.memo(SeatMapPin);
