import React from 'react';

import { ChevronIcon } from 'icons';
import colorsConstants from 'styles/colors.constants';

import styles from './ArrowSet.module.scss';

type Props = {
  onNext: () => void;
  onPrevious: () => void;
  canScrollNext: boolean;
  canScrollPrev: boolean;
};

export default function ArrowSet({
  onNext,
  onPrevious,
  canScrollNext,
  canScrollPrev,
}: Props) {
  return (
    <div className={styles['main-container']}>
      <button
        onClick={() => onPrevious()}
        className={styles.button}
        data-testid="previous-button"
        disabled={!canScrollPrev}
      >
        <ChevronIcon
          width="16"
          height="16"
          color={
            canScrollPrev ? colorsConstants.white : colorsConstants.gray243
          }
        />
      </button>
      <button
        onClick={() => onNext()}
        className={styles.button}
        data-testid="next-button"
        disabled={!canScrollNext}
      >
        <ChevronIcon
          direction="right"
          width="16"
          height="16"
          color={
            canScrollNext ? colorsConstants.white : colorsConstants.gray243
          }
        />
      </button>
    </div>
  );
}
