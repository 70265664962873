import React from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import CloseLineIcon from 'icons/CloseLineIcon';
import colors from 'styles/colors.constants';

import styles from './GalleryViewCard.module.scss';

interface GalleryViewCardProps {
  children: React.ReactNode;
  onClose: VoidFunction;
  isVisible: boolean;
  isAnimating: boolean;
  isGalleryViewV3Experiment?: boolean;
}

const GalleryViewCard = ({
  children,
  onClose,
  isVisible,
  isAnimating,
  isGalleryViewV3Experiment = false,
}: GalleryViewCardProps) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <div
          className={classNames({
            [styles['floating-view-card']]: isGalleryViewV3Experiment,
          })}
        >
          <motion.div
            key="gallery-view-card"
            className={classNames(styles.container, {
              [styles['is-animating']]: isAnimating,
            })}
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: '0%' }}
            exit={{ opacity: 0, y: '100%' }}
          >
            <button className={styles['close-button']} onClick={onClose}>
              <span className={styles['close-button-inner']}>
                <CloseLineIcon fill={colors.black} width="16" height="16" />
              </span>
            </button>
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default GalleryViewCard;
