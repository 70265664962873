import { createAction } from '@reduxjs/toolkit';
import type { AppDispatch, AsyncThunk } from 'store';

import { getReviews, GetReviewsParams } from 'services/reviews/reviews.service';
import { GetReviewsResponse } from 'types';

export const getReviewsRequest = createAction('Reviews/GetReviewsRequest');
export const getReviewsSuccess = createAction<GetReviewsResponse>(
  'Reviews/GetReviewsSuccess'
);
export const getReviewsFailure = createAction<unknown>(
  'Reviews/GetReviewsFailure'
);

export const fetchReviews =
  (params: GetReviewsParams): AsyncThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(getReviewsRequest());

    try {
      const result = await getReviews(params);
      if (result) {
        dispatch(getReviewsSuccess(result));
      }
    } catch (error) {
      dispatch(getReviewsFailure(error));
    }
  };
