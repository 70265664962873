import React from 'react';

import {
  CalendarIcon,
  DiscountIcon,
  GemIcon,
  ShieldIcon,
  TicketSetIcon,
  VerifiedIcon,
} from 'icons';
import { Disclosure, FullEvent } from 'models';
import Listing from 'models/Listing';
import { hasHighlightedDisclosure } from 'store/modules/data/Listings/utils';
import colors from 'styles/colors.constants';
import { Purchase, UserModel } from 'types';
import { isSuperBowl } from 'utils/superBowl';

import { URGENCY_MESSAGING_THRESHOLD } from '../constants';

import AffirmBlock from './AffirmBlock/AffirmBlock';
import DetailsBlock from './DetailsBlock/DetailsBlock';
import DetailsStackItem from './DetailsStackItem/DetailsStackItem';
import PriceBlock from './PriceBlock/PriceBlock';
import PriceGuaranteeBlock from './PriceGuaranteeBlock/PriceGuaranteeBlock';
import SeatPerksBlock from './SeatPerksBlock/SeatPerksBlock';
import TicketsBlock from './TicketsBlock/TicketsBlock';
import UrgencyMessageBlock from './UrgencyMessageBlock/UrgencyMessageBlock';
import VerifiedBlock from './VerifiedBlock/VerifiedBlock';

import styles from './DetailsStack.module.scss';

type Props = {
  maxLotSize: number;
  continueButton: React.ReactNode;
  onPriceBreakdownInfoClick: () => void;
  showFaceValuePricing: boolean;
  extendedPurchase?: { seatFee: number; salesTax: number; totalPrice: number };
  showAllInPricing: boolean;
  currencyPrefix: string;
  listing: Listing;
  fullEvent: FullEvent;
  allDisclosures: { [key: string]: Disclosure };
  isUpdatingPricing: boolean;
  priceWithPromoApplied: number;
  onAffirmBannerClick: () => void;
  seatRange?: string;
  ticketQuantities?: number[];
  onFocusQuantityPicker?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onChangeQuantity: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  user?: UserModel;
  purchases: Record<string, Purchase>;
  handleSuperbowlModal: () => void;
  topValueBanner?: React.ReactNode;
};

export default function DetailsStack({
  continueButton,
  onPriceBreakdownInfoClick,
  showFaceValuePricing,
  showAllInPricing,
  currencyPrefix,
  listing,
  isUpdatingPricing,
  priceWithPromoApplied,
  extendedPurchase,
  onAffirmBannerClick,
  seatRange,
  ticketQuantities = [],
  onFocusQuantityPicker,
  onChangeQuantity,
  maxLotSize,
  fullEvent,
  allDisclosures,
  user,
  purchases,
  handleSuperbowlModal,
  topValueBanner,
}: Props) {
  const isUrgencyMessagingEligible =
    maxLotSize > 0 && maxLotSize <= URGENCY_MESSAGING_THRESHOLD;

  const totalPrice =
    priceWithPromoApplied > 0
      ? priceWithPromoApplied
      : extendedPurchase?.totalPrice || 0;

  const showAffirmBlock =
    !isUpdatingPricing && totalPrice >= 50 && totalPrice < 20000;

  const ticketsOwned = Object.values(purchases).reduce((total, purchase) => {
    if (purchase.event_id === fullEvent.id) {
      return total + purchase.tickets.length;
    }
    return total;
  }, 0);

  return (
    <div className={styles['stacked-card-body']}>
      {isUrgencyMessagingEligible && (
        <UrgencyMessageBlock quantity={maxLotSize} />
      )}
      {topValueBanner}
      <DetailsStackItem
        icon={<CalendarIcon width="100%" />}
        body={
          <DetailsBlock
            fullEvent={fullEvent}
            listing={listing}
            showSeats={!seatRange}
            allDisclosures={allDisclosures}
            handleSuperbowlModal={handleSuperbowlModal}
          />
        }
      />
      <DetailsStackItem
        icon={<DiscountIcon width="100%" />}
        testId="price-block"
        body={
          !isUpdatingPricing && (
            <PriceBlock
              onPriceBreakdownInfoClick={onPriceBreakdownInfoClick}
              showFaceValuePricing={showFaceValuePricing}
              extendedPurchase={extendedPurchase}
              showAllInPricing={showAllInPricing}
              priceWithPromoApplied={priceWithPromoApplied}
              currencyPrefix={currencyPrefix}
              listing={listing}
            />
          )
        }
      />
      {showAffirmBlock && (
        <DetailsStackItem
          body={
            <AffirmBlock price={totalPrice} onClick={onAffirmBannerClick} />
          }
        />
      )}
      <DetailsStackItem
        icon={<TicketSetIcon width="100%" />}
        body={
          <TicketsBlock
            seatRange={seatRange}
            quantity={listing.currentSeatCount}
            ticketQuantities={ticketQuantities}
            onFocusQuantityPicker={onFocusQuantityPicker}
            onChangeQuantity={onChangeQuantity}
            isGeneralAdmission={listing.isGeneralAdmission}
          />
        }
      />
      {isSuperBowl(fullEvent.id) ? (
        <DetailsStackItem
          icon={<VerifiedIcon stroke={colors.black} />}
          body={<VerifiedBlock />}
        />
      ) : (
        <DetailsStackItem
          icon={<ShieldIcon fill={colors.gray200} />}
          body={<PriceGuaranteeBlock />}
        />
      )}
      {hasHighlightedDisclosure(listing.disclosures, allDisclosures) && (
        <DetailsStackItem
          icon={<GemIcon width="100%" />}
          body={
            <SeatPerksBlock
              allDisclosures={allDisclosures}
              disclosures={listing.disclosures}
            />
          }
        />
      )}
      {ticketsOwned > 0 && (
        <div className={styles['tickets-owned']}>
          You already purchased {ticketsOwned} ticket
          {ticketsOwned > 1 ? 's' : ''} to this event.
        </div>
      )}
      <div className={styles['action-button-container']}>{continueButton}</div>
      {user && <div className={styles.email}>{user.email}</div>}
    </div>
  );
}
