import React from 'react';

import SimpleReviewCard from 'components/SimpleReviewCard/SimpleReviewCard';
import { Review } from 'types';

import { REVIEWS } from '../../constants';

import styles from './Reviews.module.scss';

const Reviews = () => (
  <div className={styles.reviews}>
    {REVIEWS.map((review: Review) => (
      <SimpleReviewCard review={review} page="homepage" key={review.id} />
    ))}
  </div>
);

export default Reviews;
