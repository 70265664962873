import { createSelector } from 'reselect';

import {
  NBA_CATEGORY,
  NON_NBA_TEAMS,
} from 'components/Headers/HeaderDropdown/components/CategoriesDropdown/constants';
import Performer from 'models/Performer';
import { CATEGORIES } from 'store/modules/categories/category.helpers';

/* Returns Plain Objects : These should not be called from the UI (Components) */
const _performersState = (state) => state.data.performers;

const _performerCategory = (state, props) => props.category?.toLowerCase();

const _performerStateByCategory = createSelector(
  _performersState,
  _performerCategory,
  (ps, pg) => ps[pg]
);

const _filterPerformerStateByCategory = createSelector(
  _performerStateByCategory,
  _performerCategory,
  (pstate, cat) =>
    cat === NBA_CATEGORY
      ? pstate?.filter((team) => !NON_NBA_TEAMS.includes(team.slug))
      : pstate
);
const emptyArray = [];
function _toPerformerClass(performers = emptyArray) {
  return performers.map((performer) => new Performer(performer));
}

export const selectRelatedPerformersBySlug = createSelector(
  (state, slug) => state.data.performers[slug]?.relatedPerformers,
  (relatedPerformers) => _toPerformerClass(relatedPerformers)
);

export const makeSelectFilteredHeaderPerformersByCategory = () => {
  return createSelector(_filterPerformerStateByCategory, (performersState) =>
    _toPerformerClass(performersState)
  );
};

export const makeGetSelectPerformersByCategory = () => {
  return createSelector(
    (state, category) => state.data.performers[category],
    (performersByCategory) => _toPerformerClass(performersByCategory)
  );
};

export const makeGetSelectPerformerBySlug = () => {
  return createSelector(
    _performersState,
    (state, slug) => slug,
    (performersState, slug) => {
      const performer = performersState[slug];

      return performer ? new Performer(performer) : null;
    }
  );
};

export const makeGetSelectPerformersByCategoryGroup = () => {
  return createSelector(
    _performersState,
    (state, categoryGrp) => categoryGrp,
    (performersState, categoryGrp) => {
      return _toPerformerClass(performersState[categoryGrp]);
    }
  );
};

export const isFetchingPerformers = createSelector(
  _performersState,
  (performersState) => performersState.isFetchingPerformers
);

export const allPerformersFetched = createSelector(
  _performersState,
  (performersState) => performersState.allPerformersFetched
);

export const selectTopPickPerformers = createSelector(
  _performersState,
  (performersState) => _toPerformerClass(performersState.topPickPerformers)
);

export const getLocalPerformers = createSelector(
  _performersState,
  (state, props) => props,
  (performersState, props) => {
    const { localPerformers } = performersState;
    const { metro, category } = props;

    return localPerformers?.[metro]?.[category];
  }
);

export const selectAllPerformersInCategories = createSelector(
  _performersState,
  (performersState) => {
    return [
      {
        name: CATEGORIES.NFL,
        performers: _toPerformerClass(performersState.nfl),
      },
      {
        name: CATEGORIES.NBA,
        performers: _toPerformerClass(
          performersState.nba?.filter(
            (team) => !NON_NBA_TEAMS.includes(team.slug)
          )
        ),
      },
      {
        name: CATEGORIES.MLB,
        performers: _toPerformerClass(performersState.mlb),
      },
      {
        name: CATEGORIES.NHL,
        performers: _toPerformerClass(performersState.nhl),
      },
      {
        name: CATEGORIES.MLS,
        performers: _toPerformerClass(performersState.mls),
      },
      {
        name: CATEGORIES.MUSIC,
        performers: _toPerformerClass(performersState.music),
      },
      {
        name: CATEGORIES.THEATER,
        performers: _toPerformerClass(performersState.theater),
      },
      {
        name: CATEGORIES.COMEDY,
        performers: _toPerformerClass(performersState.comedy),
      },
    ];
  }
);
