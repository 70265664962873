import React from 'react';

import { CategoryClickTracker, Click } from 'analytics';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import { categories } from 'pages/HomeV1/constants';

import styles from './Categories.module.scss';

const Categories = () => (
  <div className={styles['cards-container']}>
    {categories.map((category) => (
      <Link
        className={styles['category-card']}
        key={category.name.toLowerCase().replace(' ', '-')}
        to={category.url}
        clickTracker={new CategoryClickTracker(category)
          .sourcePageType(Click.SOURCE_PAGE_TYPES.HOMEPAGE())
          .interaction(Click.INTERACTIONS.TILE(), {
            category_name: category.name,
          })}
      >
        <Image src={category.imagePath} className={styles['category-image']} />
        <span className={styles['category-card-title']}>{category.name}</span>
      </Link>
    ))}
  </div>
);

export default Categories;
