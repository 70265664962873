import { HttpClient } from 'services/HttpClient/HttpClient';
import { GetListingsV2RequestParams, GetListingsV2Response } from 'types';

export type GetListingsParams = Omit<
  GetListingsV2RequestParams,
  `zListings${string}`
> & {
  quartile?: -1 | 1 | 2 | 3 | 4;
};

export type ZListingsParams = Omit<
  GetListingsV2RequestParams,
  keyof GetListingsParams
>;

export function getListings(
  mobileApi: HttpClient,
  { eventId, quartile, ...params }: GetListingsParams,
  zListings: ZListingsParams
) {
  if (!eventId) {
    throw new Error('eventId is required by getListings');
  }

  const searchParams = new URLSearchParams();

  /**
   * For reasons unknown, -1 was chosen as the default "unset" client value for
   * this parameter. This is not a valid value for the request parameter, so
   * anything other than 1, 2, 3, or 4 will be ignored.
   */
  if (quartile && [1, 2, 3, 4].includes(quartile)) {
    searchParams.set('quartile', quartile.toString());
  }

  let paramKey: keyof typeof params;
  for (paramKey in params) {
    const value = params[paramKey];
    if (value !== undefined) {
      searchParams.set(paramKey, value.toString());
    }
  }

  let zListingKey: keyof ZListingsParams;
  for (zListingKey in zListings) {
    const value = zListings[zListingKey];
    if (value !== undefined) {
      searchParams.set(zListingKey, value.toString());
    }
  }

  return mobileApi.request<GetListingsV2Response>({
    path: `/v2/listings/${eventId}`,
    searchParams,
  });
}
