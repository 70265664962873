import { Action } from '@reduxjs/toolkit';

import { Review } from 'types';

import { getReviewsSuccess } from './actions';

export interface ReviewsState {
  reviews: Review[];
}

const initialState: ReviewsState = {
  reviews: [],
};

export default function reducer(
  state: ReviewsState = initialState,
  action: Action
): ReviewsState {
  if (getReviewsSuccess.match(action)) {
    return {
      ...state,
      reviews: action.payload.reviews,
    };
  }

  return state;
}
