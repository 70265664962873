import React from 'react';

import SearchBox from 'components/Search/SearchBox/SearchBox';
import MetroPicker from 'components/SelectorModals/MetroPicker/MetroPicker';
import { device, useMediaQuery } from 'hooks';
import { Metro } from 'types';

import styles from './SearchHeroV1.module.scss';

interface SearchHeroProps {
  currentMetro: Metro;
  handleHeroSearchboxFocus?: () => void;
}

const SearchHeroV1 = ({
  currentMetro,
  handleHeroSearchboxFocus,
}: SearchHeroProps) => {
  const isMobileMd = useMediaQuery(device.down.md);
  const isMobileSm = useMediaQuery(device.down.sm);

  return (
    <div className={styles['search-hero-container']}>
      <div className={styles['search-hero-header']}>
        <span className={styles['search-hero-title']}>
          Discover incredible live experiences.
        </span>
        <span className={styles['search-hero-subtitle']}>
          Score the best deals on great seats with Gametime.
        </span>
      </div>
      <div className={styles['search-hero-box']}>
        <SearchBox
          onFocus={isMobileMd ? handleHeroSearchboxFocus : undefined}
          isSearchHero
          isHomepage
        />
      </div>
      {isMobileSm && <MetroPicker currentMetro={currentMetro} />}
    </div>
  );
};

export default SearchHeroV1;
