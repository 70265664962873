import { HttpClient } from 'services/HttpClient/HttpClient';
import { GetPerformersResponse } from 'types';

export interface GetPerformersParams {
  category?: string;
  page?: number;
  per_page?: number;
}

export const getPerformers = (
  mobileApi: HttpClient,
  params: GetPerformersParams
): Promise<GetPerformersResponse | null> => {
  const searchParams = new URLSearchParams();

  if (params.category) {
    searchParams.append('category', params.category);
  }

  if (params.page) {
    searchParams.append('page', params.page.toString());
  }

  if (params.per_page) {
    searchParams.append('per_page', params.per_page.toString());
  }

  return mobileApi.request<GetPerformersResponse>({
    path: '/v1/performers',
    method: 'GET',
    searchParams,
  });
};
