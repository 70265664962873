import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import _merge from 'lodash/merge';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import config from 'config/config';
import { ArrowIcon } from 'icons';
import { useAppDispatch } from 'store';
import { sendAppDownloadSMS } from 'store/modules/user/actions';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import colors from 'styles/colors.constants';
import { getSourcePageType } from 'utils/appLinkUtils';
import { denormalizePhoneNumber } from 'utils/phone';

import AppStoreLinks from '../AppStoreLinks/AppStoreLinks';

import styles from './DownloadApp.module.scss';

const DESKTOP_PRODUCT_IMAGE_URL =
  'https://assets.gametime.co/discover/product-shots.png?auto=webp';

const phoneRegex = /(\+1)?\s?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})/g;

const DownloadApp = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [placeholder, setPlaceholder] = useState('Phone Number');
  const dispatch = useAppDispatch();
  const location = useLocation();

  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleDownload = (event) => {
    event.preventDefault();
    setPhoneNumber('');
    if (!phoneNumber.match(phoneRegex)) {
      setErrorMessage('Please enter a valid phone number');
      setIsSubmitting(false);
      return;
    }
    setPlaceholder('Sending message...');
    setIsSubmitting(true);

    const tracker = new ClickTracker();
    tracker
      .sourcePageType(Click.SOURCE_PAGE_TYPES.HOMEPAGE())
      .interaction(Click.INTERACTIONS.DOWNLOAD_APP());
    const trackProperties = tracker.json();
    analytics?.track(new Click(_merge({}, clickContext, trackProperties)));

    const sourcePageType = getSourcePageType(location.pathname);
    const link = config.DEEP_LINKS[sourcePageType];

    dispatch(updateUserPreference({ phone: phoneNumber }));
    dispatch(sendAppDownloadSMS(denormalizePhoneNumber(phoneNumber), link))
      .then(() => {
        setIsSubmitting(false);
        setPlaceholder('A message has been sent to your phone.');
      })
      .catch(() => {
        setErrorMessage('Please try again, something went wrong.'); // update to err state on input
      });
  };

  return (
    <div className={styles['download-container']}>
      <div className={styles['download-card']}>
        <div className={styles['download-content']}>
          <div className={styles['download-title-container']}>
            <span className={styles['download-title']}>
              Get the Gametime App
            </span>
            <span className={styles['download-subtitle']}>
              Save serious cash when you find incredible deals on tickets to
              Sports, Music, Shows, and more!
            </span>
          </div>
          <form onSubmit={handleDownload} className={styles['download-form']}>
            <div className={styles['download-input-form-wrapper']}>
              <div className={styles['download-email-label-container']}>
                <label className={styles['download-input-label']} htmlFor="tel">
                  Phone Number
                </label>
                {errorMessage && (
                  <span className={styles['download-input-error-message']}>
                    {errorMessage}
                  </span>
                )}
              </div>
              <div className={styles['download-input-container']}>
                <input
                  onChange={handlePhoneNumberChange}
                  className={styles['download-input']}
                  type="tel"
                  value={phoneNumber}
                  disabled={isSubmitting}
                  placeholder={placeholder}
                  aria-label="Phone number input"
                  aria-invalid={!!errorMessage}
                  aria-describedby="phone-error"
                />
                <button
                  type="submit"
                  className={styles['download-button']}
                  disabled={isSubmitting}
                >
                  <ArrowIcon fill={colors.gametimeGreenLight} />
                </button>
              </div>
            </div>
          </form>
          <AppStoreLinks />
        </div>
        <div className={styles['download-image']}>
          <img src={DESKTOP_PRODUCT_IMAGE_URL} loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
