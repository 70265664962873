import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAppContext } from 'contexts/AppContext';
import withRouter from 'hoc/withRouter';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import {
  generateBreadcrumbSchema,
  getCategoryBreadcrumbConfig,
} from 'components/Breadcrumbs/breadcrumb.helpers';
import Collection from 'components/Collection/Collection';
import ContentSection from 'components/ContentSection/ContentSection';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import JsonLD from 'components/JsonLD/JsonLD';
import NoEventsCard from 'components/NoEventsCard/NoEventsCard';
import PerformerLinks from 'components/PerformerLinks/PerformerLinks';
import ReactSlickCarousel from 'components/ReactSlickCarousel/ReactSlickCarousel';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import SimpleHeroSection from 'components/SimpleHeroSection/SimpleHeroSection';
import { PerformerContent } from 'models';
import {
  CATEGORY_GROUP_CONFIG,
  getEventTypeString,
} from 'modules/CategoryGroups/CategoryGroups.helpers';
import {
  COLLECTION_HEADINGS,
  COLLECTION_SLUGS,
  COLLECTION_VIEWS,
} from 'pages/Collection/constants';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import NotFound from 'pages/NotFound/NotFound';
import { PERFORMERS_IN_CONTEXT_PER_PAGE } from 'pages/Performer/Performer';
import {
  currentLocationSelector,
  updateCurrentLocation,
} from 'store/modules/app/app';
import {
  CATEGORIES,
  CATEGORY_URLS,
  getCategoryImgUrl,
  isInvalidCategoryPageSelector,
  MAJOR_LEAGUE_SPORTS_PERFORMERS,
  urlCategorySelector,
  urlConfigSelector,
} from 'store/modules/categories/category.helpers';
import { makeGetPerformerStaticContentByCategory } from 'store/modules/content/categories/selectors';
import { fetchPerformerContentBySlug } from 'store/modules/content/performers/actions';
import { selectPerformerContentBySlug } from 'store/modules/content/performers/selectors';
import { fetchCollections } from 'store/modules/data/Collections/actions';
import { selectCollectionsByFilters } from 'store/modules/data/Collections/selectors';
import {
  fetchAllPerformers,
  fetchTrendingPerformersByCategoryGroup,
} from 'store/modules/data/Performers/actions';
import { makeGetSelectPerformersByCategory } from 'store/modules/data/Performers/selectors';
import { fetchPerformersInContext } from 'store/modules/data/PerformersInContext/actions';
import {
  selectPerformersInContextByCategory,
  selectPerformersInContextByFilters,
} from 'store/modules/data/PerformersInContext/selectors';
import {
  generatePerformerLinks,
  generateTopPicksPerformers,
} from 'store/modules/performers/performers.helpers';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectClosestMetro,
  selectMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { isObjectEmpty } from 'utils/objects';
import { titleCase } from 'utils/strings/title-case';

import AllEventsSection from './components/AllEventsSection';
import SimpleCollectionCard from './components/SimpleCollectionCard';
import TextPerformerGroupings from './components/TextPerformerGroupings';
import { getPerformersByDivision } from './components/utils';
import {
  DIVISION_NAMES,
  DIVISIONS_BY_CATEGORY,
  DIVISIONS_ORDERED_KEYS_BY_CATEGORY,
} from './constants';
import NonSportsPerformers, {
  ALL_EVENTS_TITLE,
  INITIAL_PERFORMER_LIMIT,
} from './NonSportsPerformers';
import SportsPerformers from './SportsPerformers';

import styles from './CategoryPerformers.module.scss';

// NOTE: Very incomplete.
const CATEGORY_TO_CATEGORY_GROUP = {
  [CATEGORIES.MUSIC]: CATEGORY_GROUP_CONFIG.CONCERT.id,
  [CATEGORIES.THEATER]: CATEGORY_GROUP_CONFIG.THEATER.id,
};
@TrackPageView(({ category }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.CATEGORY(category),
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.CATEGORY(),
}))
@withAnalyticsContext
class CategoryPerformers extends Component {
  static propTypes = {
    performersInContextByCategory: PropTypes.array,
    currentMetro: PropTypes.object,
    category: PropTypes.string.isRequired,
    isNonSportsPage: PropTypes.bool.isRequired,
    nonSportsPerformerCollections: PropTypes.array,
    nonSportsPerformersInContext: PropTypes.array,
    musicVenueCollection: PropTypes.object,
    fetchCollections: PropTypes.func.isRequired,
    fetchPerformersInContext: PropTypes.func.isRequired,
    performerContent: PropTypes.instanceOf(PerformerContent),
    nonSportsContent: PropTypes.instanceOf(PerformerContent),
    params: PropTypes.object.isRequired,
    isMLBCategory: PropTypes.bool,
    fetchAllPerformers: PropTypes.func.isRequired,
    fetchTrendingPerformersByCategoryGroup: PropTypes.func.isRequired,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    dealsCollections: PropTypes.array,
    performersInContextByMetro: PropTypes.array,
    proximityCollection: PropTypes.array,
    relatedPerformers: PropTypes.array,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    clickContext: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAllLoading: false,
      paginationButtonClicked: false,
      showPaginationButton: false,
      topPickPerformers: [],
    };

    this.onNonSportsShowAllClick = this.onNonSportsShowAllClick.bind(this);
    this.renderNonSportsPerformers = this.renderNonSportsPerformers.bind(this);
  }

  componentDidMount() {
    this.setState({
      topPickPerformers: generateTopPicksPerformers(
        this.props.performersInContextByMetro
      ),
    });
  }

  componentDidUpdate(prevProps) {
    const { isNonSportsPage, nonSportsPerformersInContext } = this.props;
    const { paginationButtonClicked, showPaginationButton } = this.state;

    if (
      isNonSportsPage &&
      !showPaginationButton &&
      nonSportsPerformersInContext &&
      nonSportsPerformersInContext.length === INITIAL_PERFORMER_LIMIT &&
      !paginationButtonClicked
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showPaginationButton: true });
    }

    this.processIncomingProps(prevProps);
  }

  onNonSportsShowAllClick() {
    const {
      currentMetro: { id },
      category,
      analyticsContext,
    } = this.props;

    const { clickContext } = this.props;

    const tracker = new ClickTracker().interaction(
      Click.INTERACTIONS.SEE_MORE(),
      {
        category,
        title: ALL_EVENTS_TITLE,
      }
    );

    analyticsContext.track(new Click(_merge({}, clickContext, tracker.json())));

    this.setState({ showAllLoading: true }, () =>
      this.props
        .fetchPerformersInContext({
          category_group: CATEGORY_TO_CATEGORY_GROUP[category],
          metro: id,
          skipCache: true,
        })
        .then(() =>
          this.setState({
            showAllLoading: false,
            paginationButtonClicked: true,
            showPaginationButton: false,
          })
        )
    );
  }

  getCategoryTitle() {
    const {
      performerContent,
      category,
      params: { categoryId },
    } = this.props;
    const urlConfig = urlConfigSelector(categoryId);
    if (category === CATEGORIES.CFB) {
      return 'College Football Tickets';
    }
    return performerContent && performerContent.metaTitle
      ? performerContent.metaTitle
      : _get(urlConfig, 'title', `${category.toUpperCase()}`);
  }

  processIncomingProps(prevProps) {
    const {
      currentMetro: oldMetro,
      params: { categoryId },
    } = prevProps;
    const category = urlCategorySelector(categoryId);

    const { isNonSportsPage, currentMetro, analyticsContext } = this.props;
    if (isNonSportsPage && oldMetro.id !== currentMetro.id) {
      this.setState({
        paginationButtonClicked: false,
        showPaginationButton: false,
      });
      analyticsContext.track(
        new View({
          [TRACK.PAGE_TYPE]: View.PAGE_TYPES.CATEGORY(category),
        })
      );

      this.props.fetchAllPerformers(currentMetro);
      this.props.fetchTrendingPerformersByCategoryGroup(currentMetro.id);

      this.props.fetchPerformersInContext({
        category_group: CATEGORY_TO_CATEGORY_GROUP[category],
        limit: INITIAL_PERFORMER_LIMIT,
        metro: currentMetro.id,
        skipCache: true,
      });

      if (category === CATEGORIES.MUSIC) {
        this.props.fetchCollections({
          view: COLLECTION_VIEWS.MUSIC_WEB,
          metro: currentMetro.id,
        });
        this.props.fetchCollections({
          view: COLLECTION_VIEWS.MUSIC,
          metro: currentMetro.id,
          slug: COLLECTION_SLUGS.VENUE_SCHEDULES,
        });
      } else if (category === CATEGORIES.THEATER) {
        this.props.fetchCollections({
          view: COLLECTION_VIEWS.THEATER_WEB,
          metro: currentMetro.id,
        });
      }
    }

    const { category: oldCategory } = this.props;
    if (isNonSportsPage && oldCategory !== category) {
      this.setState({
        paginationButtonClicked: false,
        showPaginationButton: false,
      });
      analyticsContext.track(
        new View({
          [TRACK.PAGE_TYPE]: View.PAGE_TYPES.CATEGORY(category),
        })
      );
    }
  }

  renderNonSportsPerformers() {
    const {
      currentMetro,
      nonSportsPerformerCollections,
      nonSportsPerformersInContext,
      musicVenueCollection,
      analyticsContext,
    } = this.props;

    const { showAllLoading, showPaginationButton } = this.state;

    return (
      <NonSportsPerformers
        analytics={analyticsContext}
        clickContext={this.props.clickContext}
        currentMetro={currentMetro}
        performerCollections={nonSportsPerformerCollections}
        performersInContext={nonSportsPerformersInContext}
        showPaginationButton={showPaginationButton}
        showAllLoading={showAllLoading}
        onShowAllClick={this.onNonSportsShowAllClick}
        venueCollection={musicVenueCollection}
      />
    );
  }

  renderSeoContent() {
    const { performerContent, nonSportsContent, category } = this.props;

    let fallbackHeadline = `${_capitalize(category)} tickets`;

    if (CATEGORY_URLS[category]) {
      fallbackHeadline = /tickets$/i.test(CATEGORY_URLS[category].title)
        ? `${CATEGORY_URLS[category].title}`
        : `${CATEGORY_URLS[category].title} tickets`;
    }

    const hl = performerContent?.metaTitle?.length
      ? performerContent.metaTitle
      : fallbackHeadline;

    return (
      <div className={styles['seo-container']}>
        <>
          {performerContent && performerContent.isActive && (
            <ContentSection headline={hl} content={performerContent} />
          )}
        </>
        <>
          {nonSportsContent && nonSportsContent.isActive && (
            <ContentSection headline={hl} content={nonSportsContent} />
          )}
        </>
      </div>
    );
  }

  renderCollections() {
    const { currentMetro, dealsCollections, analyticsContext } = this.props;

    return (
      <div className={styles['collections-container']}>
        {dealsCollections.map((collection, index) => (
          <div
            key={collection.id}
            className={styles['category-performers-carousel']}
          >
            <Collection
              collection={collection}
              collectionTitle={collection.title}
              currentMetro={currentMetro}
              sectionIndex={index}
              analytics={analyticsContext}
              clickContext={this.props.clickContext}
            />
          </div>
        ))}
      </div>
    );
  }

  renderOtherNCAASportsPerformers() {
    const {
      category,
      clickContext,
      currentMetro,
      performersInContextByCategory,
      proximityCollection,
      appContext,
    } = this.props;
    if (!performersInContextByCategory.length) return null;

    if (category === CATEGORIES.CFB && proximityCollection.length > 1) {
      const performers = performersInContextByCategory
        .filter((pic) => DIVISIONS_BY_CATEGORY[category][pic.performer.slug])
        .map((pic) => pic.performer);

      const performersByDivision = getPerformersByDivision(
        performers,
        category
      );

      const groupsHeading = 'Top College Football Teams';
      const linksByDivisionCategory = DIVISIONS_ORDERED_KEYS_BY_CATEGORY[
        category
      ].map((key) => ({
        title: DIVISION_NAMES[key],
        links: generatePerformerLinks(performersByDivision[key]),
      }));
      const [eventCollection, performerCollection] = proximityCollection;

      return (
        <div className={styles['other-ncaa-container']}>
          <ReactSlickCarousel
            key={performerCollection.id}
            title="Nearby Teams"
            clickContext={clickContext}
            spacing={16}
            slideWidth={appContext.state.isMobile ? 218 : 200}
            items={performerCollection.performers}
            renderItem={(performer) => (
              <SimpleCollectionCard key={performer.id} source={performer} />
            )}
            renderHeader={() => <SectionHeader title="Nearby Teams" />}
          />
          <div>
            <Collection
              collection={eventCollection}
              collectionTitle="College Football Events Near You"
              currentMetro={currentMetro}
              sectionIndex={1}
              hideSeeAllButton
            />
          </div>
          );
          <TextPerformerGroupings
            heading={groupsHeading}
            linkGroups={linksByDivisionCategory}
          />
        </div>
      );
    }

    return (
      <div className={styles['other-ncaa-container']}>
        <AllEventsSection performersInContext={performersInContextByCategory} />
      </div>
    );
  }

  renderSportsPerformers() {
    const {
      performersInContextByCategory,
      currentMetro,
      category,
      isMLBCategory,
    } = this.props;

    return (
      <>
        <SportsPerformers
          performersInContextByCategory={performersInContextByCategory}
          currentMetro={currentMetro}
          category={category}
        />
        {isMLBCategory && (
          <div className={styles['performer-links-container']}>
            <PerformerLinks
              heading="Other Performers"
              relatedPerformers={this.props.relatedPerformers}
              topPickPerformers={this.state.topPickPerformers}
              category={category}
            />
          </div>
        )}
        {this.renderCollections()}
      </>
    );
  }

  renderNoActivePerformers() {
    const { performersInContextByCategory, currentMetro, isNonSportsPage } =
      this.props;

    if (isObjectEmpty(performersInContextByCategory) && !isNonSportsPage) {
      return <NoEventsCard metro={currentMetro} />;
    }
  }

  renderMeta() {
    const {
      params: { categoryId },
    } = this.props;
    const category = urlCategorySelector(categoryId);

    const categoryBreadcrumbConfig =
      category && getCategoryBreadcrumbConfig(category);

    const categoryUrl = CATEGORY_URLS[category];
    const defaultTitle = category.toUpperCase();
    const title = categoryUrl?.title || defaultTitle;
    const subtitle = categoryUrl?.shortTitle || title;
    const categoryDesc = categoryUrl?.desc || defaultTitle;
    const emoji = categoryUrl?.emoji ? ` ${categoryUrl?.emoji}` : '';

    const eventType = category && getEventTypeString(category);
    const eventString = eventType ? ` upcoming ${eventType}` : '';

    let pageTitle = `Cheap ${titleCase(title)} Tickets${emoji}`;
    let pageDescription = `See ${subtitle} ticket prices and${eventString} schedules, and score cheap last-minute ${categoryDesc} tickets. Best Price Guarantee! 100% Authentic Tickets.`;

    switch (category) {
      case 'music':
        pageTitle = 'Cheap Concert Tickets';
        pageDescription =
          'See upcoming concerts near you and score cheap last-minute concert tickets for the hottest artists & venues. Best Price Guarantee! 100% secure checkout.';
        break;
      case 'theater':
        pageTitle = 'Cheap Theater Tickets & Broadway Tickets';
        pageDescription =
          'See upcoming theater shows near you and score cheap last-minute theater tickets for the most popular performances. Best Price Guarantee! 100% secure checkout.';
        break;
      default:
        break;
    }

    return (
      <>
        <HeadDescription description={pageDescription} />
        <HeadTitle title={pageTitle} />
        <JsonLD
          json={generateBreadcrumbSchema([
            HOMEPAGE_BREADCRUMB_CONFIG,
            categoryBreadcrumbConfig,
          ])}
        />
      </>
    );
  }

  renderHeroSection() {
    const { category, isMLBCategory } = this.props;
    let heroTitle = this.getCategoryTitle();

    if (heroTitle.toLowerCase() === 'shows') {
      heroTitle = COLLECTION_HEADINGS.THEATER;
    } else if (!/tickets$/i.test(heroTitle)) {
      // Prevents "Theater Tickets Tickets" and "MLB Tickets Tickets"
      heroTitle = `${heroTitle} Tickets`;
    }

    return (
      <SimpleHeroSection
        title={heroTitle}
        backgroundImage={getCategoryImgUrl(category)}
        showMLBMarketplaceTag={isMLBCategory}
      />
    );
  }

  renderBody() {
    const { category, isNonSportsPage } = this.props;

    if (isNonSportsPage) {
      return this.renderNonSportsPerformers();
    }

    return MAJOR_LEAGUE_SPORTS_PERFORMERS.includes(category)
      ? this.renderSportsPerformers()
      : this.renderOtherNCAASportsPerformers();
  }

  render() {
    const {
      params: { categoryId },
    } = this.props;
    const isInvalidCategoryPage = isInvalidCategoryPageSelector(categoryId);
    if (isInvalidCategoryPage) {
      return <NotFound />;
    }

    return (
      <GTContainer
        canShowGoogleAdbanner
        headerProps={{
          className: styles.header,
          search: true,
          showCategories: true,
          showAccount: true,
          showHamburger: true,
        }}
      >
        {this.renderMeta()}
        {this.renderHeroSection()}
        {this.renderNoActivePerformers()}
        {this.renderBody()}
        {this.renderSeoContent()}
      </GTContainer>
    );
  }
}

const mapStateToProps = (state, { params: { categoryId } }) => {
  const category = urlCategorySelector(categoryId);
  const currentMetro = selectUserMetro(state) || selectClosestMetro(state);

  const proximityCollection =
    selectCollectionsByFilters(state, {
      view: COLLECTION_VIEWS.WEB_CATEGORY,
      metro: currentMetro.id,
    }) || [];

  const isNonSportsPage =
    category === CATEGORIES.MUSIC || category === CATEGORIES.THEATER;
  let nonSportsPerformerCollections;
  let nonSportsPerformersInContext;
  let musicVenueCollection;
  if (category === CATEGORIES.MUSIC) {
    nonSportsPerformerCollections = selectCollectionsByFilters(state, {
      metro: currentMetro.id,
      view: COLLECTION_VIEWS.MUSIC_WEB,
    });
    nonSportsPerformersInContext = selectPerformersInContextByFilters(state, {
      category_group: CATEGORY_TO_CATEGORY_GROUP[category],
      metro: currentMetro.id,
    });
    [musicVenueCollection] = selectCollectionsByFilters(state, {
      metro: currentMetro.id,
      view: COLLECTION_VIEWS.MUSIC,
      slug: COLLECTION_SLUGS.VENUE_SCHEDULES,
    });
  } else if (category === CATEGORIES.THEATER) {
    nonSportsPerformerCollections = selectCollectionsByFilters(state, {
      metro: currentMetro.id,
      view: COLLECTION_VIEWS.THEATER_WEB,
    });
    nonSportsPerformersInContext = selectPerformersInContextByFilters(state, {
      category_group: CATEGORY_TO_CATEGORY_GROUP[category],
      metro: currentMetro.id,
    });
  }

  const dealsCollections = selectCollectionsByFilters(state, {
    metro: currentMetro.id,
    view: COLLECTION_VIEWS.WEB_LEAGUE,
  });

  const selectPerformerStaticContentByCategory =
    makeGetPerformerStaticContentByCategory();

  const isMLBCategory = category === CATEGORIES.MLB;

  const performersInContextByMetro = selectPerformersInContextByFilters(state, {
    metro: currentMetro.id,
  });

  const selectPerformersByCategory = makeGetSelectPerformersByCategory();

  const relatedPerformers = selectPerformersByCategory(state, category);

  return {
    performersInContextByCategory:
      selectPerformersInContextByCategory(state, category) || [],
    currentMetro,
    category,
    isNonSportsPage,
    nonSportsPerformerCollections,
    nonSportsPerformersInContext,
    musicVenueCollection,
    performerContent: selectPerformerContentBySlug(state, category),
    nonSportsContent: selectPerformerStaticContentByCategory(state, category),
    dealsCollections,
    isMLBCategory,
    performersInContextByMetro,
    proximityCollection,
    relatedPerformers,
  };
};

const mapDispatchToProps = {
  fetchCollections,
  fetchPerformersInContext,
  fetchAllPerformers,
  fetchTrendingPerformersByCategoryGroup,
};

const ConnectedCategoryPerformer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryPerformers);

const CategoryPerformersWrapper = withRouter(
  withAppContext(ConnectedCategoryPerformer)
);

const loader =
  ({ store: { dispatch, getState } }) =>
  async ({ params: { categoryId } }) => {
    await dispatch(fetchMetros()).then(async () => {
      const state = getState();
      const currentLocation = currentLocationSelector(state);

      const { id } = selectUserMetro(state) || selectClosestMetro(state);
      if (!currentLocation) {
        dispatch(updateCurrentLocation(id));
      }

      const promises = [];
      const category = urlCategorySelector(categoryId);

      if (category === CATEGORIES.MUSIC || category === CATEGORIES.THEATER) {
        let performerCollectionsView;
        if (category === CATEGORIES.MUSIC) {
          performerCollectionsView = COLLECTION_VIEWS.MUSIC_WEB;
        } else if (category === CATEGORIES.THEATER) {
          performerCollectionsView = COLLECTION_VIEWS.THEATER_WEB;
        }

        promises.push(
          dispatch(
            fetchCollections({
              view: performerCollectionsView,
              metro: id,
            })
          )
        );
        promises.push(
          dispatch(
            fetchPerformersInContext({
              category_group: CATEGORY_TO_CATEGORY_GROUP[category],
              limit: INITIAL_PERFORMER_LIMIT,
              metro: id,
              skipCache: true,
            })
          )
        );
        if (category === CATEGORIES.MUSIC) {
          promises.push(
            dispatch(
              fetchCollections({
                metro: id,
                view: COLLECTION_VIEWS.MUSIC,
                slug: COLLECTION_SLUGS.VENUE_SCHEDULES,
              })
            )
          );
        }
      } else {
        promises.push(
          dispatch(
            fetchPerformersInContext({
              category,
            })
          )
        );

        if (MAJOR_LEAGUE_SPORTS_PERFORMERS.includes(category)) {
          try {
            await dispatch(
              fetchCollections({
                metro: id,
                view: COLLECTION_VIEWS.WEB_LEAGUE,
              })
            );
          } catch (error) {
            console.error('Error fetching collections', error);
          }
        }

        if (category === CATEGORIES.MLB) {
          promises.push(
            dispatch(
              fetchPerformersInContext({
                metro: id,
                limit: PERFORMERS_IN_CONTEXT_PER_PAGE,
              })
            )
          );
        }
      }

      if (category === CATEGORIES.CFB) {
        const metroLocationCoordinates = selectMetro(state, id).location
          .coordinates;
        const { lat, lon } = metroLocationCoordinates;
        promises.push(
          dispatch(
            fetchCollections({
              metro: id,
              view: COLLECTION_VIEWS.WEB_CATEGORY,
              category,
              location_radius: 80,
              lat,
              lon,
              results_per_page: 20,
              with_results: true,
              skipCache: true,
            })
          )
        );
      }

      promises.push(dispatch(fetchPerformerContentBySlug(category)));

      return Promise.all(promises);
    });

    return null;
  };

CategoryPerformersWrapper.loader = loader;

export default CategoryPerformersWrapper;
