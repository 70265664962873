import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  configureStore,
  Middleware,
  ThunkAction,
  UnknownAction,
} from '@reduxjs/toolkit';
import { ButterStatic } from 'buttercms';

import { HttpClient } from 'services/HttpClient/HttpClient';

import clientMiddleware from './middleware/clientMiddleware';
import cmsMiddleware from './middleware/cmsMiddleware';
import reducer from './modules/reducer';

type PreloadedState = Parameters<typeof reducer>[0];

type StoreServices = {
  /**
   * HttpClient instance configured for MobileAPI
   */
  mobileApi: HttpClient;
  butter: ButterStatic;
};

function createStore(
  storeServices: StoreServices,
  preloadedState?: PreloadedState
) {
  const { mobileApi, butter } = storeServices;

  return configureStore({
    reducer,
    preloadedState,
    devTools: __DEVTOOLS__,
    middleware: (defaultMiddleware) =>
      defaultMiddleware({
        /**
         * There are non-serializable values being put in the Redux store which
         * is a violation of one of the core principles of Redux. Skipping this
         * check is a temporary workaround to keep the app functioning with the
         * new store configuration, but we should really get this resolved by
         * removing non-serializable values from the store.
         *
         * see: https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
         */
        serializableCheck: false,
        thunk: {
          extraArgument: storeServices,
        },
      })
        .concat(clientMiddleware(mobileApi) as Middleware)
        .concat(cmsMiddleware(butter) as Middleware),
  });
}

type Store = ReturnType<typeof createStore>;
type RootState = ReturnType<Store['getState']>;
type AppDispatch = Store['dispatch'];
type Thunk<ReturnType> = ThunkAction<
  ReturnType,
  RootState,
  StoreServices,
  UnknownAction
>;
type AsyncThunk<ReturnType = void> = Thunk<Promise<ReturnType>>;

export type {
  AppDispatch,
  AsyncThunk,
  StoreServices,
  PreloadedState,
  RootState,
  Store,
  Thunk,
};

const useAppSelector = useSelector.withTypes<RootState>();
const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export { createStore, ReduxProvider, useAppDispatch, useAppSelector };
