import React from 'react';
import PropTypes from 'prop-types';

import ButtonLoader from 'components/ButtonLoader/ButtonLoader';

import styles from './GetAppPromo.module.scss';

const GET_APP_CTA = 'download the app';

const GetAppPromo = ({ title, text }) => {
  return (
    <div className={styles['get-app-container']}>
      <div className={styles['get-app-card']}>
        <h4 className={styles['get-app-card-title']}>{title}</h4>
        <p className={styles['get-app-card-text']}>{text}</p>
        <div className={styles['get-app-card-link']}>
          {/* todo: add link/sms/tracking in next PR */}
          <ButtonLoader
            className={styles['get-app-card-button']}
            text={GET_APP_CTA}
          />
        </div>
        <span className={styles['get-app-available']}>
          available on ios & android
        </span>
      </div>
    </div>
  );
};
// Convert to TS on next PR
GetAppPromo.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default GetAppPromo;
