/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import Mailcheck from 'mailcheck';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import DarkThemedFormButtonLoader from 'components/DarkThemedComponents/DarkThemedFormButtonLoader';
import TextInput from 'components/Inputs/TextInput';
import EmailVerificationModal from 'components/Modals/EmailVerificationModal/EmailVerificationModal';

import styles from './UserNameEmailForm.module.scss';

const UserNameEmailFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First name is required.'),
  lastName: Yup.string().trim().required('Last name is required.'),
  email: Yup.string()
    .email('Must be a valid email')
    .trim()
    .required('Must be a valid email'),
});

const UserNameEmailForm = ({
  handleUserNameEmailFormSubmit,
  mpActions,
  ctaText = 'SUBMIT',
  clickTracker,
  firstName = '',
  lastName = '',
  email = '',
  isMLBInfoCollection,
}) => {
  Mailcheck.defaultDomains.push('gametime.co');

  const [emailSuggestion, setEmailSuggestion] = useState('');
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);

  const handleUserConfirmation = (submitForm, errors) => {
    if (isMLBInfoCollection && !Object.keys(errors).length) {
      setShowEmailVerificationModal(true);
    } else {
      submitForm();
    }
  };

  const handleModalSubmit = (submitForm) => {
    submitForm();
    setShowEmailVerificationModal(false);
  };
  /**
   * When the user submits the form, Mailcheck will run to see if the
   * email address has a spelling error in it. If Mailcheck determines
   * that there's a spelling error, it will show in the info section
   * under the input field and prevent a submission. If the user submits
   * while there's an email suggestion shown on screen, they acknowledge
   * that what they typed is accurate.
   */
  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      if (emailSuggestion) {
        await handleUserNameEmailFormSubmit(values);
      } else {
        await Mailcheck.run({
          email: values.email.trim(),
          suggested: (suggestion) => {
            setEmailSuggestion(suggestion.full);
            setSubmitting(false);
          },
          empty: async () => {
            await handleUserNameEmailFormSubmit(values);
            setSubmitting(false);
          },
        });
      }
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ firstName, lastName, email }}
      onSubmit={onSubmitHandler}
      validationSchema={UserNameEmailFormValidationSchema}
    >
      {(props) => {
        const {
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
          values,
        } = props;

        return (
          <Form onSubmit={handleSubmit}>
            <div className={styles['name-wrapper']}>
              <TextInput
                id="user-first-name"
                name="firstName"
                label="First Name"
                placeholder="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                error={{
                  hasError: errors.firstName && touched.firstName,
                  message: errors.firstName,
                }}
                value={values.firstName}
                required
              />
              <TextInput
                id="user-last-name"
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                error={{
                  hasError: errors.lastName && touched.lastName,
                  message: errors.lastName,
                }}
                value={values.lastName}
                required
              />
            </div>
            <TextInput
              id="user-email"
              name="email"
              label="Email Address"
              placeholder="Email Address"
              autoComplete="email"
              onChange={(e) => {
                setEmailSuggestion('');
                handleChange(e);
              }}
              onBlur={handleBlur}
              disabled={isSubmitting}
              error={{
                hasError: errors.email && touched.email,
                message: errors.email,
              }}
              info={{
                hasInfo: emailSuggestion && touched.email,
                infoMessage: `Did you mean ${emailSuggestion}?`,
              }}
              value={values.email}
              required
            />
            <DarkThemedFormButtonLoader
              onClick={() => handleUserConfirmation(submitForm, errors)}
              type="button"
              loading={isSubmitting}
              disabled={isSubmitting}
              text={ctaText}
              mpActions={mpActions}
              clickTracker={clickTracker}
              showBackground
            />
            {showEmailVerificationModal && (
              <EmailVerificationModal
                email={values.email}
                setShowEmailVerificationModal={setShowEmailVerificationModal}
                onSubmit={() => handleModalSubmit(submitForm)}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

UserNameEmailForm.propTypes = {
  handleUserNameEmailFormSubmit: PropTypes.func.isRequired,
  mpActions: PropTypes.object,
  ctaText: PropTypes.string,
  clickTracker: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
};

export default UserNameEmailForm;
