import React from 'react';
import Tag from 'ui/Tag/Tag';

import { FullEventClickTracker } from 'analytics';
import BulletList from 'components/BulletList/BulletList';
import Emoji from 'components/Emoji';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import { FullEvent } from 'models';
import colors from 'styles/colors.constants';
import { formatPriceWithComma } from 'utils/number';

import styles from './EventCard.module.scss';

type EventCardProps = {
  event: FullEvent;
  clickTracker: FullEventClickTracker;
};

const EventCard = ({ event, clickTracker }: EventCardProps) => {
  const title = event.getShortName('@');
  const imageSrc = event.getPrimaryPerformer().heroImageUrl;
  const price = `From $${formatPriceWithComma(event.getPrice())}`;
  const bulletList = <BulletList listItems={event.dateTimeVenue} />;
  const tagData = event.getTagData();

  const tagElement = tagData ? (
    <Tag
      title={tagData.title}
      icon={<Emoji emoji={tagData.emoji} variant="0xFE0F" />}
      background={tagData.background}
      color={colors.black}
      type="event"
    />
  ) : null;

  return (
    <Link clickTracker={clickTracker} to={event.getPath()}>
      <div className={styles['card-container']}>
        <div className={styles['img-zoom-container']}>
          <div className={styles['img-zoom-container']}>
            <Image
              className={styles['event-img']}
              src={imageSrc}
              alt={event.getAltText()}
            />
          </div>
        </div>
        <div className={styles['tag-container']}>{tagElement}</div>
        <div className={styles['event-details']}>
          <span className={styles['event-name']}>{title}</span>
          <span className={styles['event-date-time']}>{bulletList}</span>
          <span className={styles['event-price']}>{price}</span>
        </div>
      </div>
    </Link>
  );
};

export default EventCard;
