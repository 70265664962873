import React, { useContext, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ArrowSet from 'ui/ArrowSet/ArrowSet';
import PerformerChip from 'ui/PerformerChip/PerformerChip';

import { Click, PerformerClickTracker } from 'analytics';
import Link from 'components/Link/Link';
import { Performer } from 'models';

import styles from './TeamsNearby.module.scss';

export default function TeamsNearby({
  performers,
}: {
  performers: Performer[];
}) {
  const renderHeader = () => {
    const { scrollPrev, scrollNext, useIsVisible, items } =
      useContext(VisibilityContext);

    const visibleItems = items.getVisible();
    // first and last items in the list
    const firstItem = items.first()?.key;
    const lastItem = items.last()?.key;

    // last items in the viewport
    const [firstVisibleItem, setFirstVisibleItem] = useState<
      string | undefined
    >(firstItem);
    const [lastVisibleItem, setLastVisibleItem] = useState<string>();

    const isFirstItemVisible = useIsVisible('first', true);
    const isLastItemVisible = useIsVisible('last', false);

    // current items in viewport. will not update state if no items are visible or
    // if the items are the same as the last visible ones
    const currentFirstVisibleItem = visibleItems.at(0)?.[0];
    const currentLastVisibleItem = visibleItems.at(-1)?.[0];
    if (currentFirstVisibleItem && currentFirstVisibleItem !== firstVisibleItem)
      setFirstVisibleItem(currentFirstVisibleItem);
    if (currentLastVisibleItem && currentLastVisibleItem !== lastVisibleItem)
      setLastVisibleItem(currentLastVisibleItem);

    // if items are not in the viewport, ask if the first or last items were the
    // last visible items, if not, we can scroll
    const canScrollNext =
      isFirstItemVisible || (lastItem && lastItem !== lastVisibleItem);
    const canScrollPrev =
      isLastItemVisible || (firstItem && firstItem !== firstVisibleItem);

    return (
      <div className={styles['header-container']}>
        <h2 id="teams-nearby-heading" className={styles.title}>
          Teams Nearby
        </h2>
        <div className={styles['arrow-set-container']}>
          <ArrowSet
            onNext={() => scrollNext()}
            onPrevious={() => scrollPrev()}
            canScrollNext={!!canScrollNext}
            canScrollPrev={!!canScrollPrev}
          />
        </div>
      </div>
    );
  };

  return (
    <ScrollMenu
      scrollContainerClassName={styles['scroll-container']}
      Header={() => renderHeader()}
    >
      {performers.map((performer) => (
        <Link
          to={performer.getPath()}
          key={performer.id}
          clickTracker={new PerformerClickTracker(performer)
            .sourcePageType(Click.SOURCE_PAGE_TYPES.HOMEPAGE())
            .interaction(Click.INTERACTIONS.CAROUSEL(), {
              collection: 'Teams Nearby',
            })}
        >
          <PerformerChip
            name={performer.name}
            eventCount={performer.stats?.event_count}
            color={performer.primaryColor}
            emoji={performer.emoji}
          />
        </Link>
      ))}
    </ScrollMenu>
  );
}
