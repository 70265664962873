import React, { useRef, useState } from 'react';
import _merge from 'lodash/merge';

import { Click, ClickTracker, useAnalyticsContext } from 'analytics';
import { useClickContext } from 'analytics/context/ClickContext';
import FilterDropdown from 'components/FilterControl/FilterDropdown';
import MetroSelectorModal from 'components/SelectorModals/MetroSelector/MetroSelector';
import { device, useMediaQuery, useOnClickOutside } from 'hooks';
import { CaretDownIcon, LocationFillIcon } from 'icons';
import { useAppDispatch } from 'store';
import { updateCurrentLocation } from 'store/modules/app/app';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import colors from 'styles/colors.constants';
import { Metro } from 'types';

import styles from './CarouselMetroPicker.module.scss';

interface CarouselMetroPickerProps {
  closestMetro: Metro;
  collectionTitle?: string;
  currentMetro: Metro;
  handleMetroChange: (metro: string) => void;
  metros: Metro[];
}

const CarouselMetroPicker = ({
  closestMetro,
  collectionTitle = 'Popular Events Near',
  currentMetro,
  handleMetroChange,
  metros,
}: CarouselMetroPickerProps) => {
  const [isMetroModalOpen, setIsMetroModalOpen] = useState(false);
  const analytics = useAnalyticsContext();
  const clickContext = useClickContext();
  const isMobile = useMediaQuery(device.down.sm);

  const dispatch = useAppDispatch();
  const handleMetroFilterClickOutside = () => {
    if (!isMobile) {
      setIsMetroModalOpen(false);
    }
  };
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, handleMetroFilterClickOutside);
  const closeModal = () => setIsMetroModalOpen(false);
  const openModal = () => {
    const tracker = new ClickTracker().interaction(
      Click.INTERACTIONS.CHANGE_LOCATION()
    );
    analytics.track(
      new Click(_merge({}, clickContext, tracker.json()) as Click)
    );
    setIsMetroModalOpen(true);
  };

  const handleMetroSelect = (selectedMetro: Metro) => {
    handleMetroChange(selectedMetro.id);
    dispatch(updateCurrentLocation(selectedMetro.id));
    dispatch(updateUserPreference({ lastVisitedMetro: selectedMetro.id }));
  };

  const metroFilterOptions = [
    {
      name: 'Use My Location',
      onClick: () => handleMetroSelect(closestMetro),
      icon: <LocationFillIcon fill={colors.gametimeGreen} />,
    },
    ...metros.map((metro) => {
      return {
        name: metro.name,
        onClick: () => {
          handleMetroSelect(metro);
        },
      };
    }),
  ];

  const metroFilterModal = isMobile ? (
    <MetroSelectorModal
      show={isMetroModalOpen}
      onHide={() => {
        setIsMetroModalOpen(false);
      }}
    />
  ) : (
    <FilterDropdown show={isMetroModalOpen} options={metroFilterOptions} />
  );

  return (
    <div className={styles['picker-header']}>
      <h1 ref={ref} className={styles['metro-picker']}>
        {collectionTitle}
        {!isMobile && (
          <button
            className={styles['inline-metro-picker']}
            onClick={() => (isMetroModalOpen ? closeModal() : openModal())}
            aria-expanded={isMetroModalOpen}
            aria-haspopup="true"
            aria-label="Select metro area"
          >
            <div className={styles['metro-icon-container']}>
              <span className={styles['inline-metro']}>
                {currentMetro.name}
              </span>
              <CaretDownIcon
                width="24"
                height="24"
                fill={colors.gametimeGreenLight}
                aria-hidden="true"
              />
            </div>
            {metroFilterModal}
          </button>
        )}
      </h1>
    </div>
  );
};

export default CarouselMetroPicker;
