import { useCallback, useEffect, useMemo, useState } from 'react';
import { create as braintreeDataCollectorCreate } from 'braintree-web/data-collector';
import {
  create as braintreeHostedFieldsCreate,
  HostedFieldFieldOptions,
  HostedFields,
  HostedFieldsHostedFieldsFieldData as FieldData,
  HostedFieldsHostedFieldsFieldName as FieldName,
  HostedFieldsTokenizePayload,
} from 'braintree-web/hosted-fields';

import config from 'config/config';
import { useAppDispatch } from 'store';
import { saveUserBraintreeData } from 'store/modules/userPurchases/actions';

export type BraintreeTokenize = () => Promise<HostedFieldsTokenizePayload>;
export interface BraintreeBillingAddress {
  firstName: string;
  lastName: string;
  streetAddress: string;
  extendedAddress?: string;
  locality: string;
  region: string;
  postalCode: string;
  countryCodeAlpha2: string;
}

export function getFieldErrorMessage(fieldName: FieldName, field: FieldData) {
  let error = undefined;

  switch (fieldName) {
    case 'number': {
      if (field.isEmpty) {
        error = 'Credit card number is required';
      } else if (!field.isValid) {
        error = 'Please check if you typed the correct card number';
      }
      break;
    }
    case 'expirationDate': {
      if (field.isEmpty || !field.isValid) {
        error = 'Please enter a valid card expiry date';
      }
      break;
    }
    case 'cvv': {
      if (field.isEmpty || !field.isValid) {
        error = 'Please enter a valid 3-4 digit security code';
      }
      break;
    }
    default: {
      break;
    }
  }

  return error;
}

export function useBraintreeHostedFields() {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<
    HostedFields | undefined
  >();

  const [fieldFocus, setFieldFocus] = useState<
    Partial<Record<FieldName, boolean>>
  >({});

  const [fieldErrors, setFieldErrors] = useState<
    Partial<Record<FieldName, string>>
  >({});
  const setFieldError = useCallback(
    (fieldName: FieldName, errorMessage?: string) =>
      setFieldErrors((fieldErrorsState) => ({
        ...fieldErrorsState,
        [fieldName]: errorMessage,
      })),
    []
  );

  /**
   * Creates a Braintree Hosted Fields instance and sets the value in state.
   *
   * see: https://braintree.github.io/braintree-web/current/module-braintree-web_hosted-fields.html#.create
   * see: https://braintree.github.io/braintree-web/current/HostedFields.html
   */
  const createHostedFields = useCallback(
    (
      fields: HostedFieldFieldOptions,
      styles: Record<string, string | React.CSSProperties>
    ) =>
      braintreeHostedFieldsCreate({
        authorization: config.BRAINTREE_CLIENT_TOKEN,
        fields,
        styles,
      })
        .then((hostedFieldsInstance) => {
          hostedFieldsInstance.on('focus', (event) => {
            const fieldName = event.emittedBy;
            setFieldFocus((fields) => ({
              ...fields,
              [fieldName]: true,
            }));
          });

          hostedFieldsInstance.on('blur', (event) => {
            const fieldName = event.emittedBy;
            const fieldData = event.fields[fieldName];
            const errorMessage = getFieldErrorMessage(fieldName, fieldData);

            setFieldError(fieldName, errorMessage);
            setFieldFocus((fields) => ({
              ...fields,
              [fieldName]: false,
            }));
          });

          hostedFieldsInstance.on('validityChange', (event) => {
            const fieldName = event.emittedBy;
            const fieldData = event.fields[fieldName];

            if (fieldData.isValid) {
              setFieldError(fieldName, undefined);
            }
          });

          setHostedFieldsInstance(hostedFieldsInstance);

          return hostedFieldsInstance;
        })
        .catch((error) => {
          console.error('Error instantiating Braintree Hosted Fields', error);
        }),
    [setFieldError]
  );

  useEffect(() => {
    if (!hostedFieldsInstance) {
      return;
    }

    // only return cleanup function if hosted fields instance exists
    return () => {
      hostedFieldsInstance.teardown();
    };
  }, [hostedFieldsInstance]);

  const dispatch = useAppDispatch();

  const hostedFields = useMemo(() => {
    if (!hostedFieldsInstance) {
      return;
    }

    return {
      getState: hostedFieldsInstance.getState,

      /**
       * Validates fields found in Braintree Hosted Fields instance state,
       * returning true if all fields are valid or false if any field
       * contains an error.
       */
      validate() {
        const fieldsState = hostedFieldsInstance.getState().fields;

        const fieldErrors: Partial<Record<FieldName, string>> = {};
        let fieldName: FieldName;
        for (fieldName in fieldsState) {
          const fieldData = fieldsState[fieldName];
          const errorMessage = getFieldErrorMessage(fieldName, fieldData);
          if (errorMessage) {
            fieldErrors[fieldName] = errorMessage;
          }
        }

        setFieldErrors(fieldErrors);

        return Object.keys(fieldErrors).length === 0;
      },

      /**
       * Tokenize a payment method with hosted field instance data and
       * return the response. Also has the side effect of dispatching an
       * action to save Braintree user device data. This async function
       * should be called after using validateFields.
       */
      async tokenize(billingAddress?: BraintreeBillingAddress) {
        const [payload] = await Promise.all([
          hostedFieldsInstance.tokenize({ billingAddress }),
          braintreeDataCollectorCreate({
            // @ts-expect-error authorization field missing in @types/braintree-web
            authorization: config.BRAINTREE_CLIENT_TOKEN,
          })
            .then((dataCollector) => {
              dispatch(saveUserBraintreeData(dataCollector.deviceData));
            })
            .catch(console.error),
        ]);

        return payload;
      },

      /**
       * Set an attribute on a form field.
       *
       * @param {Object} options
       * @param {string} options.field - The name of the field. Must be a valid
       *   field name included in the hosted fields options.
       * @param {string} options.attribute - Supported attributes are
       *   aria-invalid, aria-required, disabled, and placeholder.
       * @param {string} options.value — the value to set for the attribute.
       * @param {function} [callback] — optional callback invoked after the
       *   field attribute is set. Includes an error if one occurred. If the
       *   attribute is set successfully, no data is returned.
       */
      setAttribute:
        hostedFieldsInstance.setAttribute.bind(hostedFieldsInstance),

      /**
       * Remove an attribute from a form field.
       *
       * @param {Object} options
       * @param {string} options.field - The name of the field. Must be a valid
       *   field name included in the hosted fields options.
       * @param {string} options.attribute - Supported attributes are
       *   aria-invalid, aria-required, disabled, and placeholder.
       * @param {function} [callback] — optional callback invoked after the
       *   field attribute is removed. Includes an error if one occurred. If the
       *   attribute is removed successfully, no data is returned.
       */
      removeAttribute:
        hostedFieldsInstance.removeAttribute.bind(hostedFieldsInstance),
    };
  }, [hostedFieldsInstance, dispatch]);

  return {
    createHostedFields,
    hostedFields,
    fieldFocus,
    fieldErrors,
    setFieldError,
  };
}
