import React from 'react';

import colorsConstants from 'styles/colors.constants';

import styles from './PerformerChip.module.scss';

type Props = {
  name: string;
  color?: string;
  eventCount?: number;
  emoji?: string;
  onClick?: () => void;
};

export default function PerformerChip({
  name,
  color = colorsConstants.gametimeGreen,
  emoji,
  eventCount = 0,
  onClick = () => {},
}: Props) {
  return (
    <button className={styles['main-container']} onClick={onClick}>
      <div
        className={styles['icon-container']}
        style={{ backgroundColor: color }}
        data-testid="performer-icon-container"
      >
        <span className={styles.emoji} aria-hidden="true">
          {emoji}
        </span>
      </div>
      <div className={styles['text-container']}>
        <span className={styles.name}>{name}</span>
        <span
          className={styles['event-count']}
        >{`${eventCount} Event${eventCount === 1 ? '' : 's'}`}</span>
      </div>
    </button>
  );
}
