import { generateTopPicksPerformers } from 'store/modules/performers/performers.helpers';
import { ascSort } from 'utils/sorts';

import { ACTIONS } from './actions';

const initialState = {
  currentCategory: null,
  searchPerformers: [],
  isFetchingPerformers: false,
  allPerformersFetched: false,
  localPerformers: {},
  topPickPerformers: [],
};

function _sportsCategoriesToState(state, { result }) {
  const performers = result.performers || [];
  const performersMap = performers.reduce((acc, p) => {
    const { category } = p;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(p);
    return acc;
  }, {});

  return {
    ...state,
    ...performersMap,
    currentCategory: null,
    isFetchingPerformers: false,
  };
}

const _relatedPerformersToState = (state, { params, result }) => {
  return {
    ...state,
    isFetchingPerformers: false,
    [params.slug]: {
      ...state[params.slug],
      relatedPerformers: result.performers,
    },
  };
};

const _getPerformersRequestSuccess = (state, action, sort) => {
  const { result, params } = action;
  const performers = result.performers || [];
  if (sort) {
    ascSort(performers, 'name');
  }

  const { category, slug, category_group, isSports, isRelatedPerformers } =
    params;

  if (isSports) {
    return _sportsCategoriesToState(state, action);
  }

  if (isRelatedPerformers) {
    return _relatedPerformersToState(state, action);
  }

  /*
   * we fetch sports by either slug or category
   * if by slug then user clicked on a sports team from sub menu so results will be an array of 1 performer
   * otherwise we fetch by category so we store all the results
   */
  const key = slug || category || category_group;
  const value = slug ? performers[0] : performers;

  return {
    ...state,
    [key]: value,
    isFetchingPerformers: false,
  };
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.GET_PERFORMERS_REQUEST:
      return {
        ...state,
        isFetchingPerformers: true,
      };
    case ACTIONS.GET_PERFORMERS_REQUEST_SUCCESS: {
      return _getPerformersRequestSuccess(state, action, true);
    }
    case ACTIONS.GET_TRENDING_PERFORMERS_REQUEST_SUCCESS: {
      return _getPerformersRequestSuccess(state, action, false);
    }
    case ACTIONS.SET_PERFORMERS_CATEGORY:
      return {
        ...state,
        currentCategory: action.category,
      };
    case ACTIONS.ALL_PERFORMERS_FETCHED:
      return {
        ...state,
        allPerformersFetched: true,
      };
    case ACTIONS.GET_LOCAL_PERFORMERS_REQUEST_SUCCESS: {
      const { params, result } = action;
      const metro = state.localPerformers[params.metro] || {};

      const localPerformers = {
        ...state.localPerformers,
        [params.metro]: {
          ...metro,
          [params.category]: result.performers,
        },
      };

      return {
        ...state,
        localPerformers,
      };
    }
    case ACTIONS.CALCULATE_TOP_PICK_PERFORMERS: {
      const { performersInContext, performer } = action;
      const topPickPerformers = generateTopPicksPerformers(
        performersInContext,
        performer
      );
      return {
        ...state,
        topPickPerformers,
      };
    }
    default:
      return state;
  }
}
