import { SOURCE_PAGE_TYPE_NAMES } from 'analytics/constants';

export const getSourcePageType = (url: string) => {
  if (url.includes('-tickets/metros/')) {
    return SOURCE_PAGE_TYPE_NAMES.METRO;
  }
  if (url.includes('/order/')) {
    return SOURCE_PAGE_TYPE_NAMES.ORDER_CONFIRMATION;
  }

  switch (url) {
    case '/':
      return SOURCE_PAGE_TYPE_NAMES.HOMEPAGE;
    case '/picks':
      return SOURCE_PAGE_TYPE_NAMES.GT_PICKS;
    case '/why-gametime':
      return SOURCE_PAGE_TYPE_NAMES.WHY_GAMETIME;
    default:
      return SOURCE_PAGE_TYPE_NAMES.HOMEPAGE;
  }
};
