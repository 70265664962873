import React from 'react';
import classNames from 'classnames';

import styles from './Tag.module.scss';

interface TagProps {
  title: React.ReactNode;
  icon?: React.ReactNode;
  /**
   * optional background property, supports any valid CSS background property
   * including colors, gradients, and images.
   */
  background?: string;
  color?: string;
  borderColor?: string;
  bordered?: boolean;
  size?: 'default' | 'small';
  type?: 'default' | 'event';
}

export default function Tag({
  title,
  icon,
  background,
  color,
  borderColor,
  bordered = false,
  size = 'default',
  type = 'default',
}: TagProps) {
  return (
    <div
      data-testid="tag"
      style={
        {
          '--background': background,
          '--color': color,
          '--border-color': borderColor,
        } as React.CSSProperties
      }
      className={classNames(
        styles['tag'],
        styles[`size-${size}`],
        styles[`type-${type}`],
        {
          [styles['bordered']]: bordered,
        }
      )}
    >
      {icon}
      <span className={styles.title}>{title}</span>
    </div>
  );
}
