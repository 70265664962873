import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import CMSContentCard from 'ui/CMSContentCard/CMSContentCard';

import {
  Click,
  ClickTracker,
  FullEventClickTracker,
  PerformerClickTracker,
  useAnalyticsContext,
  View,
} from 'analytics';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import { generateBreadcrumbSchema } from 'components/Breadcrumbs/breadcrumb.helpers';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import HeadImage from 'components/Head/Image';
import PreloadedImage from 'components/Head/PreloadedImage';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import JsonLD from 'components/JsonLD/JsonLD';
import ReactSlickCarousel from 'components/ReactSlickCarousel/ReactSlickCarousel';
import { device, useMediaQuery } from 'hooks';
import FullEvent from 'models/FullEvent';
import Performer from 'models/Performer';
import { HOMEPAGE_TITLE } from 'modules/pageTitles';
import { COLLECTION_VIEWS } from 'pages/Collection/constants';
import { MESSAGE_PREVIEW_IMAGE_URL } from 'pages/constants';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { DataLoader } from 'routes/routes.utils';
import { useAppDispatch } from 'store';
import {
  currentLocationSelector,
  updateCurrentLocation,
} from 'store/modules/app/app';
import { showMobileHeroSearchBox } from 'store/modules/app/app.ui';
import { CATEGORY_GROUP_IDS } from 'store/modules/categories/category.helpers';
import { fetchPerformerEventsContent } from 'store/modules/content/performers/actions';
import { selectPerformerEventsContent } from 'store/modules/content/performers/selectors';
import { fetchCollections } from 'store/modules/data/Collections/actions';
import { selectPopularCollectionByMetro } from 'store/modules/data/Collections/selectors';
import { fetchTrendingPerformersByCategoryGroup } from 'store/modules/data/Performers/actions';
import { makeGetSelectPerformersByCategoryGroup } from 'store/modules/data/Performers/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectAllMetros,
  selectClosestMetro,
  selectMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';

import AppStoreLinks from './components/AppStoreLinks/AppStoreLinks';
import CarouselMetroPicker from './components/CarouselMetroPicker/CarouselMetroPicker';
import Categories from './components/Categories/Categories';
import DownloadApp from './components/DownloadApp/DownloadApp';
import EventCard from './components/EventCard/EventCard';
import PerformerCard from './components/PerformerCard/PerformerCard';
import Reviews from './components/Reviews/Reviews';
import SearchHero from './components/SearchHeroV1/SearchHeroV1';
import TeamsNearby from './components/TeamsNearby/TeamsNearby';
import ValueProps from './components/ValueProps/ValueProps';
import {
  DESKTOP_SEARCH_HERO_BACKGROUND_URL,
  MOBILE_SEARCH_HERO_BACKGROUND_URL,
} from './constants';

import styles from './HomeV1.module.scss';

type Analytics = {
  track: (arg: unknown) => void;
};

type CmsContent = { fields: CmsContentFields };
type CmsContentFields = {
  title: string;
  subtitle: string;
  button_cta_text: string;
  button_link: string;
  tag_text: string;
  image: string;
  background_color: string;
};

const selectTrendingPerformersByCategoryGroup =
  makeGetSelectPerformersByCategoryGroup();

function HomeV1() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(device.down.md);
  const params = useParams();
  const navigate = useNavigate();

  // hooks
  const analytics: Analytics = useAnalyticsContext();

  // metro logic, todo make its own hook or a more sophisticated selector
  const userMetro = useSelector(selectUserMetro) || {};
  const closestMetro = useSelector(selectClosestMetro);
  const metroId = params.metroId || userMetro.id || closestMetro.id;
  const currentMetro = useSelector((state) => selectMetro(state, metroId));
  const metros = useSelector(selectAllMetros);

  useEffect(() => {
    // handles standard case of user navigating to a different metro
    if (params.metroId !== userMetro.id) {
      fetchOnMetroChange(userMetro.id);
    }
    // handles case of user navigating back with a different metroId
    if (userMetro?.id !== metroId) {
      handleMetroChange(userMetro.id);
    }

    analytics.track(new View(View.PAGE_TYPES.HOMEPAGE(metroId)));
  }, [metroId, userMetro, params.metroId]);

  // collection
  const popularCollection = useSelector(selectPopularCollectionByMetro);

  // butterContent
  const cmsContent: CmsContent[] = useSelector(selectPerformerEventsContent);

  // select trending concert performers
  const concertPerformers = useSelector((state) =>
    selectTrendingPerformersByCategoryGroup(state, 'concert')
  );

  const sportsPerformers = useSelector((state) =>
    selectTrendingPerformersByCategoryGroup(state, CATEGORY_GROUP_IDS.sports)
  );

  const fetchOnMetroChange = async (metroId: string) => {
    await Promise.all([
      dispatch(
        fetchCollections({
          view: COLLECTION_VIEWS.WEB_DISCOVER,
          with_results: true,
          metro: metroId,
          skipCache: true,
        })
      ),
      dispatch(
        fetchTrendingPerformersByCategoryGroup(
          metroId,
          CATEGORY_GROUP_IDS.sports
        )
      ),
      dispatch(fetchTrendingPerformersByCategoryGroup(metroId, 'concert')),
    ]);
  };

  const handleMetroChange = async (_metroId: string) => {
    if (!_metroId || _metroId === metroId) return null;
    navigate(`/${_metroId}-tickets/metros/${_metroId}`);
  };

  const handleShowSearchBox = () => {
    dispatch(showMobileHeroSearchBox());
  };

  const renderPopularHeader = () => {
    const collectionTitle = isMobile
      ? popularCollection?.title
      : `${popularCollection?.title} in`;
    return (
      <CarouselMetroPicker
        collectionTitle={collectionTitle}
        currentMetro={currentMetro}
        metros={metros}
        closestMetro={closestMetro}
        handleMetroChange={handleMetroChange}
      />
    );
  };

  const renderPopularItem = (event: FullEvent) => (
    <EventCard
      event={event}
      clickTracker={new FullEventClickTracker(event)
        .sourcePageType(Click.SOURCE_PAGE_TYPES.HOMEPAGE())
        .interaction(Click.INTERACTIONS.TILE(), {
          collection: 'Popular Events',
          get_in_price: event.getPrice(),
        })}
    />
  );

  /* set up react slick handling:
   *   tracking for top forward and back arrows
   *   renderConcertPerformerItem
   *   renderHeader
   *
   * render component in the artists-item div
   * remember to specify slide width and height
   *
   * create performer card component
   */

  const renderConcertPerformerHeader = () => (
    <h2
      className={classNames(
        styles['concert-carousel-header'],
        styles['section-title']
      )}
    >
      Top Artists
    </h2>
  );

  const renderConcertPerformerItem = (performer: Performer) => (
    <PerformerCard
      performer={performer}
      clickTracker={new PerformerClickTracker(performer)
        .interaction(Click.INTERACTIONS.TILE(), {
          collection: 'Top Artists',
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.HOMEPAGE())}
    />
  );

  return (
    <ContainerTemplate
      canShowGoogleAdbanner
      header={
        <MinimalHeader
          search
          showCategories
          showAccount
          showHamburger
          showGTShield
          isHomepage
          showGTMessage
        />
      }
      className={styles['home-container']}
    >
      <div>
        <HeadTitle
          title={HOMEPAGE_TITLE}
          helmetProps={{ titleTemplate: '%s' }}
        />
        <HeadImage src={MESSAGE_PREVIEW_IMAGE_URL} />
        <JsonLD json={generateBreadcrumbSchema([HOMEPAGE_BREADCRUMB_CONFIG])} />
        <PreloadedImage src={DESKTOP_SEARCH_HERO_BACKGROUND_URL} />
        <PreloadedImage src={MOBILE_SEARCH_HERO_BACKGROUND_URL} />
      </div>
      <main className={styles['home']}>
        <section className={styles['hero-block']}>
          <div className={classNames(styles['homepage-block'], styles.search)}>
            <SearchHero
              currentMetro={currentMetro}
              handleHeroSearchboxFocus={handleShowSearchBox}
            />
          </div>
          <div className={classNames(styles['homepage-block'], styles.popular)}>
            {popularCollection && Object.keys(popularCollection).length > 0 && (
              <ReactSlickCarousel
                items={popularCollection.eventsList}
                renderItem={renderPopularItem}
                renderHeader={renderPopularHeader}
                title="popular-events-carousel"
                spacing={isMobile ? 16 : 24}
                slideWidth={isMobile ? 235 : 282}
                trackMargin={isMobile ? 16 : 0}
                topArrows={!isMobile}
                className={styles['space-between']}
                getItemKey={(fullEvent: FullEvent) => fullEvent.event.id}
              />
            )}
          </div>
        </section>
        {sportsPerformers.length > 0 && (
          <section
            className={classNames(
              styles['homepage-block'],
              styles['teams-nearby']
            )}
            role="region"
            aria-labelledby="teams-nearby-heading"
          >
            <TeamsNearby
              performers={sportsPerformers}
              key={`teams-${metroId}`}
            />
          </section>
        )}
        {concertPerformers.length > 0 && (
          <section
            className={classNames(styles['homepage-block'], styles['trending'])}
          >
            <ReactSlickCarousel
              items={concertPerformers}
              renderItem={renderConcertPerformerItem}
              renderHeader={renderConcertPerformerHeader}
              key={`performers-${metroId}`}
              title="trending-concert-performers-carousel"
              spacing={isMobile ? 16 : 24}
              slideWidth={isMobile ? 235 : 282}
              topArrows
              className={styles['space-between']}
              trackMargin={isMobile ? 16 : 0}
            />
          </section>
        )}
        {cmsContent.length > 0 && (
          <section
            className={classNames(
              styles['homepage-block'],
              styles['cms-events']
            )}
            role="region"
            aria-labelledby="cms-events-heading"
          >
            <h2 id="cms-events-heading" className={styles['section-title']}>
              Can't Miss Events
            </h2>
            <div className={styles['cards-container']}>
              {cmsContent.map(({ fields }) => (
                <CMSContentCard
                  fields={fields}
                  isMobile={isMobile}
                  key={fields.button_link}
                  isSingleton={cmsContent.length === 1}
                  clickTracker={new ClickTracker()
                    .interaction(Click.INTERACTIONS.GET_TICKETS(), {
                      tile_name: fields.title,
                    })
                    .sourcePageType(Click.SOURCE_PAGE_TYPES.HOMEPAGE())}
                />
              ))}
            </div>
          </section>
        )}
        <section
          className={classNames(styles['homepage-block'], styles['categories'])}
          role="region"
          aria-labelledby="categories-heading"
        >
          <h2 id="categories-heading" className={styles['section-title']}>
            Categories
          </h2>
          <Categories />
        </section>
        <hr className={styles.divider} />
        <section
          className={classNames(
            styles['homepage-block'],
            styles['choose-gametime']
          )}
          role="region"
          aria-labelledby="choose-gametime-header"
        >
          <h2 id="choose-gametime-header" className={styles['section-header']}>
            Reasons to love Gametime
          </h2>
          <p className={styles['section-subtitle']}>More saving, more fun.</p>
          <ValueProps />
        </section>
        <hr className={styles.divider} />
        <section
          className={classNames(styles['homepage-block'], styles['reviews'])}
          role="region"
          aria-labelledby="reviews-header"
        >
          <h2 id="reviews-header" className={styles['section-header']}>
            See what fans like you are saying.
          </h2>
          <p className={styles['section-subtitle']}>
            500K+ happy fans. Millions of tickets sold.{' '}
            <br className={styles['text-break']} />
            Only one Gametime.
          </p>
          {isMobile && <AppStoreLinks />}
          <Reviews />
        </section>
        {!isMobile && (
          <section
            className={classNames(styles['homepage-block'], styles['download'])}
          >
            <DownloadApp />
          </section>
        )}
        <hr className={styles.divider} />
      </main>
      <GTFooter />
    </ContainerTemplate>
  );
}

const loader: DataLoader =
  ({ store }) =>
  async ({ params }) => {
    const { dispatch, getState } = store;
    await dispatch(fetchMetros());
    const state = getState();
    const metroId =
      params.metroId ||
      selectUserMetro(state)?.id ||
      selectClosestMetro(state)?.id;
    const currentLocation = currentLocationSelector(state);

    if (!currentLocation) {
      dispatch(updateCurrentLocation(metroId));
    }
    const promises = [
      dispatch(
        fetchCollections({
          view: COLLECTION_VIEWS.WEB_DISCOVER,
          with_results: true,
          metro: metroId,
          skipCache: true,
        })
      ),
      dispatch(
        fetchTrendingPerformersByCategoryGroup(
          metroId,
          CATEGORY_GROUP_IDS.sports
        )
      ),
      dispatch(fetchTrendingPerformersByCategoryGroup(metroId, 'concert')),
      dispatch(fetchPerformerEventsContent('homepage')),
    ];

    await Promise.all(promises);
    return null;
  };

HomeV1.loader = loader;

export default HomeV1;
