import React from 'react';
import PropTypes from 'prop-types';

const DeepLinkBadge = ({ badgeImgSrc, altMessage }) => {
  return <img src={badgeImgSrc} alt={altMessage} />;
};

DeepLinkBadge.propTypes = {
  badgeImgSrc: PropTypes.string.isRequired,
  altMessage: PropTypes.string.isRequired,
};

export default DeepLinkBadge;
