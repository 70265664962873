import { Action } from '@reduxjs/toolkit';

import type { GetListingsParams } from 'services/listings/listings.service';
import {
  ListingDisplayGroup as ListingDisplayGroup_UNCHECKED,
  ListingV2,
  RequestStatus,
} from 'types';

import {
  fetchListingsFailure,
  fetchListingsRequest,
  fetchListingsSuccess,
  updateListingsParams,
  updateListingsQuantity,
} from './actions';

// we could set the TSConfig noUncheckedIndexedAccess rule, but it will require
// many changes in the codebase, so we are using Partial here to avoid errors
type ListingDisplayGroup = Omit<ListingDisplayGroup_UNCHECKED, 'listings'> & {
  listings: Partial<ListingDisplayGroup_UNCHECKED['listings']>;
};

export interface ListingsState {
  status: RequestStatus;
  params: Partial<GetListingsParams>;
  listings: Partial<Record<string, ListingV2>>;
  displayGroups: ListingDisplayGroup[];
  availableLots: number[];
  error?: unknown;
}

export const initialState: ListingsState = {
  params: {},
  listings: {},
  availableLots: [],
  displayGroups: [],
  status: 'idle',
  error: undefined,
};

export default function listingsReducer(
  state: ListingsState = initialState,
  action: Action
): ListingsState {
  if (fetchListingsRequest.match(action)) {
    return {
      ...state,
      params: action.payload,
      status: 'loading',
      error: undefined,
    };
  }

  if (fetchListingsSuccess.match(action)) {
    const { eventId, response } = action.payload;

    // make sure we are only loading listings for the most recently requested
    // event
    if (state.params.eventId !== eventId) {
      return state;
    }

    return {
      ...state,
      listings: response.listings,
      displayGroups: response.display_groups,
      availableLots: response.available_lots,
      status: 'success',
    };
  }

  if (fetchListingsFailure.match(action)) {
    return {
      ...initialState,
      status: 'failure',
      error: action.payload,
    };
  }

  if (updateListingsParams.match(action)) {
    return {
      ...state,
      params: action.payload,
    };
  }

  if (updateListingsQuantity.match(action)) {
    return {
      ...state,
      params: {
        ...state.params,
        quantity: action.payload,
      },
    };
  }

  return state;
}
