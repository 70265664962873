import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'hoc/withRouter';
import PropTypes from 'prop-types';

import { Click, PAYLOAD, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import { getMetroPerformersBreadcrumbConfig } from 'components/Breadcrumbs/breadcrumb.helpers';
import ContentSection from 'components/ContentSection/ContentSection';
import EnsurePath from 'components/EnsurePath/EnsurePath';
import { EVENT_TYPES } from 'components/FullEventPagination/constants';
import FullEventsSection from 'components/FullEventPagination/FullEventsSection';
import GTGuaranteeInlineBanner from 'components/GTGuaranteeInlineBanner/GTGuaranteeInlineBanner';
import HeadCanonicalPath from 'components/Head/CanonicalPath';
import HeadDescription from 'components/Head/Description';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import { getCanonicalQuery } from 'components/Head/utils';
import HeroSection from 'components/HeroSection/HeroSection';
import faqsToFAQPageJSONLD from 'components/JsonLD/helpers/faqsToFAQPageJSONLD';
import JsonLD from 'components/JsonLD/JsonLD';
import TabBar from 'components/TabBar/TabBar';
import extractFAQs from 'helpers/extractFAQs';
import { hasFAQs } from 'helpers/extractFAQs/extractFAQs';
import { FullEvent, Venue, VenueContent } from 'models';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import NotFound from 'pages/NotFound/NotFound';
import { fetchVenueContentBySlug } from 'store/modules/content/venues/actions';
import { selectVenueContentBySlug } from 'store/modules/content/venues/selectors';
import { fetchFullEvents } from 'store/modules/data/FullEvents/actions';
import { selectEventsData } from 'store/modules/data/FullEvents/selectors';
import { selectSearchTestData } from 'store/modules/data/Search/selectors';
import { fetchVenueBySlug } from 'store/modules/data/Venues/actions';
import { selectVenueBySlug } from 'store/modules/data/Venues/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { selectMetro } from 'store/modules/resources/resource.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import { addQuery } from 'utils/url';

import { VENUE_TABS } from './constants';

import styles from './Venue.module.scss';

const mapPropsToPathname = ({ venue }) => (venue ? venue.getPath() : null);

@EnsurePath(mapPropsToPathname)
@TrackPageView(
  ({
    venue,
    queryId,
    resultPosition,
    searchIndex,
    searchSessionId,
    searchTestData,
  }) => {
    if (!venue) {
      return null;
    }

    return {
      [TRACK.PAGE_TYPE]: View.PAGE_TYPES.VENUE(venue.id, venue.metro),
      payload: {
        [PAYLOAD.QUERY_ID]: queryId,
        [PAYLOAD.RESULT_POSITION]: resultPosition,
        [PAYLOAD.SEARCH_INDEX]: searchIndex,
        [PAYLOAD.SEARCH_SESSION_ID]: searchSessionId,
        [PAYLOAD.SEARCH_TEST_ID]: searchTestData?.testId,
        [PAYLOAD.SEARCH_VARIANT_ID]: searchTestData?.variantId,
      },
    };
  }
)
@withClickContext(({ venue }) => {
  if (!venue) {
    return undefined;
  }
  return {
    [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.VENUE(venue),
  };
})
class VenuePage extends Component {
  static propTypes = {
    venue: PropTypes.instanceOf(Venue),
    metro: PropTypes.object,
    venueContent: PropTypes.instanceOf(VenueContent),
    fetchFullEvents: PropTypes.func.isRequired,
    updateUserPreference: PropTypes.func,
    venuePageData: PropTypes.shape({
      events: PropTypes.array,
      more: PropTypes.bool.isRequired,
      params: PropTypes.object.isRequired,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }).isRequired,
    query: PropTypes.object,
    searchTestData: PropTypes.shape({
      testId: PropTypes.string,
      variantId: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);

    this.state = this.#getInitialState();

    this.fetchMoreEvents = this.fetchMoreEvents.bind(this);
  }

  #getInitialState() {
    if (!this.props.venuePageData) {
      return {
        isEmpty: true,
        isLoading: false,
        more: false,
        params: {},
        fullEvents: [],
        activeTab: VENUE_TABS[0].key,
      };
    }

    const { events, more, params } = this.props.venuePageData;

    return {
      isEmpty: !events?.length,
      isLoading: false,
      more,
      params,
      fullEvents: events,
      activeTab: VENUE_TABS[0].key,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.venue?.slug !== this.props.venue?.slug) {
      this.setState(this.#getInitialState());
    }
  }

  async fetchMoreEvents() {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    const params = this.state.params;
    const res = await this.props.fetchFullEvents({
      ...params,
      page: params.page + 1,
    });

    const fullEvents = res.events.map((e) => new FullEvent(e));

    this.setState((state) => ({
      ...state,
      isLoading: false,
      more: res.more,
      params: {
        ...state.params,
        page: res.page,
        per_page: res.per_page,
      },
      fullEvents: [...state.fullEvents, ...fullEvents],
    }));
  }

  onMetroBreadcrumbClick(metro) {
    this.props.updateUserPreference({
      lastVisitedMetro: metro.id,
    });
  }

  handleSetActiveTab(tabId) {
    this.setState((state) => ({
      ...state,
      activeTab: tabId,
    }));
  }

  renderMeta() {
    const {
      venue: { name: venueName, image: venueImage, city, state },
      location: { pathname },
      query,
      venueContent,
    } = this.props;

    let pageTitle = `${venueName} Tickets & Events`;

    let pageDescription = `Find the cheapest tickets to ${venueName} events in ${city}, ${state} with panoramic seat view photos. Best Price Guarantee! 100% Authentic Tickets.`;

    // If venue has metaTitle and metaDescription from CMS, overwrite them
    if (venueContent && venueContent.isActive) {
      const { metaTitle, metaDescription } = venueContent;

      if (metaTitle) {
        pageTitle = metaTitle;
      }

      if (metaDescription) {
        pageDescription = metaDescription;
      }
    }

    return (
      <div>
        <HeadImage src={venueImage} />
        <HeadDescription description={pageDescription} />
        <HeadCanonicalPath path={pathname} query={getCanonicalQuery(query)} />
        <HeadTitle title={pageTitle} />
      </div>
    );
  }

  render() {
    const { venue, venueContent, metro, venuePageData, location } = this.props;
    const { params } = this.state;
    if (!venue) {
      return <NotFound />;
    }

    const currentPageConfig = {
      ...venue.getBreadcrumbConfig(),
      hidden: true,
    };

    const tabs = Object.fromEntries(VENUE_TABS.map((tab) => [tab.key, tab]));

    const faqs =
      venueContent?.isActive && venueContent?.body && hasFAQs(venueContent.body)
        ? extractFAQs(venueContent.body)
        : null;

    return (
      <GTContainer
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          className: styles.header,
          showHamburger: true,
        }}
        className={styles['venue-container']}
      >
        {this.renderMeta()}
        {faqs && <JsonLD json={faqsToFAQPageJSONLD(faqs)} />}
        <HeroSection
          className={styles['hero-section']}
          breadcrumbProps={{
            breadcrumbs: [
              HOMEPAGE_BREADCRUMB_CONFIG,
              getMetroPerformersBreadcrumbConfig(metro),
              currentPageConfig,
            ],
            onMetroBreadcrumbClick: () => this.onMetroBreadcrumbClick(metro),
          }}
          title={`${venue.name} Tickets`}
          imageProps={{
            ...venue.getImageOptions(),
            lazyLoad: false,
            isPreloaded: true,
          }}
        />
        <div className={styles['tab-bar-container']}>
          <TabBar
            tabs={VENUE_TABS}
            activeTab={this.state.activeTab}
            onChange={(tab) => this.handleSetActiveTab(tab)}
          />
        </div>
        <div className={styles['body-container']}>
          {venueContent?.venueHtml && (
            <div
              className={styles['venue-html']}
              dangerouslySetInnerHTML={{ __html: venueContent.venueHtml }}
            />
          )}
          {tabs.events.key === this.state.activeTab && (
            <FullEventsSection
              renderNoEvents
              event={EVENT_TYPES.VENUE}
              fetchMoreEvents={this.fetchMoreEvents}
              paginationState={this.state}
            />
          )}
          <div className={styles['trust-inline']}>
            <GTGuaranteeInlineBanner />
          </div>
          {venueContent?.isActive && (
            <ContentSection
              headline={`About ${venue.name}`}
              content={venueContent}
            />
          )}
        </div>
        {params.page > 1 && (
          <a
            className={styles['pagination-link']}
            href={addQuery(location.pathname, location.search, { page: 1 })}
          />
        )}
        {venuePageData?.more && (
          <a
            className={styles['pagination-link']}
            href={addQuery(location.pathname, location.search, {
              page: params.page + 1,
            })}
          />
        )}
      </GTContainer>
    );
  }
}

const mapStateToProps = (state, { params: { slug }, query }) => {
  const venue = selectVenueBySlug(state, slug);

  if (!venue) {
    return {};
  }

  return {
    venue,
    metro: selectMetro(state, venue.metro),
    fullEvents: [],
    venueContent: selectVenueContentBySlug(state, slug),
    queryId: query?.queryId,
    resultPosition: query?.resultPosition,
    searchIndex: query?.searchIndex,
    searchSessionId: query?.searchSessionId,
    venuePageData: selectEventsData(state),
    searchTestData: selectSearchTestData(state),
  };
};

const mapDispatchToProps = { fetchFullEvents, updateUserPreference };

const VenueWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VenuePage)
);

const loader =
  ({ store: { dispatch } }) =>
  async ({ params: { slug }, request }) => {
    const query = new URL(request.url).searchParams;
    const page = query.get('page') ?? 1;

    const params = {
      [EVENT_TYPES.VENUE.eventSlug]: slug,
      eventType: EVENT_TYPES.VENUE.eventType,
      page,
      per_page: 15,
      resetState: true,
    };

    await Promise.all([
      dispatch(fetchMetros()),
      dispatch(fetchVenueBySlug(slug)),
      dispatch(fetchVenueContentBySlug(slug)),
      dispatch(fetchFullEvents(params)),
    ]);

    return null;
  };

VenueWrapper.loader = loader;

export default VenueWrapper;
