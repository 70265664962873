import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import classNames from 'classnames';
import withRouter from 'hoc/withRouter';
import PropTypes from 'prop-types';

import GoogleAdBanner from 'components/GoogleAdBanner/GoogleAdBanner';
import ModalManager from 'components/Modals/ModalManager';
import Notifications from 'components/Notifications/Notifications';
import { isFromAdwords } from 'store/modules/location';
import { isRestrictedMetro } from 'store/modules/resources/resource.selectors';
import { searchStringToQueryObject } from 'utils/url';

import styles from './ContainerTemplate.module.scss';

class ContainerTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResultsAvailable: false,
    };

    this.onSearchResultsAvailabilityChange =
      this.onSearchResultsAvailabilityChange.bind(this);
  }

  onSearchResultsAvailabilityChange(available) {
    if (this.state.searchResultsAvailable !== available) {
      this.setState({ searchResultsAvailable: available });
    }
  }

  render() {
    const {
      helmetProps,
      className,
      header,
      footer,
      children,
      query: { header: showHeader, footer: showFooter },
      containerProps = {},
      canShowGoogleAdbanner = false,
      shouldShowGoogleAdbanner,
    } = this.props;
    const { searchResultsAvailable } = this.state;
    const headerProps = {
      onSearchResultsAvailabilityChange: this.onSearchResultsAvailabilityChange,
    };

    let bodyAttributes = {};
    const showGoogleAdBanner =
      !searchResultsAvailable &&
      canShowGoogleAdbanner &&
      shouldShowGoogleAdbanner;

    if (searchResultsAvailable) {
      bodyAttributes = { 'data-search-bar-active': true };
    }

    return (
      <div
        {...containerProps}
        className={classNames(className, styles.container)}
      >
        <Helmet {...helmetProps} bodyAttributes={{ ...bodyAttributes }} />
        <Notifications />
        <ModalManager />
        {showGoogleAdBanner && <GoogleAdBanner />}
        {showHeader !== 'false' &&
          header &&
          React.cloneElement(header, headerProps)}
        <div className="app-main-content__wrapper">
          <main className={styles.appContent}>{children}</main>
          {showFooter !== 'false' && footer}
        </div>
      </div>
    );
  }
}

ContainerTemplate.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func.isRequired,
  footer: PropTypes.node,
  header: PropTypes.node,
  className: PropTypes.string,
  helmetProps: PropTypes.object,
  containerProps: PropTypes.object,
  location: PropTypes.object,
  canShowGoogleAdbanner: PropTypes.bool,
  query: PropTypes.object,
  shouldShowGoogleAdbanner: PropTypes.bool.isRequired,
  userIsLoggedIn: PropTypes.bool,
};

export default withRouter(
  connect((state, props) => {
    const location = props.location;
    const query = searchStringToQueryObject(location.search);

    return {
      location,
      shouldShowGoogleAdbanner:
        isFromAdwords(query) || isRestrictedMetro(state),
      query,
    };
  })(ContainerTemplate)
);
