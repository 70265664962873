import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'hoc/withRouter';
import PropTypes from 'prop-types';

import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import { generateBreadcrumbSchema } from 'components/Breadcrumbs/breadcrumb.helpers';
import HeadDescription from 'components/Head/Description';
import HeadTitle from 'components/Head/Title';
import JsonLD from 'components/JsonLD/JsonLD';
import SimpleHeroSection from 'components/SimpleHeroSection/SimpleHeroSection';
import { PerformerInContext } from 'models';
import AllEventsSection from 'pages/CategoryPerformers/components/AllEventsSection';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import {
  currentLocationSelector,
  updateCurrentLocation,
} from 'store/modules/app/app';
import { getCategoryImgUrl } from 'store/modules/categories/category.helpers';
import { fetchPerformersInContext } from 'store/modules/data/PerformersInContext/actions';
import {
  selectGenrePerformers,
  updateGenrePerformers,
} from 'store/modules/genrePerformers/genrePerformers';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { titleCase } from 'utils/strings/title-case';

import styles from './GenrePerformers.module.scss';

class GenrePerformers extends Component {
  static propTypes = {
    genrePerformers: PropTypes.arrayOf(
      PropTypes.instanceOf(PerformerInContext)
    ),
    params: PropTypes.shape({
      genre: PropTypes.string.isRequired,
    }),
  };
  constructor(props) {
    super(props);
  }

  render() {
    // meta/head dependencies
    const { params, genrePerformers } = this.props;
    const formattedGenre = titleCase(params.genre);
    const pageTitle = `Cheap ${formattedGenre} Tickets`;
    const pageDescription = `See upcoming ${formattedGenre} concerts`;
    const genreBreadcrumbConfig = {
      name: params.genre,
      path: `/concert-tickets/genre/${params.genre}`,
    };
    const breadcrumbSchema = [
      HOMEPAGE_BREADCRUMB_CONFIG,
      genreBreadcrumbConfig,
    ];

    const heroTitle = `${formattedGenre} Concert Tickets`;

    return (
      <GTContainer
        canShowGoogleAdbanner
        headerProps={{
          search: true,
          showCategories: true,
          showAccount: true,
          showHamburger: true,
        }}
      >
        <HeadDescription description={pageDescription} />
        <HeadTitle title={pageTitle} />
        <JsonLD json={generateBreadcrumbSchema(breadcrumbSchema)} />
        <SimpleHeroSection
          title={heroTitle}
          backgroundImage={getCategoryImgUrl('music')}
        />
        <div className={styles.events}>
          {genrePerformers && (
            <AllEventsSection performersInContext={genrePerformers} />
          )}
        </div>
      </GTContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    genrePerformers: selectGenrePerformers(state),
  };
};

const GenrePerformersWrapper = withRouter(
  connect(mapStateToProps)(GenrePerformers)
);

const loader =
  ({ store }) =>
  async ({ params }) => {
    const genre = params.genre;
    await store.dispatch(fetchMetros()).then(() => {
      const state = store.getState();
      const currentLocation = currentLocationSelector(state);

      const { id } = selectUserMetro(state) || selectClosestMetro(state);
      if (!currentLocation) {
        store.dispatch(updateCurrentLocation(id));
      }
    });
    const currentLocation = currentLocationSelector(store.getState());
    /**
     * 5/24 there is a BE issue with hyphenated genres that will be fixed soon.
     * in the meantime, we are sending the first word of the genre to the BE
     * the below code will be removed once the BE issue is fixed
     */
    const genreFirstWord = genre.split('-')[0];
    const genrePerformers = await store.dispatch(
      fetchPerformersInContext({
        category_group: 'concert',
        genre: genreFirstWord,
        limit: 15,
        metro: currentLocation,
        skipCache: true,
        sort_by: '-event.trending_score',
      })
    );

    if (genrePerformers?.performers.length)
      await store.dispatch(updateGenrePerformers(genrePerformers));

    return null;
  };

GenrePerformersWrapper.loader = loader;

export default GenrePerformersWrapper;
