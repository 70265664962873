import React from 'react';
import ValuePropCard from 'ui/ValuePropCard/ValuePropCard';

import styles from './ValueProps.module.scss';

function ValueProps() {
  return (
    <div className={styles['value-props']}>
      <ValuePropCard gradientColor="#363B80">
        <span className={styles['value-prop-emoji']} aria-hidden="true">
          👀
        </span>
        <h3 className={styles['value-prop-title']}>Ditch Hidden Fees</h3>
        <p className={styles['value-prop-content']}>
          Enable All-In Pricing to make price comparison easier. You'll see
          total prices up front - including taxes and fees.
        </p>
      </ValuePropCard>
      <ValuePropCard gradientColor="#2E9F66">
        <span className={styles['value-prop-emoji']} aria-hidden="true">
          💪
        </span>
        <h3 className={styles['value-prop-title']}>Lowest Price Guarantee</h3>
        <p className={styles['value-prop-content']}>
          We're committed to clear, competitive pricing. If you find a lower
          price elsewhere, we'll make up 110% of the difference.{' '}
          <br className={styles['text-break']} />
          <a className={styles['value-prop-link']} href="/gametime-guarantee">
            Learn More
          </a>
        </p>
      </ValuePropCard>
      <ValuePropCard gradientColor="#25819F">
        <span className={styles['value-prop-emoji']} aria-hidden="true">
          ✨
        </span>
        <h3 className={styles['value-prop-title']}>Gametime Picks</h3>
        <p className={styles['value-prop-content']}>
          Gametime analyzes thousands of tickets for all your favorite events.
          We find the best of the best deals, so you don't have to.
        </p>
      </ValuePropCard>
    </div>
  );
}

export default ValueProps;
