import React from 'react';

import iosStoreBadge from 'assets/download-on-the-app-store-badge.svg';
import playStoreBadge from 'assets/get-it-on-google-play-badge.svg';
import config from 'config/config';

import styles from './AppStoreLinks.module.scss';

const AppStoreLinks = () => (
  <div className={styles['app-store-links']}>
    <a href={config.APP_STORE_URL} target="_blank" rel="noopener noreferrer">
      <img
        src={iosStoreBadge}
        alt="Get the Gametime app on the Apple App Store"
      />
    </a>
    <a href={config.PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
      <img src={playStoreBadge} alt="Get the Gametime app on Google Play" />
    </a>
  </div>
);

export default AppStoreLinks;
