import _get from 'lodash/get';

import { PAYLOAD } from 'analytics/constants';
import { mapListingTrackingData } from 'analytics/utils';

import { TrackEvent } from './TrackEvent';

export const STATUS_NAMES = {
  PURCHASE_FLOW_CREATION_FAILURE: 'purchase_flow_creation_failure',
  CUSTOM: 'custom',
  CALL_SUPPORT: 'call_support',
  SHOW_LISTING_DETAIL: 'show_listing_detail',
  SHOW_TEAM_LISTINGS: 'show_team_listings',
  COMPLETE: 'complete',
  PURCHASE_INFO_SUBMIT_FAILURE: 'purchase_info_submit_failure',
  SHOW_ADD_CARD: 'show_add_card',
};

const PAGE_TYPE_NAMES = {
  ACCOUNT_DELETION: 'account_deletion',
  BLOG_POST: 'blog_post',
  BLOG: 'blog',
  LISTING: 'listing',
  EVENT: 'event',
  METRO: 'metro',
  COLLECTION: 'collection',
  CATEGORY: 'category',
  PERFORMER: 'performer',
  STATIC_PAGE: 'static_page',
  PROCESS_PURCHASE: 'process_purchase',
  TICKET: 'ticket',
  VENUE: 'venue',
  PURCHASE_CONFIRM: 'purchase_confirm',
  ADD_CREDIT_CARD_MANUAL: 'add_credit_card_manual',
  SCHEDULE: 'schedule',
  HOMEPAGE: 'homepage',
  MAIN_METRO_PAGE: 'main_metro_seo',
  SPORTS_BY_METRO: 'metro_category_seo',
  MY_TICKETS: 'my_tickets',
  PROFILE: 'profile',
  REDEEM_CODE: 'redeem_code',
  SEARCH: 'search',
  PURCHASE_COMPLETE: 'purchase_complete',
  INSTALL: 'install',
  UNKNOWN: 'unknown',
  ORDER_CONFIRMATION: 'order_confirmation',
  ADD_EMAIL: 'add_email',
  MAGIC_LINK: 'magic_link',
  CHECKOUT: 'checkout',
  ADD_PHONE_NUMBER: 'add_phone_number',
  TRANSFER_TICKETS: 'transfer_tickets',
  VERIFY_BILLING_ADDRESS: 'verify_billing_address',
  VERIFY_SHIPPING_ADDRESS: 'verify_shipping_address',
  LOGIN: 'login',
  NOT_FOUND: 'not_found',
  ACCOUNT_VERIFICATION: 'account_verification',
  SMS_VERIFICATION: 'sms_verification',
  MARKETING_SURVEY: 'marketing_survey',
  CVV_VERIFICATION: 'cvv_verification',
  REQUEST_PROCESSING_FAILURE: 'request_processing_failure',
  GAMETIME_PICKS: 'gametime_picks',
};

export class View extends TrackEvent {
  static PAGE_TYPES = {
    BLOG_POST: (slug) => ({
      page_type: PAGE_TYPE_NAMES.BLOG_POST,
      post_id: slug,
    }),
    BLOG: () => ({ page_type: PAGE_TYPE_NAMES.BLOG }),
    LISTING: ({ fullEvent, listing, displayedPrice, ...params }) => {
      return {
        page_type: PAGE_TYPE_NAMES.LISTING,
        listing_id: listing && listing.id,
        performer_id: fullEvent && fullEvent.getPerformerId(),
        event_id: fullEvent && fullEvent.id,
        metro: fullEvent && fullEvent.metro,
        displayed_price: displayedPrice,
        ...params,
      };
    },
    EVENT: ({
      fullEvent,
      listing,
      quantity,
      sort,
      payload,
      isListingRoute,
      dealsTracking,
      ...params
    }) => {
      function getDealsTrackingForEventPage() {
        const {
          hasFlashDealsInEvent,
          hasZoneDealsInEvent,
          hasFeaturedDealsInEvent,
        } = dealsTracking;

        return {
          [PAYLOAD.ZONE_DEAL_AVAILABLE]: hasZoneDealsInEvent,
          [PAYLOAD.FLASH_DEAL_AVAILABLE]: hasFlashDealsInEvent,
          [PAYLOAD.FEATURED_DEAL_AVAILABLE]: hasFeaturedDealsInEvent,
        };
      }

      const trackingProps = {
        page_type: PAGE_TYPE_NAMES.EVENT,
        event_id: fullEvent && fullEvent.id,
        performer_id: fullEvent && fullEvent.getPerformerId(),
        venue_id: fullEvent && fullEvent.venueId,
        metro: fullEvent && fullEvent.metro,
        ...params,
        payload: {
          ...payload,
          quantity,
          sort,
        },
      };

      if (isListingRoute && listing) {
        trackingProps.page_type = PAGE_TYPE_NAMES.LISTING;
        trackingProps.listing_id = listing.id;
        trackingProps.payload = {
          ...trackingProps.payload,
          ...mapListingTrackingData(listing.listingTrackingData),
        };
      }

      if (!isListingRoute && fullEvent && Object.keys(dealsTracking)?.length) {
        trackingProps.payload = {
          ...trackingProps.payload,
          ...getDealsTrackingForEventPage(),
        };
      }

      return trackingProps;
    },
    METRO: (metro, categoryGroupId) => ({
      page_type: PAGE_TYPE_NAMES.METRO,
      metro,
      category_group_id: categoryGroupId,
    }),
    COLLECTION: (collectionName) => ({
      page_type: PAGE_TYPE_NAMES.COLLECTION,
      collection_name: collectionName,
    }),
    CATEGORY: (categoryName) => ({
      page_type: PAGE_TYPE_NAMES.CATEGORY,
      category_name: categoryName,
    }),
    PERFORMER: (performerId) => ({
      page_type: PAGE_TYPE_NAMES.PERFORMER,
      performer_id: performerId,
    }),
    STATIC_PAGE: (pageName) => ({
      page_type: PAGE_TYPE_NAMES.STATIC_PAGE,
      page_name: pageName,
    }),
    PROCESS_PURCHASE: (eventId, listingId) => ({
      page_type: PAGE_TYPE_NAMES.PROCESS_PURCHASE,
      event_id: eventId,
      listing_id: listingId,
    }),
    REDEEM_CODE: (activePromos) => ({
      page_type: PAGE_TYPE_NAMES.REDEEM_CODE,
      payload: { active_promo: activePromos },
    }),
    TICKET: (ticketId, transactionId) => ({
      page_type: PAGE_TYPE_NAMES.TICKET,
      ticket_id: ticketId,
      transaction_id: transactionId,
    }),
    VENUE: (venueId, metro) => ({
      page_type: PAGE_TYPE_NAMES.VENUE,
      venue_id: venueId,
      metro,
    }),
    PURCHASE_CONFIRM: (listingId, eventId) => ({
      page_type: PAGE_TYPE_NAMES.PURCHASE_CONFIRM,
      listing_id: listingId,
      event_id: eventId,
    }),
    ADD_CREDIT_CARD_MANUAL: () => ({
      page_type: PAGE_TYPE_NAMES.ADD_CREDIT_CARD_MANUAL,
    }),
    SCHEDULE: () => ({ page_type: PAGE_TYPE_NAMES.SCHEDULE }),
    HOMEPAGE: (metro) => ({ page_type: PAGE_TYPE_NAMES.HOMEPAGE, metro }),
    MAIN_METRO_PAGE: () => ({
      page_type: PAGE_TYPE_NAMES.MAIN_METRO_PAGE,
    }),
    SPORTS_BY_METRO: () => ({
      page_type: PAGE_TYPE_NAMES.SPORTS_BY_METRO,
    }),
    MY_TICKETS: () => ({ page_type: PAGE_TYPE_NAMES.MY_TICKETS }),
    PROFILE: () => ({ page_type: PAGE_TYPE_NAMES.PROFILE }),
    SEARCH: () => ({ page_type: PAGE_TYPE_NAMES.SEARCH }),
    PURCHASE_COMPLETE: (status, eventId, listingId) => ({
      page_type: PAGE_TYPE_NAMES.PURCHASE_COMPLETE,
      status,
      event_id: eventId,
      listing_id: listingId,
    }),
    INSTALL: () => ({ page_type: PAGE_TYPE_NAMES.INSTALL }),
    UNKNOWN: () => ({ page_type: PAGE_TYPE_NAMES.UNKNOWN }),
    ORDER_CONFIRMATION: (transactionId, parkingPassAvailable, promoCode) => ({
      page_type: PAGE_TYPE_NAMES.ORDER_CONFIRMATION,
      transaction_id: transactionId,
      parking_pass_available: parkingPassAvailable,
      promo_code: promoCode,
    }),
    ADD_EMAIL: () => ({ page_type: PAGE_TYPE_NAMES.ADD_EMAIL }),
    MAGIC_LINK: () => ({ page_type: PAGE_TYPE_NAMES.MAGIC_LINK }),
    CHECKOUT: ({ listing, performer, fullEvent, payload }) => ({
      page_type: PAGE_TYPE_NAMES.CHECKOUT,
      listing_id: _get(listing, 'id'),
      event_id: fullEvent && fullEvent.id,
      metro: fullEvent && fullEvent.metro,
      performer_id: _get(performer, 'id'),
      payload,
    }),
    ADD_PHONE_NUMBER: () => ({ page_type: PAGE_TYPE_NAMES.ADD_PHONE_NUMBER }),
    TRANSFER_TICKETS: (transactionId) => ({
      page_type: PAGE_TYPE_NAMES.TRANSFER_TICKETS,
      transaction_id: transactionId,
    }),
    VERIFY_BILLING_ADDRESS: () => ({
      page_type: PAGE_TYPE_NAMES.VERIFY_BILLING_ADDRESS,
    }),
    VERIFY_SHIPPING_ADDRESS: () => ({
      page_type: PAGE_TYPE_NAMES.VERIFY_SHIPPING_ADDRESS,
    }),
    LOGIN: () => ({ page_type: PAGE_TYPE_NAMES.LOGIN }),
    NOT_FOUND: () => ({ page_type: PAGE_TYPE_NAMES.NOT_FOUND }),
    ACCOUNT_VERIFICATION: () => ({
      page_type: PAGE_TYPE_NAMES.ACCOUNT_VERIFICATION,
    }),
    SMS_VERIFICATION: () => ({
      page_type: PAGE_TYPE_NAMES.SMS_VERIFICATION,
    }),
    MARKETING_SURVEY: (transactionId) => ({
      page_type: PAGE_TYPE_NAMES.MARKETING_SURVEY,
      transaction_id: transactionId,
    }),
    CVV_VERIFICATION: () => ({
      page_type: PAGE_TYPE_NAMES.CVV_VERIFICATION,
    }),
    ACCOUNT_DELETION: () => ({
      page_type: PAGE_TYPE_NAMES.ACCOUNT_DELETION,
    }),
    REQUEST_PROCESSING_FAILURE: () => ({
      page_type: PAGE_TYPE_NAMES.REQUEST_PROCESSING_FAILURE,
    }),
    GAMETIME_PICKS: () => ({ page_type: PAGE_TYPE_NAMES.GAMETIME_PICKS }),
  };

  _eventName = 'view';
}
