import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import DealsTag from 'components/Deals/DealsTag';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { FullEvent, Listing } from 'models';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';

import DealsSliderNavigation, { navigate } from './DealsSliderNavigation';

import styles from './DealsSlider.module.scss';

const settings = {
  infinite: false,
  variableWidth: true,
  arrows: false,
};

const DealsSlider = ({
  header,
  listings,
  fullEvent,
  dealType = '',
  onListingHover,
  zoomLevel,
  allDisclosures,
  isCarouselFloating = false,
}) => {
  const sliderRef = useRef();
  const isMobile = useMediaQuery(device.down.md);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(listings.length === 1);

  const handleNavigation = (dir) => {
    if (!sliderRef?.current) return;
    if (dir === navigate.next) {
      return sliderRef.current.slickNext();
    }
    sliderRef.current.slickPrev();
  };

  const handleAfterChange = (slideIndex) => {
    const index = slideIndex + 1;
    const slidesTotal = listings.length;
    setDisableNext(index === slidesTotal);
    setDisablePrev(index === 1);
  };

  return (
    <section
      className={styles['slider-container']}
      data-testid="slider-container"
    >
      {!isCarouselFloating && (
        <header className={styles['slider-header']}>
          <div className={styles['slider-header-info']}>
            <span className={styles['slider-header-name']}> {header} </span>
            {dealType === 'zone' && <DealsTag fullEvent={fullEvent} />}
          </div>
          {!isMobile && (
            <DealsSliderNavigation
              onClick={handleNavigation}
              disabledPrev={disablePrev}
              disabledNext={disableNext}
            />
          )}
        </header>
      )}
      <Slider
        className={styles['slider-carousel']}
        ref={sliderRef}
        {...settings}
        afterChange={handleAfterChange}
      >
        {listings.map((listing, index) => (
          <div key={listing.id} className={styles['slider-item']}>
            <ListingCard
              fullEvent={fullEvent}
              onHover={onListingHover}
              listingIndex={index}
              zoomLevel={zoomLevel}
              allDisclosures={allDisclosures}
              listing={listing}
              isCarouselFloating={isCarouselFloating}
              isCarouselItem
              isExclusivesV1
            />
          </div>
        ))}
      </Slider>
    </section>
  );
};

DealsSlider.propTypes = {
  header: PropTypes.any,
  listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)).isRequired,
  fullEvent: PropTypes.instanceOf(FullEvent),
  dealType: PropTypes.string,
  onListingHover: PropTypes.func.isRequired,
  allDisclosures: PropTypes.object.isRequired,
  zoomLevel: PropTypes.number,
  isCarouselFloating: PropTypes.bool,
};

export default DealsSlider;
