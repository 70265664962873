import React, { Component } from 'react';
import { connect } from 'react-redux';
import atob from 'atob';
import classNames from 'classnames';
import withRouter from 'hoc/withRouter';
import PropTypes from 'prop-types';

import { Click, PAYLOAD, TRACK, TrackPageView, View } from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import HeadRobots from 'components/Head/Robots';
import HeadTitle from 'components/Head/Title';
import { SEARCH_PAGE_TYPES } from 'components/Search/Search.constants';
import SearchResults from 'components/Search/SearchResults/SearchResults';
import GTContainer from 'pages/Containers/GTContainer/GTContainer';
import { isProductionEnvironmentSelector } from 'store/modules/app/app.selectors';
import { clearSearch, search } from 'store/modules/data/Search/actions';
import { selectSearchResults } from 'store/modules/data/Search/selectors';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { searchStringToQueryObject } from 'utils/url';

import styles from './Search.module.scss';

const SEARCH_RESULTS_THEMES = {
  DEFAULT: 'default',
  DARK: 'dark',
};

const propTypes = {
  clearSearch: PropTypes.func.isRequired,
  searchResults: PropTypes.shape({
    results: PropTypes.array.isRequired,
    flattenedResults: PropTypes.array.isRequired,
    hasMoreResults: PropTypes.bool.isRequired,
  }),
  searchText: PropTypes.string,
  searchSessionID: PropTypes.string,
  keystrokeSequence: PropTypes.number,
  apiSequence: PropTypes.number,
  isProductionEnvironment: PropTypes.bool,
};

@connect(
  (state, { location }) => {
    const query = searchStringToQueryObject(location.search);
    const searchText = query.q;
    let searchSessionID = '';
    let keystrokeSequence = 0;
    let apiSequence = 0;

    if (query.d) {
      const searchSessionData = JSON.parse(atob(query.d));
      ({ searchSessionID, keystrokeSequence, apiSequence } = searchSessionData);
    }

    return {
      searchResults: selectSearchResults(state, searchSessionID),
      isProductionEnvironment: isProductionEnvironmentSelector(state),
      searchText,
      searchSessionID,
      keystrokeSequence,
      apiSequence,
    };
  },
  {
    clearSearch,
  }
)
@TrackPageView(({ searchText }) => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.SEARCH(),
  payload: {
    [PAYLOAD.SEARCH_TERM]: searchText,
  },
}))
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.SEARCH(),
}))
class Search extends Component {
  static propTypes = propTypes;

  shouldComponentUpdate(nextProps) {
    return this.props.searchText !== nextProps.searchText;
  }

  componentWillUnmount() {
    this.props.clearSearch();
  }

  getTitle() {
    const {
      searchText,
      searchResults: { flattenedResults },
    } = this.props;
    const resultsQuantity = flattenedResults.length;

    let title = '';
    let titleSearchText = '';

    if (searchText) {
      titleSearchText = `for '${searchText}'`;
    }

    if (resultsQuantity === 0) {
      title = `No results ${titleSearchText}`;
    } else {
      title = `Search results ${titleSearchText || ''}`;
    }

    return title;
  }

  render() {
    const {
      apiSequence,
      keystrokeSequence,
      searchSessionID,
      searchText,
      searchResults: { results, flattenedResults },
      isProductionEnvironment,
    } = this.props;
    const noResults = flattenedResults.length === 0;

    return (
      <GTContainer
        className={styles.container}
        headerProps={{
          search: true,
          showAccount: true,
          showCategories: true,
          showHamburger: true,
          initialSearchSessionID: searchSessionID,
          initialKeystrokeSequence: keystrokeSequence,
          initialApiSequence: apiSequence,
        }}
      >
        <HeadRobots
          noarchive
          nofollow={!isProductionEnvironment}
          noindex={!isProductionEnvironment}
          index={isProductionEnvironment}
          follow={isProductionEnvironment}
        />
        <HeadTitle title={this.getTitle()} />
        <div className={styles['search-landing-container']}>
          <div className={styles['title-container']}>
            <h1 className={styles.title}>{this.getTitle()}</h1>
          </div>
          <div className={styles['search-results-container']}>
            <SearchResults
              className={classNames(styles['search-results'], {
                [styles['no-results']]: noResults,
              })}
              searchText={searchText}
              searchResults={results}
              show
              showTitle={false}
              noResults={noResults}
              theme={SEARCH_RESULTS_THEMES.DARK}
              searchSessionID={searchSessionID}
              keystrokeSequence={keystrokeSequence}
              apiSequence={apiSequence}
              pageType={SEARCH_PAGE_TYPES.FULL}
            />
          </div>
        </div>
      </GTContainer>
    );
  }
}

const loader =
  ({ store: { dispatch, getState } }) =>
  async ({ request }) => {
    const queryParams = new URL(request.url).searchParams;

    await dispatch(fetchMetros());
    const state = getState();
    const searchText = queryParams.get('q');

    if (!searchText) {
      return null;
    }
    const currentMetro = selectUserMetro(state) || selectClosestMetro(state);

    await dispatch(search(searchText, currentMetro));
    return null;
  };

const SearchWrapper = withRouter(Search);

SearchWrapper.loader = loader;

export default SearchWrapper;
